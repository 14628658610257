// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@each $color, $value in $theme-colors {
  @include list-group-item-custom-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
}

.list-group {
  i {
    display: inline-block;
  }
}

// Inline users list
.users-list {
  li {
    & + li {
      margin-left: -0.65rem;
    }

    img {
      border: 2px solid $white;
    }
  }
}

@import 'bootstrap';
@import 'bootstrap-extended';
@import 'colors';
@import 'components';
//@import "themes/layout-dark";
@import "core/menu/horizontal-menu";
@import 'overrides';
@import 'table';
@import 'indigo-pink.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import 'global';
@import 'general';
@import 'material';
@import 'button';
@import 'style.css';
@import 'rtl';
@import 'icon';
//@import '~@mdi/font';

@import '~@mdi/font/css/materialdesignicons.min.css';
.alert-danger {
  border-color: #ffc7c7 !important;
  background-color: #ffc7c7 !important;
  color: #0a0202 !important;
  display: flex;
  width: 100%;
  //border-left-color: red;
  //border-left-width: 4px;
  //border-radius: 0 2px 2px 0;
}

.nav-tabs .nav-link.active {
  border-bottom: 3px solid #8e8952 !important;
  background-color: #ffffff !important;
  color: black;
  font-weight: 500;
}

.nav-tabs {
  min-width: 0;
  padding-right: 20px;
  overflow: hidden;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ddd;

  li {
    font-size: 13.2px;

    a {
      padding: 15px 15px;
      margin-right: 10px;
      line-height: 1.4;
      border: 0px !important;
      border-bottom: 2px solid white !important;
      color: #333;

      &:hover {
        color: black;
        background-color: #fff;
        border-bottom: 3px solid #d1cc7e !important;
        text-decoration: none;
      }
    }

    &.active {
      a {
        color: #333 !important;
        font-weight: bold !important;
        border-bottom: 2px solid #d1cc7e !important;
      }
    }
  }
}

.moor-card .card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  box-shadow: 4px 4px 10px -2px #708793 !important;
  margin: 0 0;
}

.moor-card .card .card-header {
  max-height: 110px;
  padding: 10px 30px !important;
  background-color: #f7fbb7;
}

.card-block {
  p {
    text-align: center;
  }

  a {
    text-align: center;
  }
}

.img-loading {
  position: absolute;
  z-index: 200;
  width: 40%;
  left: 30%;
}

.icon-green {
  color: green;
}

icon-black {
  color: #343434;
}

.icon-red {
  color: red;
}

input:focus {
  border-color: #0520e4 !important;
}

.icon-margin-12 {
  margin-left: 95% !important;
  margin-top: -32px !important;
}

.icon-margin-6 {
  margin-left: 88% !important;
  margin-top: -32px !important;
}

.icon-margin-row-12 {
  margin-left: 93% !important;
  margin-top: -32px !important;
}

.icon-margin-row-6 {
  margin-left: 79% !important;
  margin-top: -32px !important;
}

.moor-error-text {
  margin-left: 12px;
}

.moor-button-icon-cancel {
  font-size: 20px;
  color: #a1a1a1 !important;
  margin-right: 5px;
}

.moor-button-icon-check {
  font-size: 20px;
  color: blue !important;
  margin-right: 5px;
}

.moor-button-icon-delete {
  font-size: 20px;
  color: red !important;
  margin-right: 5px;

  &:hover {
    color: #fc5b50 !important;
  }
}

.moor-button-icon-view {
  font-size: 20px;
  margin-right: 5px;
  color: #2bc3ce !important;

  &:hover {
    color: #3bd1d7 !important;
  }
}

.moor-button-icon-edit {
  font-size: 20px;
  margin-right: 5px;
  color: #0054ff !important;

  &:hover {
    color: #46a0ff !important;
  }
}

.moor-button-icon-default {
  font-size: 20px;
  margin-right: 5px;
  color: #909e9e !important;

  &:hover {
    color: #c6cfd1 !important;
  }
}

.moor-button-add-green {
  background-color: green !important;
  color: white !important;
  //width: 110px;
  //height: 40px;
  &:hover {
    background-color: #00a000 !important;
  }

  &:active {
    background-color: #006000 !important;
  }

  &:disabled {
    background-color: #5a6057 !important;
  }
}

.moor-button-add-detail-green {
  background-color: #00c184 !important;
  color: white !important;
  //width: 110px;
  //height: 40px;
  &:hover {
    background-color: #00a000 !important;
  }

  &:active {
    background-color: #006000 !important;
  }

  &:disabled {
    background-color: #5a6057 !important;
  }
}

.moor-button-reject-red {
  background-color: #9c201f !important;
  color: white !important;

  &:hover {
    background-color: #cb392c !important;
  }

  &:active {
    background-color: #601c18 !important;
  }
}

.moor-button-add-blue {
  background-color: #005888 !important;
  color: white !important;

  &:hover {
    background-color: #006da4 !important;
  }
}

.moor-button-delete-red {
  background-color: #bf2f27 !important;
  color: white !important;

  &:hover {
    color: #e4342c !important;
  }
}

.moor-search {
  padding-left: 15px;
  padding-top: 5px;
  margin: 4px;
  border: 1px double #a2a2a2;

  &__legend {
    width: auto;
    padding: 4px;
    color: #209e91;
  }
}

.alert-success-icon {
  display: flex;
  margin-right: 40px;
  align-items: center;
}

.alert-danger-icon {
  display: flex;
  margin-right: 40px;
  align-items: center;
}

.alert-success {
  color: black !important;
  background-color: #b3e9c1;
  border: 1px solid #089057;
  border-radius: 0;
  display: flex;
  width: 100%;
}

.alert-success::before {
  background-color: #92D400;
}

.pf-alert::before {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  width: 3px;
  content: "";
}

.alert-warning-icon {
  display: flex;
  margin-right: 40px;
  align-items: center;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  display: flex;
  width: 100%;
}

//
.alert-warning::before {
  background-color: #F0AB00;
}

.alert-danger::before {
  background-color: #f00000;
}

.float-logo {
  float: left;
}

.float-button {
  float: right;
}

.deal-tooltip {
  //max-width: 30%;


  .content {
    display: flex;

    img {
      max-width: 21%;
    }

    .mdi {
      font-size: 100px
    }
  }
}

:host ::ng-deep .cdk-overlay-pane {
  max-width: 30% !important;
}

//.cdk-overlay-pane {
//  max-width: 70% !important;
//  overflow-y: auto;
//}

::ng-deep.mat-tooltip {
  background-color: rgba(255, 250, 169, 0.77) !important;
  color: #000000 !important;
  border-style: solid;
  border-width: 1px;
  font-size: 12px !important;
  font-weight: 800 !important;
}

.custom-tooltip {
  background-color: rgba(169, 239, 255, 0.65) !important;
  color: #000000 !important;
  border-style: solid;
  border-width: 1px;
  font-size: 12px !important;
  font-weight: 800 !important;
}
.custom-tooltip-vehicle {
  background-color: rgba(231, 231, 231, 0.77) !important;
  color: #ff0000 !important;
  border-style: solid;
  border-width: 1px;
  font-size: 8px !important;
  font-weight: 500 !important;
}

.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -21px;
}

.ribbon span::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border: 3px solid #79a70a;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.ribbon span::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border: 3px solid #0aa7a2;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.ribbon-right {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon-right span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #0aa78f;
  background: linear-gradient(#0dc9b9 0%, #0aa795 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -4px;
}

.ribbon-right span::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border: 3px solid #0aa795;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.ribbon-right span::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border: 3px solid #0aa79a;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.uploader-input-section {
  max-width: 100%;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.tourism-services-in{
  color: red;
  font-weight: bold;
}
.hotel-img {
  max-width: 13em;
  width: 13em;
}

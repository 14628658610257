.mdi.md-18 {
  font-size: 23px;
}

.mdi.md-green {
  color: #049d6b;
}

.mdi.md-red {
  color: #ff1a1a;
}

.mdi.md-orange {
  color: #e7c728;
}

.mdi.md-black {
  color: #030200;
}

.mdi.md-blue {
  color: #033db2;
}

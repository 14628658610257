// Vertical Tabs
.nav-vertical {
  overflow: hidden;

  .nav-left {
    &.nav-tabs {
      float: left;
      border-bottom: 0;
      border-radius: 0;
      display: table;

      li.nav-item {
        margin: 0;

        a.nav-link {
          min-width: 6.5rem;
          border-right: 1px solid $gray-300;

          &.active {
            border-right: 2px solid $primary !important;
            border-bottom: 0;
          }
        }
      }
    }

    ~ .tab-content {
      .tab-pane {
        display: none;
        padding-left: 1rem;
        overflow-y: auto;

        &.active {
          display: block;
        }
      }
    }
  }

  .nav-right {
    &.nav-tabs {
      float: right;
      border-bottom: 0;
      border-radius: 0;
      display: table;

      li.nav-item {
        margin: 0;

        a.nav-link {
          border-left: 1px solid $gray-300;
          min-width: 6.5rem;

          &.active {
            border-left: 2px solid $primary !important;
            border-bottom: 0;
          }
        }
      }
    }

    ~ .tab-content {
      .tab-pane {
        display: none;
        padding-right: 1rem;
        overflow-y: auto;

        &.active {
          display: block;
        }
      }
    }
  }
}

// Tabs/Navs/Pills
.tab-content {
  padding: 0.375rem;
}

.nav-tabs {
  .nav-link {
    height: 100%;
    border: 0;
    border-radius: 0;

    &.active {
      border-bottom: 2px solid $primary;
    }
  }

  .nav-item {
    &.show {
      .nav-link {
        background-color: transparent;
        border-bottom-color: $white;
      }
    }
  }
}

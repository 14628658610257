
body {
  margin: 0;
}

/* ==============================================================
Bootstrap tweaks
===============================================================*/
//
//body,
//h1,
//h2,
//h3,
//h4 {
//  font-weight: 300;
//}

/* Increase contrast of links to get 100% on Lighthouse Accessability Audit. Override this color if you want to change the link color, or use a Bootswatch theme */
//a,
//a:hover {
//  color: #533f03;
//  font-weight: bold;
//}

a:hover {
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.ng-valid[required],
.ng-valid.required {
  //border-left: 5px solid green;
}

//.ng-invalid:not(form) {
//  border-left: 5px solid red;
//}

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .jhi-toast {
    z-index: 10000;
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .jhi-toast {
    width: 50%;
  }
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
  dd {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      margin-bottom: 15px;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding-left: 180px;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */
.mdi.md-18 {
  font-size: 23px;
}

.mdi.md-delete {
  color: #9f202b;
}

.mdi.md-success {
  color: #398f32;
}

.phone-input-form {
  margin-bottom: 24px;
  span {
    font-size: 13px;
    font-family: sans-serif;
    color: #969696b8;
    font-weight: bold;
    letter-spacing: -0.5px;
  }
  ngx-intl-tel-input {
    border-bottom: 1px solid #009483 !important;
    :invalid {
      border-bottom: 1px solid #942d19 !important;
    }
  }
  mat-error {
    font-weight: 400;
    font-size: 70%;
  }
}

:host ::ng-deep .ngx-mat-tel-input-container input {
  height: 52px !important;
}

:host ::ng-deep ngx-mat-intl-tel-input {
  margin-top: 3px;
  margin-right: 16px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  height: 61px;
  //:hover{
  //  border-radius: 4px;
  //  border: 1px solid #4e4e4e;
  //}
}

:host ::ng-deep .country-selector {
  max-height: 68%;
  margin-top: 3px;
  margin-left: 17px;

  opacity: 1 !important;
}
.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
  transform: none !important;
}

::ng-deep .error-dialog {
  color: #ff0006 !important;
  background-color: white !important;
  border: 2px solid black;
}

.info-msg {
  background-color: #ff5d0059;
  color: #000000;
  padding: 10px;
  border: double #ff7000;
}

.reject-msg {
  background-color: rgb(255 0 0 / 50%);
  color: #ffffff;
  padding: 10px;
  border: double #910209;
}
.grid-field {
  //display: grid;

  mat-form-field {
    width: 100%;

  }
}

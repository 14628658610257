/*------------------------------------------------------------------
[Main Stylesheet]

Project:    Travelz
Version:    1.0
Last change:    12/1/2018
Primary use:    Travel, Tour Booking


---------------------------------------------------------------------

01. Typography
02. Header
03. Global
04. Background color
05. Custom
06. Button
07. Border
08. Border Radius
09. Video
10. Background image
11. Icon
12. Width
13. Height
14. Page title
15. Padding
16. Margin
17. Text Color
18. Footer



-------------------------------------------------------------------*/

/* ===================================
    Typography
====================================== */

body {
  font-family: 'Poppins';
  font-size: 13px;
  line-height: 23px;
}

img {
  max-width: 100%;
}

a {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  color: #f44336;
}

a:hover {
  color: #f44336;
  text-decoration: none;
}

a:focus {
  color: #f44336;
  outline: none;
  text-decoration: none;
}

a:active {
  color: #f44336;
  outline: none;
  text-decoration: none;
}

.btn-primary-travlez {
  background-color: #1db8c1;
  border: none;
  border-radius: 0px;
  font-size: 13px;
}

.btn-primary-travlez:hover {
  background-color: #f44336;
}

input {
  background-color: #f9f9f9;
  padding: 5px;
  border: solid 1px #eee;
  outline: none;
}

.full-width {
  width: 100%;
}

.h1,
h1 {
  font-size: 52px;
}

.h2,
h2 {
  font-size: 42px;
}

.h3,
h3 {
  font-size: 38px;
}

.h4,
h4 {
  font-size: 32px;
}

.h5,
h5 {
  font-size: 24px;
}

.h6,
h6 {
  font-size: 18px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway';
  font-weight: 500;
}

.font-2 {
  font-family: 'Open Sans';
}

.font-3 {
  font-family: 'Open Sans';
}

video {
  background-size: cover;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}

.container-all {
  max-width: 80%;
  margin: auto;
}

/* ===================================
    Header
====================================== */

header {
  position: relative;
  z-index: 9;
  /* Link Padding Top & bottom */
}

header a.mobile-toggle {
  display: none;
}

header header-output .inherit-menu {
  position: static;
}

header .header-in {
  position: relative;
}

header ul.nav-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

header ul.nav-menu > li {
  float: left;
}

header ul.nav-menu > li > a {
  color: #000;
  opacity: 0.7;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  display: block;
  padding: 35px 0px 35px 0px;
  margin-right: 25px;
}

header ul.nav-menu > li :nth-last-child(1) a {
  margin-right: 0px;
}

header ul.nav-menu > li > ul.sub-menu {
  left: 0;
}

header ul.nav-menu > li > ul.sub-menu > li > ul.sub-menu {
  left: 200px;
  top: 0;
}

header ul.nav-menu > li ul.sub-menu {
  transform: translateY(10%);
  -webkit-transform: translateY(10%);
  -moz-transform: translateY(10%);
  -o-transform: translateY(10%);
  -ms-transform: translateY(10%);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 90px;
  width: 200px;
  visibility: hidden;
  /*  mix */
  opacity: 0;
}

header ul.nav-menu > li ul.sub-menu li {
  display: block;
}

header ul.nav-menu > li ul.sub-menu li a {
  color: #000;
  opacity: 0.7;
  display: block;
  padding: 10px 20px 10px 20px;
  text-transform: capitalize;
  border: 1px solid #e5e5e5;
  margin-top: -1px;
}

header ul.nav-menu > li ul.sub-menu li a:hover {
  color: #000;
  opacity: 1;
  padding-left: 25px;
}

header ul.nav-menu > li ul.sub-menu li a:hover:before {
  transition: all 0.4s;
  content: '\f105';
  font-family: FontAwesome;
  color: #f3be4e;
  margin-right: 5px;
}

header ul.nav-menu > li:nth-last-child(1) > a {
  margin-right: 0px;
}

header ul.nav-menu > li:hover > ul.sub-menu {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

header ul.nav-menu > li:hover > ul.sub-menu > li:hover > ul.sub-menu {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

header ul.nav-menu > li.active > a:before {
  content: '\f068';
  font-family: FontAwesome;
  color: #fdbe19;
  margin-right: 5px;
}

header ul.nav-menu > li:nth-last-child(1) > ul.sub-menu,
header ul.nav-menu > li:nth-last-child(2) > ul.sub-menu {
  left: auto;
  right: 0;
}

header ul.nav-menu > li.active > a {
  color: #1db8c1;
}

header ul.nav-menu > li > a:hover {
  color: #1db8c1;
}

header ul.nav-menu > li.has-dropdown {
  position: relative;
}

header ul.nav-menu > li.has-dropdown > a:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #000;
  opacity: 0.5;
  margin-left: 3px;
}

header ul.nav-menu > li.has-dropdown > ul > li.has-dropdown {
  position: relative;
}

header ul.nav-menu > li.has-dropdown > ul > li.has-dropdown > a:after {
  content: '\f105';
  font-family: FontAwesome;
  color: #000;
  opacity: 0.5;
  margin-left: 3px;
  position: absolute;
  right: 20px;
}

header ul.nav-menu > li.mega-menu > a:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #999;
  margin-left: 3px;
}

header ul.nav-menu li.mega-menu:hover .mega-menu-out {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  opacity: 1;
  visibility: visible;
  display: block;
}

header ul.nav-menu .mega-menu-out {
  background-color: #fff;
  transform: translateY(10%);
  -webkit-transform: translateY(10%);
  -moz-transform: translateY(10%);
  -o-transform: translateY(10%);
  -ms-transform: translateY(10%);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  left: 0;
  top: 89px;
  width: 100%;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  border: 1px solid #e0e0e0;
}

header ul.nav-menu .mega-menu-out:after {
  content: ' ';
  display: block;
  clear: both;
  width: 100%;
}

header ul.nav-menu .mega-menu-out .mega-menu-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

header ul.nav-menu .mega-menu-out .mega-menu-list li a {
  color: #000;
  opacity: 0.7;
  text-transform: capitalize;
  padding: 8px 0px 8px 0px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #eee;
}

header ul.nav-menu .mega-menu-out .mega-menu-list li a:hover {
  color: #f44336;
  padding-left: 5px;
}

header ul.nav-menu .mega-menu-out .mega-menu-list li a:hover:before {
  transition: all 0.4s;
  content: '\f105';
  font-family: FontAwesome;
  color: #f3be4e;
  margin-right: 5px;
}

header ul.dropdown-dark .mega-menu-out {
  background-color: #212121;
  border: 1px solid #424242;
}

header ul.dropdown-dark .mega-menu-out .mega-menu-list li a {
  color: #fff;
  opacity: 0.7;
  border-bottom: 1px solid #424242;
}

header ul.dropdown-dark .mega-menu-out .mega-menu-list li a:hover {
  color: #fff;
  opacity: 1;
}

header ul.dropdown-dark .mega-menu-out .mega-menu-list li a:hover:before {
  color: #f3be4e;
}

header ul.dropdown-dark .mega-links-4columns ul.sub-menu-columns > li > a {
  color: #fff;
  border-bottom: 1px solid #616161;
}

header ul.dropdown-dark li > ul.sub-menu {
  background-color: #212121;
}

header ul.dropdown-dark li > ul.sub-menu > li {
  display: block;
}

header ul.dropdown-dark li > ul.sub-menu > li > a {
  color: #fff;
  border: 1px solid #616161;
}

header ul.dropdown-dark li > ul.sub-menu > li a:hover {
  color: #fff;
}

header ul.dropdown-dark li > ul.sub-menu > li a:hover:before {
  color: #52d0d6;
}

header ul.dropdown-dark > li.has-dropdown {
  position: relative;
}

header ul.dropdown-dark > li.has-dropdown li.has-dropdown > a:after {
  color: #fff !important;
}

header ul.white-link > li > a {
  color: #fff;
  opacity: 0.8;
}

header ul.white-link > li a:hover {
  color: #fff;
  opacity: 1;
}

header ul.white-link > li.has-dropdown {
  position: relative;
}

header ul.white-link > li.has-dropdown > a:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #fff;
  opacity: 0.8;
  margin-left: 3px;
}

header ul.white-link > li.active > a:before {
  color: #fff;
}

header ul.white-link > li.active > a {
  color: #fff;
}

header .mega-links-4columns ul.sub-menu-columns {
  padding: 0;
  margin: 0;
  list-style: none;
}

header .mega-links-4columns ul.sub-menu-columns > li {
  width: 25%;
  float: left;
  padding: 0px 10px 10px 10px;
}

header .mega-links-4columns ul.sub-menu-columns > li > a {
  padding: 0px 0px 10px 0px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  color: #1db8c1;
  border-bottom: 1px solid #dcdcdc;
  display: block;
}

header ul.link-padding-tb-0px > li > a {
  padding-top: 0px;
  padding-bottom: 0px;
}

header ul.link-padding-tb-0px > li > ul.sub-menu {
  top: 23px;
}

header ul.link-padding-tb-0px .mega-menu-out {
  top: 23px;
}

header ul.link-padding-tb-1px > li > a {
  padding-top: 1px;
  padding-bottom: 1px;
}

header ul.link-padding-tb-1px > li > ul.sub-menu {
  top: 25px;
}

header ul.link-padding-tb-1px .mega-menu-out {
  top: 25px;
}

header ul.link-padding-tb-2px > li > a {
  padding-top: 2px;
  padding-bottom: 2px;
}

header ul.link-padding-tb-2px > li > ul.sub-menu {
  top: 27px;
}

header ul.link-padding-tb-2px .mega-menu-out {
  top: 27px;
}

header ul.link-padding-tb-3px > li > a {
  padding-top: 3px;
  padding-bottom: 3px;
}

header ul.link-padding-tb-3px > li > ul.sub-menu {
  top: 29px;
}

header ul.link-padding-tb-3px .mega-menu-out {
  top: 29px;
}

header ul.link-padding-tb-4px > li > a {
  padding-top: 4px;
  padding-bottom: 4px;
}

header ul.link-padding-tb-4px > li > ul.sub-menu {
  top: 31px;
}

header ul.link-padding-tb-4px .mega-menu-out {
  top: 31px;
}

header ul.link-padding-tb-5px > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
}

header ul.link-padding-tb-5px > li > ul.sub-menu {
  top: 33px;
}

header ul.link-padding-tb-5px .mega-menu-out {
  top: 33px;
}

header ul.link-padding-tb-6px > li > a {
  padding-top: 6px;
  padding-bottom: 6px;
}

header ul.link-padding-tb-6px > li > ul.sub-menu {
  top: 35px;
}

header ul.link-padding-tb-6px .mega-menu-out {
  top: 35px;
}

header ul.link-padding-tb-7px > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}

header ul.link-padding-tb-7px > li > ul.sub-menu {
  top: 37px;
}

header ul.link-padding-tb-7px .mega-menu-out {
  top: 37px;
}

header ul.link-padding-tb-8px > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
}

header ul.link-padding-tb-8px > li > ul.sub-menu {
  top: 39px;
}

header ul.link-padding-tb-8px .mega-menu-out {
  top: 39px;
}

header ul.link-padding-tb-9px > li > a {
  padding-top: 9px;
  padding-bottom: 9px;
}

header ul.link-padding-tb-9px > li > ul.sub-menu {
  top: 41px;
}

header ul.link-padding-tb-9px .mega-menu-out {
  top: 41px;
}

header ul.link-padding-tb-10px > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
}

header ul.link-padding-tb-10px > li > ul.sub-menu {
  top: 43px;
}

header ul.link-padding-tb-10px .mega-menu-out {
  top: 43px;
}

header ul.link-padding-tb-11px > li > a {
  padding-top: 11px;
  padding-bottom: 11px;
}

header ul.link-padding-tb-11px > li > ul.sub-menu {
  top: 45px;
}

header ul.link-padding-tb-11px .mega-menu-out {
  top: 45px;
}

header ul.link-padding-tb-12px > li > a {
  padding-top: 12px;
  padding-bottom: 12px;
}

header ul.link-padding-tb-12px > li > ul.sub-menu {
  top: 47px;
}

header ul.link-padding-tb-12px .mega-menu-out {
  top: 47px;
}

header ul.link-padding-tb-13px > li > a {
  padding-top: 13px;
  padding-bottom: 13px;
}

header ul.link-padding-tb-13px > li > ul.sub-menu {
  top: 49px;
}

header ul.link-padding-tb-13px .mega-menu-out {
  top: 49px;
}

header ul.link-padding-tb-14px > li > a {
  padding-top: 14px;
  padding-bottom: 14px;
}

header ul.link-padding-tb-14px > li > ul.sub-menu {
  top: 51px;
}

header ul.link-padding-tb-14px .mega-menu-out {
  top: 51px;
}

header ul.link-padding-tb-15px > li > a {
  padding-top: 15px;
  padding-bottom: 15px;
}

header ul.link-padding-tb-15px > li > ul.sub-menu {
  top: 53px;
}

header ul.link-padding-tb-15px .mega-menu-out {
  top: 53px;
}

header ul.link-padding-tb-16px > li > a {
  padding-top: 16px;
  padding-bottom: 16px;
}

header ul.link-padding-tb-16px > li > ul.sub-menu {
  top: 55px;
}

header ul.link-padding-tb-16px .mega-menu-out {
  top: 55px;
}

header ul.link-padding-tb-17px > li > a {
  padding-top: 17px;
  padding-bottom: 17px;
}

header ul.link-padding-tb-17px > li > ul.sub-menu {
  top: 57px;
}

header ul.link-padding-tb-17px .mega-menu-out {
  top: 57px;
}

header ul.link-padding-tb-18px > li > a {
  padding-top: 18px;
  padding-bottom: 18px;
}

header ul.link-padding-tb-18px > li > ul.sub-menu {
  top: 59px;
}

header ul.link-padding-tb-18px .mega-menu-out {
  top: 59px;
}

header ul.link-padding-tb-19px > li > a {
  padding-top: 19px;
  padding-bottom: 19px;
}

header ul.link-padding-tb-19px > li > ul.sub-menu {
  top: 61px;
}

header ul.link-padding-tb-19px .mega-menu-out {
  top: 61px;
}

header ul.link-padding-tb-20px > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}

header ul.link-padding-tb-20px > li > ul.sub-menu {
  top: 63px;
}

header ul.link-padding-tb-20px .mega-menu-out {
  top: 63px;
}

header ul.link-padding-tb-21px > li > a {
  padding-top: 21px;
  padding-bottom: 21px;
}

header ul.link-padding-tb-21px > li > ul.sub-menu {
  top: 65px;
}

header ul.link-padding-tb-21px .mega-menu-out {
  top: 65px;
}

header ul.link-padding-tb-22px > li > a {
  padding-top: 22px;
  padding-bottom: 22px;
}

header ul.link-padding-tb-22px > li > ul.sub-menu {
  top: 67px;
}

header ul.link-padding-tb-22px .mega-menu-out {
  top: 67px;
}

header ul.link-padding-tb-23px > li > a {
  padding-top: 23px;
  padding-bottom: 23px;
}

header ul.link-padding-tb-23px > li > ul.sub-menu {
  top: 69px;
}

header ul.link-padding-tb-23px .mega-menu-out {
  top: 69px;
}

header ul.link-padding-tb-24px > li > a {
  padding-top: 24px;
  padding-bottom: 24px;
}

header ul.link-padding-tb-24px > li > ul.sub-menu {
  top: 71px;
}

header ul.link-padding-tb-24px .mega-menu-out {
  top: 71px;
}

header ul.link-padding-tb-25px > li > a {
  padding-top: 25px;
  padding-bottom: 25px;
}

header ul.link-padding-tb-25px > li > ul.sub-menu {
  top: 73px;
}

header ul.link-padding-tb-25px .mega-menu-out {
  top: 73px;
}

header ul.link-padding-tb-26px > li > a {
  padding-top: 26px;
  padding-bottom: 26px;
}

header ul.link-padding-tb-26px > li > ul.sub-menu {
  top: 75px;
}

header ul.link-padding-tb-26px .mega-menu-out {
  top: 75px;
}

header ul.link-padding-tb-27px > li > a {
  padding-top: 27px;
  padding-bottom: 27px;
}

header ul.link-padding-tb-27px > li > ul.sub-menu {
  top: 77px;
}

header ul.link-padding-tb-27px .mega-menu-out {
  top: 77px;
}

header ul.link-padding-tb-28px > li > a {
  padding-top: 28px;
  padding-bottom: 28px;
}

header ul.link-padding-tb-28px > li > ul.sub-menu {
  top: 79px;
}

header ul.link-padding-tb-28px .mega-menu-out {
  top: 79px;
}

header ul.link-padding-tb-29px > li > a {
  padding-top: 29px;
  padding-bottom: 29px;
}

header ul.link-padding-tb-29px > li > ul.sub-menu {
  top: 81px;
}

header ul.link-padding-tb-29px .mega-menu-out {
  top: 81px;
}

header ul.link-padding-tb-30px > li > a {
  padding-top: 30px;
  padding-bottom: 30px;
}

header ul.link-padding-tb-30px > li > ul.sub-menu {
  top: 83px;
}

header ul.link-padding-tb-30px .mega-menu-out {
  top: 83px;
}

header ul.link-padding-tb-31px > li > a {
  padding-top: 31px;
  padding-bottom: 31px;
}

header ul.link-padding-tb-31px > li > ul.sub-menu {
  top: 85px;
}

header ul.link-padding-tb-31px .mega-menu-out {
  top: 85px;
}

header ul.link-padding-tb-32px > li > a {
  padding-top: 32px;
  padding-bottom: 32px;
}

header ul.link-padding-tb-32px > li > ul.sub-menu {
  top: 87px;
}

header ul.link-padding-tb-32px .mega-menu-out {
  top: 87px;
}

header ul.link-padding-tb-33px > li > a {
  padding-top: 33px;
  padding-bottom: 33px;
}

header ul.link-padding-tb-33px > li > ul.sub-menu {
  top: 89px;
}

header ul.link-padding-tb-33px .mega-menu-out {
  top: 89px;
}

header ul.link-padding-tb-34px > li > a {
  padding-top: 34px;
  padding-bottom: 34px;
}

header ul.link-padding-tb-34px > li > ul.sub-menu {
  top: 91px;
}

header ul.link-padding-tb-34px .mega-menu-out {
  top: 91px;
}

header ul.link-padding-tb-35px > li > a {
  padding-top: 35px;
  padding-bottom: 35px;
}

header ul.link-padding-tb-35px > li > ul.sub-menu {
  top: 93px;
}

header ul.link-padding-tb-35px .mega-menu-out {
  top: 93px;
}

header ul.link-padding-tb-36px > li > a {
  padding-top: 36px;
  padding-bottom: 36px;
}

header ul.link-padding-tb-36px > li > ul.sub-menu {
  top: 95px;
}

header ul.link-padding-tb-36px .mega-menu-out {
  top: 95px;
}

header ul.link-padding-tb-37px > li > a {
  padding-top: 37px;
  padding-bottom: 37px;
}

header ul.link-padding-tb-37px > li > ul.sub-menu {
  top: 97px;
}

header ul.link-padding-tb-37px .mega-menu-out {
  top: 97px;
}

header ul.link-padding-tb-38px > li > a {
  padding-top: 38px;
  padding-bottom: 38px;
}

header ul.link-padding-tb-38px > li > ul.sub-menu {
  top: 99px;
}

header ul.link-padding-tb-38px .mega-menu-out {
  top: 99px;
}

header ul.link-padding-tb-39px > li > a {
  padding-top: 39px;
  padding-bottom: 39px;
}

header ul.link-padding-tb-39px > li > ul.sub-menu {
  top: 101px;
}

header ul.link-padding-tb-39px .mega-menu-out {
  top: 101px;
}

header ul.link-padding-tb-40px > li > a {
  padding-top: 40px;
  padding-bottom: 40px;
}

header ul.link-padding-tb-40px > li > ul.sub-menu {
  top: 103px;
}

header ul.link-padding-tb-40px .mega-menu-out {
  top: 103px;
}

header ul.link-padding-tb-41px > li > a {
  padding-top: 41px;
  padding-bottom: 41px;
}

header ul.link-padding-tb-41px > li > ul.sub-menu {
  top: 105px;
}

header ul.link-padding-tb-41px .mega-menu-out {
  top: 105px;
}

header ul.link-padding-tb-42px > li > a {
  padding-top: 42px;
  padding-bottom: 42px;
}

header ul.link-padding-tb-42px > li > ul.sub-menu {
  top: 107px;
}

header ul.link-padding-tb-42px .mega-menu-out {
  top: 107px;
}

header ul.link-padding-tb-43px > li > a {
  padding-top: 43px;
  padding-bottom: 43px;
}

header ul.link-padding-tb-43px > li > ul.sub-menu {
  top: 109px;
}

header ul.link-padding-tb-43px .mega-menu-out {
  top: 109px;
}

header ul.link-padding-tb-44px > li > a {
  padding-top: 44px;
  padding-bottom: 44px;
}

header ul.link-padding-tb-44px > li > ul.sub-menu {
  top: 111px;
}

header ul.link-padding-tb-44px .mega-menu-out {
  top: 111px;
}

header ul.link-padding-tb-45px > li > a {
  padding-top: 45px;
  padding-bottom: 45px;
}

header ul.link-padding-tb-45px > li > ul.sub-menu {
  top: 113px;
}

header ul.link-padding-tb-45px .mega-menu-out {
  top: 113px;
}

/* ------ header search ------*/

header.header-search .search-ba {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+30&1+0,0+30 */
  background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 30%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 30%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 30%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
  /* IE6-9 */
}

header.header-search .form-control {
  background-color: rgba(255, 255, 255, 0.4);
}

header.header-search .dropdown-toggle::after {
  display: none;
}

header.header-search .dropdown-menu a.dropdown-item {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

header.header-search .dropdown-menu a.dropdown-item:hover {
  color: #fff;
  background-color: #f44336;
}

header.header-search .dropdown-menu a.dropdown-item .fa {
  color: #fff;
  margin-right: 15px;
}

header.header-search .form-control-lg,
header.header-search .input-group-lg > .form-control,
header.header-search .input-group-lg > .input-group-addon,
header.header-search .input-group-lg > .input-group-btn > .btn {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 25px 15px 25px;
  font-family: 'Poppins';
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.fixed-header-dark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #212121;
}

header a.model-link {
  display: block;
  width: 40px;
  border: 1px solid #eee;
  text-align: center;
  padding: 5px;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 15px;
  border-radius: 3px;
  position: relative;
  color: #a7a7a7;
}

header a.model-link span {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  padding: 1px;
  border-radius: 20px;
  background-color: #fdbe19;
  color: #fff;
}

header .search-link {
  margin-right: 10px;
}

header .model-link {
  position: relative;
}

header#sidebar-header ul.float-right {
  float: none !important;
}

header#sidebar-header ul.nav-menu > li {
  float: none;
  display: block;
}

header#sidebar-header ul.nav-menu > li > a {
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 0px;
}

header#sidebar-header ul.nav-menu > li ul {
  display: none;
}

header#sidebar-header ul.nav-menu > li > ul.sub-menu {
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  transition: all 0s ease;
  top: auto;
  width: 100%;
  position: relative;
  visibility: inherit;
  opacity: 1;
  display: none;
}

header#sidebar-header ul.nav-menu > li > ul.sub-menu > li > ul.sub-menu {
  left: auto;
  top: auto;
  width: 100%;
  position: relative;
  visibility: inherit;
  opacity: 1;
}

header#sidebar-header ul.nav-menu > li.has-dropdown > a:after,
header#sidebar-header ul.nav-menu > li.mega-menu > a:after {
  position: absolute;
  right: 5px;
  margin-left: 0px;
}

header#sidebar-header ul.nav-menu .mega-menu-out {
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  transition: all 0s ease;
  top: auto;
  width: 100%;
  position: relative;
  visibility: inherit;
  opacity: 1;
  padding: 0px;
  border: none;
}

header#sidebar-header ul.nav-menu .mega-links-4columns ul.sub-menu-columns {
  padding: 10px 0 10px;
}

header#sidebar-header ul.nav-menu .mega-links-4columns ul.sub-menu-columns > li {
  width: 100%;
  position: relative;
}

header#sidebar-header ul.nav-menu .mega-links-4columns ul.sub-menu-columns > li > a:after {
  position: absolute;
  right: 20px;
  margin-left: 0px;
  transition: all 0.4s;
  content: '\f107';
  font-family: FontAwesome;
}

/* ==================================================================== *
/*     Global        -------------------------------------------------- *
======================================================================= */

/*--- Global Background ---*/

.background-main-color {
  background-color: #1db8c1;
}

.background-main-color-2 {
  background-color: #52d0d6;
}

.background-second-color {
  background-color: #fdbe19;
}

.background-third-color {
  background-color: #f44336;
}

.background-red {
  background-color: #e91e63;
}

.background-pink {
  background-color: #f44336;
}

.background-purple {
  background-color: #9c27b0;
}

.background-blue {
  background-color: #2196f3;
}

.background-light-green {
  background-color: #8bc34a;
}

.background-lime {
  background-color: #cddc39;
}

.background-lime-1 {
  background-color: #f0f4c3;
}

.background-yellow {
  background-color: #ffeb3b;
}

.background-amber {
  background-color: #ffc107;
}

.background-orange {
  background-color: #ff9800;
}

.background-light-grey {
  background-color: #fafafa;
}

/*.background-section1 {*/
/*  !*background-color: #fafafa;*!*/
/*  background: url('../../template/images/sea-world.jpg') no-repeat;*/
/*  background-size: cover;*/
/*}*/

/*.background-section2 {*/
/*  !*background-color: #fafafa;*!*/
/*  background: url('../../template/images/Earth-Map-Background.png') no-repeat;*/
/*  background-size: cover;*/
/*}*/

.background-grey {
  background-color: #e0e0e0;
}

.background-grey-1 {
  background-color: #85abbb3d;
}

.background-grey-2 {
  background-color: #9e9e9e;
}

.background-grey-3 {
  background-color: #616161;
}

.background-grey-4 {
  background-color: #424242;
}

.background-white {
  background-color: #fff;
}

.background-black {
  background-color: #000;
}

.background-dark {
  background-color: #212121;
}

.background-brown-0 {
  background-color: #efebe9;
}

.background-brown-1 {
  background-color: #795548;
}

.background-brown-2 {
  background-color: #5d4037;
}

.background-brown-3 {
  background-color: #bf360c;
}

/*--- Background Image ---*/

.background-overlay {
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.background-overlay:after {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  top: 0;
  left: 0;
  z-index: 0;
}

.background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.background-img-dark:after {
  background-color: #000;
}

.background-img-white:after {
  background-color: #fff;
}

.background-img-main-color:after {
  background-color: #1db8c1;
}

.background-img-second-color:after {
  background-color: #fdbe19;
}

.background-img-third-color:after {
  background-color: #f44336;
}

.no-background {
  background: none;
}

.breadcrumb {
  display: inherit;
}

.breadcrumb li {
  display: inline-block;
  margin-right: 10px;
}

.breadcrumb li > :after {
  font-family: FontAwesome;
  content: '\f105';
  margin-left: 10px;
}

/*--- Text Color ---*/

.text-main-color {
  color: #1db8c1;
}

.text-second-color {
  color: #fdbe19;
}

.text-third-color {
  color: #f44336;
}

.text-red {
  color: #e91e63;
}

.text-pink {
  color: #f44336;
}

.text-purple {
  color: #9c27b0;
}

.text-blue {
  color: #2196f3;
}

.text-light-green {
  color: #8bc34a;
}

.text-lime {
  color: #cddc39;
}

.text-yellow {
  color: #ffeb3b;
}

.text-amber {
  color: #ffc107;
}

.text-orange {
  color: #ff9800;
}

.text-light-grey {
  color: #fafafa;
}

.text-white,
.text-white a {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-dark {
  color: #212121;
}

.text-grey {
  color: #e0e0e0;
}

.text-grey-2 {
  color: #9e9e9e;
}

.text-grey-3 {
  color: #616161;
}

.text-grey-4 {
  color: #424242;
}

.text-brown-0 {
  color: #efebe9;
}

.text-brown-1 {
  color: #795548;
}

.text-brown-2 {
  color: #5d4037;
}

.text-brown-3 {
  color: #bf360c;
}

/* text size */

.text-extra-small {
  font-size: 11px;
  line-height: 14px;
}

.text-small {
  font-size: 12px;
  line-height: 20px;
}

.text-up-small {
  font-size: 14px;
  line-height: 22px;
}

.text-medium {
  font-size: 16px;
  line-height: 23px;
}

.text-large {
  font-size: 18px;
  line-height: 26px;
}

.text-extra-large {
  font-size: 20px;
  line-height: 26px;
}

.text-title-large {
  font-size: 30px;
  line-height: 34px;
}

/*--- Box Shadow ---*/

.box-shadow {
  -webkit-box-shadow: 0px 0px 32px -8px #858585;
  -moz-box-shadow: 0px 0px 32px -8px #858585;
  box-shadow: 0px 0px 32px -8px #858585;
}

.box-shadow-hover {
  box-shadow: none;
}

.box-shadow-hover:hover {
  -webkit-box-shadow: 0px 0px 18px -8px #858585;
  -moz-box-shadow: 0px 0px 18px -8px #858585;
  box-shadow: 0px 0px 18px -8px #858585;
}

/*-- Position Transform --*/

.position-inherit {
  position: inherit;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-static {
  position: static;
}

.position-static {
  position: fixed;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.full-screen-intro {
  height: calc(100vh - 200px);
}

/*-- Right Position --*/

.right-0px {
  right: 0px;
}

.right-1px {
  right: 1px;
}

.right-2px {
  right: 2px;
}

.right-3px {
  right: 3px;
}

.right-4px {
  right: 4px;
}

.right-5px {
  right: 5px;
}

.right-6px {
  right: 6px;
}

.right-7px {
  right: 7px;
}

.right-8px {
  right: 8px;
}

.right-9px {
  right: 9px;
}

.right-10px {
  right: 10px;
}

.right-11px {
  right: 11px;
}

.right-12px {
  right: 12px;
}

.right-13px {
  right: 13px;
}

.right-14px {
  right: 14px;
}

.right-15px {
  right: 15px;
}

.right-16px {
  right: 16px;
}

.right-17px {
  right: 17px;
}

.right-18px {
  right: 18px;
}

.right-19px {
  right: 19px;
}

.right-20px {
  right: 20px;
}

.right-21px {
  right: 21px;
}

.right-22px {
  right: 22px;
}

.right-23px {
  right: 23px;
}

.right-24px {
  right: 24px;
}

.right-25px {
  right: 25px;
}

.right-26px {
  right: 26px;
}

.right-27px {
  right: 27px;
}

.right-28px {
  right: 28px;
}

.right-29px {
  right: 29px;
}

.right-30px {
  right: 30px;
}

.right-31px {
  right: 31px;
}

.right-32px {
  right: 32px;
}

.right-33px {
  right: 33px;
}

.right-34px {
  right: 34px;
}

.right-35px {
  right: 35px;
}

.right-36px {
  right: 36px;
}

.right-37px {
  right: 37px;
}

.right-38px {
  right: 38px;
}

.right-39px {
  right: 39px;
}

.right-40px {
  right: 40px;
}

.right-41px {
  right: 41px;
}

.right-42px {
  right: 42px;
}

.right-43px {
  right: 43px;
}

.right-44px {
  right: 44px;
}

.right-45px {
  right: 45px;
}

.right-46px {
  right: 46px;
}

.right-47px {
  right: 47px;
}

.right-48px {
  right: 48px;
}

.right-49px {
  right: 49px;
}

.right-50px {
  right: 50px;
}

.right-51px {
  right: 51px;
}

.right-52px {
  right: 52px;
}

.right-53px {
  right: 53px;
}

.right-54px {
  right: 54px;
}

.right-55px {
  right: 55px;
}

.right-56px {
  right: 56px;
}

.right-57px {
  right: 57px;
}

.right-58px {
  right: 58px;
}

.right-59px {
  right: 59px;
}

.right-60px {
  right: 60px;
}

.right-61px {
  right: 61px;
}

.right-62px {
  right: 62px;
}

.right-63px {
  right: 63px;
}

.right-64px {
  right: 64px;
}

.right-65px {
  right: 65px;
}

.right-66px {
  right: 66px;
}

.right-67px {
  right: 67px;
}

.right-68px {
  right: 68px;
}

.right-69px {
  right: 69px;
}

.right-70px {
  right: 70px;
}

.right-71px {
  right: 71px;
}

.right-72px {
  right: 72px;
}

.right-73px {
  right: 73px;
}

.right-74px {
  right: 74px;
}

.right-75px {
  right: 75px;
}

.right-76px {
  right: 76px;
}

.right-77px {
  right: 77px;
}

.right-78px {
  right: 78px;
}

.right-79px {
  right: 79px;
}

.right-80px {
  right: 80px;
}

.right-81px {
  right: 81px;
}

.right-82px {
  right: 82px;
}

.right-83px {
  right: 83px;
}

.right-84px {
  right: 84px;
}

.right-85px {
  right: 85px;
}

.right-86px {
  right: 86px;
}

.right-87px {
  right: 87px;
}

.right-88px {
  right: 88px;
}

.right-89px {
  right: 89px;
}

.right-90px {
  right: 90px;
}

.right-91px {
  right: 91px;
}

.right-92px {
  right: 92px;
}

.right-93px {
  right: 93px;
}

.right-94px {
  right: 94px;
}

.right-95px {
  right: 95px;
}

.right-96px {
  right: 96px;
}

.right-97px {
  right: 97px;
}

.right-98px {
  right: 98px;
}

.right-99px {
  right: 99px;
}

.right-100px {
  right: 100px;
}

.right-101px {
  right: 101px;
}

.right-102px {
  right: 102px;
}

.right-103px {
  right: 103px;
}

.right-104px {
  right: 104px;
}

.right-105px {
  right: 105px;
}

.right-106px {
  right: 106px;
}

.right-107px {
  right: 107px;
}

.right-108px {
  right: 108px;
}

.right-109px {
  right: 109px;
}

.right-110px {
  right: 110px;
}

.right-111px {
  right: 111px;
}

.right-112px {
  right: 112px;
}

.right-113px {
  right: 113px;
}

.right-114px {
  right: 114px;
}

.right-115px {
  right: 115px;
}

.right-116px {
  right: 116px;
}

.right-117px {
  right: 117px;
}

.right-118px {
  right: 118px;
}

.right-119px {
  right: 119px;
}

.right-120px {
  right: 120px;
}

.right-121px {
  right: 121px;
}

.right-122px {
  right: 122px;
}

.right-123px {
  right: 123px;
}

.right-124px {
  right: 124px;
}

.right-125px {
  right: 125px;
}

.right-126px {
  right: 126px;
}

.right-127px {
  right: 127px;
}

.right-128px {
  right: 128px;
}

.right-129px {
  right: 129px;
}

.right-130px {
  right: 130px;
}

.right-131px {
  right: 131px;
}

.right-132px {
  right: 132px;
}

.right-133px {
  right: 133px;
}

.right-134px {
  right: 134px;
}

.right-135px {
  right: 135px;
}

.right-136px {
  right: 136px;
}

.right-137px {
  right: 137px;
}

.right-138px {
  right: 138px;
}

.right-139px {
  right: 139px;
}

.right-140px {
  right: 140px;
}

.right-141px {
  right: 141px;
}

.right-142px {
  right: 142px;
}

.right-143px {
  right: 143px;
}

.right-144px {
  right: 144px;
}

.right-145px {
  right: 145px;
}

.right-146px {
  right: 146px;
}

.right-147px {
  right: 147px;
}

.right-148px {
  right: 148px;
}

.right-149px {
  right: 149px;
}

.right-150px {
  right: 150px;
}

/*-- Left Position --*/

.left-0px {
  left: 0px;
}

.left-1px {
  left: 1px;
}

.left-2px {
  left: 2px;
}

.left-3px {
  left: 3px;
}

.left-4px {
  left: 4px;
}

.left-5px {
  left: 5px;
}

.left-6px {
  left: 6px;
}

.left-7px {
  left: 7px;
}

.left-8px {
  left: 8px;
}

.left-9px {
  left: 9px;
}

.left-10px {
  left: 10px;
}

.left-11px {
  left: 11px;
}

.left-12px {
  left: 12px;
}

.left-13px {
  left: 13px;
}

.left-14px {
  left: 14px;
}

.left-15px {
  left: 15px;
}

.left-16px {
  left: 16px;
}

.left-17px {
  left: 17px;
}

.left-18px {
  left: 18px;
}

.left-19px {
  left: 19px;
}

.left-20px {
  left: 20px;
}

.left-21px {
  left: 21px;
}

.left-22px {
  left: 22px;
}

.left-23px {
  left: 23px;
}

.left-24px {
  left: 24px;
}

.left-25px {
  left: 25px;
}

.left-26px {
  left: 26px;
}

.left-27px {
  left: 27px;
}

.left-28px {
  left: 28px;
}

.left-29px {
  left: 29px;
}

.left-30px {
  left: 30px;
}

.left-31px {
  left: 31px;
}

.left-32px {
  left: 32px;
}

.left-33px {
  left: 33px;
}

.left-34px {
  left: 34px;
}

.left-35px {
  left: 35px;
}

.left-36px {
  left: 36px;
}

.left-37px {
  left: 37px;
}

.left-38px {
  left: 38px;
}

.left-39px {
  left: 39px;
}

.left-40px {
  left: 40px;
}

.left-41px {
  left: 41px;
}

.left-42px {
  left: 42px;
}

.left-43px {
  left: 43px;
}

.left-44px {
  left: 44px;
}

.left-45px {
  left: 45px;
}

.left-46px {
  left: 46px;
}

.left-47px {
  left: 47px;
}

.left-48px {
  left: 48px;
}

.left-49px {
  left: 49px;
}

.left-50px {
  left: 50px;
}

.left-51px {
  left: 51px;
}

.left-52px {
  left: 52px;
}

.left-53px {
  left: 53px;
}

.left-54px {
  left: 54px;
}

.left-55px {
  left: 55px;
}

.left-56px {
  left: 56px;
}

.left-57px {
  left: 57px;
}

.left-58px {
  left: 58px;
}

.left-59px {
  left: 59px;
}

.left-60px {
  left: 60px;
}

.left-61px {
  left: 61px;
}

.left-62px {
  left: 62px;
}

.left-63px {
  left: 63px;
}

.left-64px {
  left: 64px;
}

.left-65px {
  left: 65px;
}

.left-66px {
  left: 66px;
}

.left-67px {
  left: 67px;
}

.left-68px {
  left: 68px;
}

.left-69px {
  left: 69px;
}

.left-70px {
  left: 70px;
}

.left-71px {
  left: 71px;
}

.left-72px {
  left: 72px;
}

.left-73px {
  left: 73px;
}

.left-74px {
  left: 74px;
}

.left-75px {
  left: 75px;
}

.left-76px {
  left: 76px;
}

.left-77px {
  left: 77px;
}

.left-78px {
  left: 78px;
}

.left-79px {
  left: 79px;
}

.left-80px {
  left: 80px;
}

.left-81px {
  left: 81px;
}

.left-82px {
  left: 82px;
}

.left-83px {
  left: 83px;
}

.left-84px {
  left: 84px;
}

.left-85px {
  left: 85px;
}

.left-86px {
  left: 86px;
}

.left-87px {
  left: 87px;
}

.left-88px {
  left: 88px;
}

.left-89px {
  left: 89px;
}

.left-90px {
  left: 90px;
}

.left-91px {
  left: 91px;
}

.left-92px {
  left: 92px;
}

.left-93px {
  left: 93px;
}

.left-94px {
  left: 94px;
}

.left-95px {
  left: 95px;
}

.left-96px {
  left: 96px;
}

.left-97px {
  left: 97px;
}

.left-98px {
  left: 98px;
}

.left-99px {
  left: 99px;
}

.left-100px {
  left: 100px;
}

.left-101px {
  left: 101px;
}

.left-102px {
  left: 102px;
}

.left-103px {
  left: 103px;
}

.left-104px {
  left: 104px;
}

.left-105px {
  left: 105px;
}

.left-106px {
  left: 106px;
}

.left-107px {
  left: 107px;
}

.left-108px {
  left: 108px;
}

.left-109px {
  left: 109px;
}

.left-110px {
  left: 110px;
}

.left-111px {
  left: 111px;
}

.left-112px {
  left: 112px;
}

.left-113px {
  left: 113px;
}

.left-114px {
  left: 114px;
}

.left-115px {
  left: 115px;
}

.left-116px {
  left: 116px;
}

.left-117px {
  left: 117px;
}

.left-118px {
  left: 118px;
}

.left-119px {
  left: 119px;
}

.left-120px {
  left: 120px;
}

.left-121px {
  left: 121px;
}

.left-122px {
  left: 122px;
}

.left-123px {
  left: 123px;
}

.left-124px {
  left: 124px;
}

.left-125px {
  left: 125px;
}

.left-126px {
  left: 126px;
}

.left-127px {
  left: 127px;
}

.left-128px {
  left: 128px;
}

.left-129px {
  left: 129px;
}

.left-130px {
  left: 130px;
}

.left-131px {
  left: 131px;
}

.left-132px {
  left: 132px;
}

.left-133px {
  left: 133px;
}

.left-134px {
  left: 134px;
}

.left-135px {
  left: 135px;
}

.left-136px {
  left: 136px;
}

.left-137px {
  left: 137px;
}

.left-138px {
  left: 138px;
}

.left-139px {
  left: 139px;
}

.left-140px {
  left: 140px;
}

.left-141px {
  left: 141px;
}

.left-142px {
  left: 142px;
}

.left-143px {
  left: 143px;
}

.left-144px {
  left: 144px;
}

.left-145px {
  left: 145px;
}

.left-146px {
  left: 146px;
}

.left-147px {
  left: 147px;
}

.left-148px {
  left: 148px;
}

.left-149px {
  left: 149px;
}

.left-150px {
  left: 150px;
}

/*-- Top Position --*/

.top-0px {
  top: 0px;
}

.top-1px {
  top: 1px;
}

.top-2px {
  top: 2px;
}

.top-3px {
  top: 3px;
}

.top-4px {
  top: 4px;
}

.top-5px {
  top: 5px;
}

.top-6px {
  top: 6px;
}

.top-7px {
  top: 7px;
}

.top-8px {
  top: 8px;
}

.top-9px {
  top: 9px;
}

.top-10px {
  top: 10px;
}

.top-11px {
  top: 11px;
}

.top-12px {
  top: 12px;
}

.top-13px {
  top: 13px;
}

.top-14px {
  top: 14px;
}

.top-15px {
  top: 15px;
}

.top-16px {
  top: 16px;
}

.top-17px {
  top: 17px;
}

.top-18px {
  top: 18px;
}

.top-19px {
  top: 19px;
}

.top-20px {
  top: 20px;
}

.top-21px {
  top: 21px;
}

.top-22px {
  top: 22px;
}

.top-23px {
  top: 23px;
}

.top-24px {
  top: 24px;
}

.top-25px {
  top: 25px;
}

.top-26px {
  top: 26px;
}

.top-27px {
  top: 27px;
}

.top-28px {
  top: 28px;
}

.top-29px {
  top: 29px;
}

.top-30px {
  top: 30px;
}

.top-31px {
  top: 31px;
}

.top-32px {
  top: 32px;
}

.top-33px {
  top: 33px;
}

.top-34px {
  top: 34px;
}

.top-35px {
  top: 35px;
}

.top-36px {
  top: 36px;
}

.top-37px {
  top: 37px;
}

.top-38px {
  top: 38px;
}

.top-39px {
  top: 39px;
}

.top-40px {
  top: 40px;
}

.top-41px {
  top: 41px;
}

.top-42px {
  top: 42px;
}

.top-43px {
  top: 43px;
}

.top-44px {
  top: 44px;
}

.top-45px {
  top: 45px;
}

.top-46px {
  top: 46px;
}

.top-47px {
  top: 47px;
}

.top-48px {
  top: 48px;
}

.top-49px {
  top: 49px;
}

.top-50px {
  top: 50px;
}

.top-51px {
  top: 51px;
}

.top-52px {
  top: 52px;
}

.top-53px {
  top: 53px;
}

.top-54px {
  top: 54px;
}

.top-55px {
  top: 55px;
}

.top-56px {
  top: 56px;
}

.top-57px {
  top: 57px;
}

.top-58px {
  top: 58px;
}

.top-59px {
  top: 59px;
}

.top-60px {
  top: 60px;
}

.top-61px {
  top: 61px;
}

.top-62px {
  top: 62px;
}

.top-63px {
  top: 63px;
}

.top-64px {
  top: 64px;
}

.top-65px {
  top: 65px;
}

.top-66px {
  top: 66px;
}

.top-67px {
  top: 67px;
}

.top-68px {
  top: 68px;
}

.top-69px {
  top: 69px;
}

.top-70px {
  top: 70px;
}

.top-71px {
  top: 71px;
}

.top-72px {
  top: 72px;
}

.top-73px {
  top: 73px;
}

.top-74px {
  top: 74px;
}

.top-75px {
  top: 75px;
}

.top-76px {
  top: 76px;
}

.top-77px {
  top: 77px;
}

.top-78px {
  top: 78px;
}

.top-79px {
  top: 79px;
}

.top-80px {
  top: 80px;
}

.top-81px {
  top: 81px;
}

.top-82px {
  top: 82px;
}

.top-83px {
  top: 83px;
}

.top-84px {
  top: 84px;
}

.top-85px {
  top: 85px;
}

.top-86px {
  top: 86px;
}

.top-87px {
  top: 87px;
}

.top-88px {
  top: 88px;
}

.top-89px {
  top: 89px;
}

.top-90px {
  top: 90px;
}

.top-91px {
  top: 91px;
}

.top-92px {
  top: 92px;
}

.top-93px {
  top: 93px;
}

.top-94px {
  top: 94px;
}

.top-95px {
  top: 95px;
}

.top-96px {
  top: 96px;
}

.top-97px {
  top: 97px;
}

.top-98px {
  top: 98px;
}

.top-99px {
  top: 99px;
}

.top-100px {
  top: 100px;
}

.top-101px {
  top: 101px;
}

.top-102px {
  top: 102px;
}

.top-103px {
  top: 103px;
}

.top-104px {
  top: 104px;
}

.top-105px {
  top: 105px;
}

.top-106px {
  top: 106px;
}

.top-107px {
  top: 107px;
}

.top-108px {
  top: 108px;
}

.top-109px {
  top: 109px;
}

.top-110px {
  top: 110px;
}

.top-111px {
  top: 111px;
}

.top-112px {
  top: 112px;
}

.top-113px {
  top: 113px;
}

.top-114px {
  top: 114px;
}

.top-115px {
  top: 115px;
}

.top-116px {
  top: 116px;
}

.top-117px {
  top: 117px;
}

.top-118px {
  top: 118px;
}

.top-119px {
  top: 119px;
}

.top-120px {
  top: 120px;
}

.top-121px {
  top: 121px;
}

.top-122px {
  top: 122px;
}

.top-123px {
  top: 123px;
}

.top-124px {
  top: 124px;
}

.top-125px {
  top: 125px;
}

.top-126px {
  top: 126px;
}

.top-127px {
  top: 127px;
}

.top-128px {
  top: 128px;
}

.top-129px {
  top: 129px;
}

.top-130px {
  top: 130px;
}

.top-131px {
  top: 131px;
}

.top-132px {
  top: 132px;
}

.top-133px {
  top: 133px;
}

.top-134px {
  top: 134px;
}

.top-135px {
  top: 135px;
}

.top-136px {
  top: 136px;
}

.top-137px {
  top: 137px;
}

.top-138px {
  top: 138px;
}

.top-139px {
  top: 139px;
}

.top-140px {
  top: 140px;
}

.top-141px {
  top: 141px;
}

.top-142px {
  top: 142px;
}

.top-143px {
  top: 143px;
}

.top-144px {
  top: 144px;
}

.top-145px {
  top: 145px;
}

.top-146px {
  top: 146px;
}

.top-147px {
  top: 147px;
}

.top-148px {
  top: 148px;
}

.top-149px {
  top: 149px;
}

.top-150px {
  top: 150px;
}

/*-- Bottom Position --*/

.bottom-0px {
  bottom: 0px;
}

.bottom-1px {
  bottom: 1px;
}

.bottom-2px {
  bottom: 2px;
}

.bottom-3px {
  bottom: 3px;
}

.bottom-4px {
  bottom: 4px;
}

.bottom-5px {
  bottom: 5px;
}

.bottom-6px {
  bottom: 6px;
}

.bottom-7px {
  bottom: 7px;
}

.bottom-8px {
  bottom: 8px;
}

.bottom-9px {
  bottom: 9px;
}

.bottom-10px {
  bottom: 10px;
}

.bottom-11px {
  bottom: 11px;
}

.bottom-12px {
  bottom: 12px;
}

.bottom-13px {
  bottom: 13px;
}

.bottom-14px {
  bottom: 14px;
}

.bottom-15px {
  bottom: 15px;
}

.bottom-16px {
  bottom: 16px;
}

.bottom-17px {
  bottom: 17px;
}

.bottom-18px {
  bottom: 18px;
}

.bottom-19px {
  bottom: 19px;
}

.bottom-20px {
  bottom: 20px;
}

.bottom-21px {
  bottom: 21px;
}

.bottom-22px {
  bottom: 22px;
}

.bottom-23px {
  bottom: 23px;
}

.bottom-24px {
  bottom: 24px;
}

.bottom-25px {
  bottom: 25px;
}

.bottom-26px {
  bottom: 26px;
}

.bottom-27px {
  bottom: 27px;
}

.bottom-28px {
  bottom: 28px;
}

.bottom-29px {
  bottom: 29px;
}

.bottom-30px {
  bottom: 30px;
}

.bottom-31px {
  bottom: 31px;
}

.bottom-32px {
  bottom: 32px;
}

.bottom-33px {
  bottom: 33px;
}

.bottom-34px {
  bottom: 34px;
}

.bottom-35px {
  bottom: 35px;
}

.bottom-36px {
  bottom: 36px;
}

.bottom-37px {
  bottom: 37px;
}

.bottom-38px {
  bottom: 38px;
}

.bottom-39px {
  bottom: 39px;
}

.bottom-40px {
  bottom: 40px;
}

.bottom-41px {
  bottom: 41px;
}

.bottom-42px {
  bottom: 42px;
}

.bottom-43px {
  bottom: 43px;
}

.bottom-44px {
  bottom: 44px;
}

.bottom-45px {
  bottom: 45px;
}

.bottom-46px {
  bottom: 46px;
}

.bottom-47px {
  bottom: 47px;
}

.bottom-48px {
  bottom: 48px;
}

.bottom-49px {
  bottom: 49px;
}

.bottom-50px {
  bottom: 50px;
}

.bottom-51px {
  bottom: 51px;
}

.bottom-52px {
  bottom: 52px;
}

.bottom-53px {
  bottom: 53px;
}

.bottom-54px {
  bottom: 54px;
}

.bottom-55px {
  bottom: 55px;
}

.bottom-56px {
  bottom: 56px;
}

.bottom-57px {
  bottom: 57px;
}

.bottom-58px {
  bottom: 58px;
}

.bottom-59px {
  bottom: 59px;
}

.bottom-60px {
  bottom: 60px;
}

.bottom-61px {
  bottom: 61px;
}

.bottom-62px {
  bottom: 62px;
}

.bottom-63px {
  bottom: 63px;
}

.bottom-64px {
  bottom: 64px;
}

.bottom-65px {
  bottom: 65px;
}

.bottom-66px {
  bottom: 66px;
}

.bottom-67px {
  bottom: 67px;
}

.bottom-68px {
  bottom: 68px;
}

.bottom-69px {
  bottom: 69px;
}

.bottom-70px {
  bottom: 70px;
}

.bottom-71px {
  bottom: 71px;
}

.bottom-72px {
  bottom: 72px;
}

.bottom-73px {
  bottom: 73px;
}

.bottom-74px {
  bottom: 74px;
}

.bottom-75px {
  bottom: 75px;
}

.bottom-76px {
  bottom: 76px;
}

.bottom-77px {
  bottom: 77px;
}

.bottom-78px {
  bottom: 78px;
}

.bottom-79px {
  bottom: 79px;
}

.bottom-80px {
  bottom: 80px;
}

.bottom-81px {
  bottom: 81px;
}

.bottom-82px {
  bottom: 82px;
}

.bottom-83px {
  bottom: 83px;
}

.bottom-84px {
  bottom: 84px;
}

.bottom-85px {
  bottom: 85px;
}

.bottom-86px {
  bottom: 86px;
}

.bottom-87px {
  bottom: 87px;
}

.bottom-88px {
  bottom: 88px;
}

.bottom-89px {
  bottom: 89px;
}

.bottom-90px {
  bottom: 90px;
}

.bottom-91px {
  bottom: 91px;
}

.bottom-92px {
  bottom: 92px;
}

.bottom-93px {
  bottom: 93px;
}

.bottom-94px {
  bottom: 94px;
}

.bottom-95px {
  bottom: 95px;
}

.bottom-96px {
  bottom: 96px;
}

.bottom-97px {
  bottom: 97px;
}

.bottom-98px {
  bottom: 98px;
}

.bottom-99px {
  bottom: 99px;
}

.bottom-100px {
  bottom: 100px;
}

.bottom-101px {
  bottom: 101px;
}

.bottom-102px {
  bottom: 102px;
}

.bottom-103px {
  bottom: 103px;
}

.bottom-104px {
  bottom: 104px;
}

.bottom-105px {
  bottom: 105px;
}

.bottom-106px {
  bottom: 106px;
}

.bottom-107px {
  bottom: 107px;
}

.bottom-108px {
  bottom: 108px;
}

.bottom-109px {
  bottom: 109px;
}

.bottom-110px {
  bottom: 110px;
}

.bottom-111px {
  bottom: 111px;
}

.bottom-112px {
  bottom: 112px;
}

.bottom-113px {
  bottom: 113px;
}

.bottom-114px {
  bottom: 114px;
}

.bottom-115px {
  bottom: 115px;
}

.bottom-116px {
  bottom: 116px;
}

.bottom-117px {
  bottom: 117px;
}

.bottom-118px {
  bottom: 118px;
}

.bottom-119px {
  bottom: 119px;
}

.bottom-120px {
  bottom: 120px;
}

.bottom-121px {
  bottom: 121px;
}

.bottom-122px {
  bottom: 122px;
}

.bottom-123px {
  bottom: 123px;
}

.bottom-124px {
  bottom: 124px;
}

.bottom-125px {
  bottom: 125px;
}

.bottom-126px {
  bottom: 126px;
}

.bottom-127px {
  bottom: 127px;
}

.bottom-128px {
  bottom: 128px;
}

.bottom-129px {
  bottom: 129px;
}

.bottom-130px {
  bottom: 130px;
}

.bottom-131px {
  bottom: 131px;
}

.bottom-132px {
  bottom: 132px;
}

.bottom-133px {
  bottom: 133px;
}

.bottom-134px {
  bottom: 134px;
}

.bottom-135px {
  bottom: 135px;
}

.bottom-136px {
  bottom: 136px;
}

.bottom-137px {
  bottom: 137px;
}

.bottom-138px {
  bottom: 138px;
}

.bottom-139px {
  bottom: 139px;
}

.bottom-140px {
  bottom: 140px;
}

.bottom-141px {
  bottom: 141px;
}

.bottom-142px {
  bottom: 142px;
}

.bottom-143px {
  bottom: 143px;
}

.bottom-144px {
  bottom: 144px;
}

.bottom-145px {
  bottom: 145px;
}

.bottom-146px {
  bottom: 146px;
}

.bottom-147px {
  bottom: 147px;
}

.bottom-148px {
  bottom: 148px;
}

.bottom-149px {
  bottom: 149px;
}

.bottom-150px {
  bottom: 150px;
}

/*-- Auto ---*/

.right-auto {
  right: auto;
}

.left-auto {
  left: auto;
}

.top-auto {
  top: auto;
}

.bottom-auto {
  bottom: auto;
}

.all-auto {
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
}

/*--- Padding all ---*/

.padding-0px {
  padding: 0px;
}

.padding-1px {
  padding: 1px;
}

.padding-2px {
  padding: 2px;
}

.padding-3px {
  padding: 3px;
}

.padding-4px {
  padding: 4px;
}

.padding-5px {
  padding: 5px;
}

.padding-6px {
  padding: 6px;
}

.padding-7px {
  padding: 7px;
}

.padding-8px {
  padding: 8px;
}

.padding-9px {
  padding: 9px;
}

.padding-10px {
  padding: 10px;
}

.padding-11px {
  padding: 11px;
}

.padding-12px {
  padding: 12px;
}

.padding-13px {
  padding: 13px;
}

.padding-14px {
  padding: 14px;
}

.padding-15px {
  padding: 15px;
}

.padding-16px {
  padding: 16px;
}

.padding-17px {
  padding: 17px;
}

.padding-18px {
  padding: 18px;
}

.padding-19px {
  padding: 19px;
}

.padding-20px {
  padding: 20px;
}

.padding-21px {
  padding: 21px;
}

.padding-22px {
  padding: 22px;
}

.padding-23px {
  padding: 23px;
}

.padding-24px {
  padding: 24px;
}

.padding-25px {
  padding: 25px;
}

.padding-26px {
  padding: 26px;
}

.padding-27px {
  padding: 27px;
}

.padding-28px {
  padding: 28px;
}

.padding-29px {
  padding: 29px;
}

.padding-30px {
  padding: 30px;
}

.padding-31px {
  padding: 31px;
}

.padding-32px {
  padding: 32px;
}

.padding-33px {
  padding: 33px;
}

.padding-34px {
  padding: 34px;
}

.padding-35px {
  padding: 35px;
}

.padding-36px {
  padding: 36px;
}

.padding-37px {
  padding: 37px;
}

.padding-38px {
  padding: 38px;
}

.padding-39px {
  padding: 39px;
}

.padding-40px {
  padding: 40px;
}

.padding-41px {
  padding: 41px;
}

.padding-42px {
  padding: 42px;
}

.padding-43px {
  padding: 43px;
}

.padding-44px {
  padding: 44px;
}

.padding-45px {
  padding: 45px;
}

.padding-46px {
  padding: 46px;
}

.padding-47px {
  padding: 47px;
}

.padding-48px {
  padding: 48px;
}

.padding-49px {
  padding: 49px;
}

.padding-50px {
  padding: 50px;
}

.padding-51px {
  padding: 51px;
}

.padding-52px {
  padding: 52px;
}

.padding-53px {
  padding: 53px;
}

.padding-54px {
  padding: 54px;
}

.padding-55px {
  padding: 55px;
}

.padding-56px {
  padding: 56px;
}

.padding-57px {
  padding: 57px;
}

.padding-58px {
  padding: 58px;
}

.padding-59px {
  padding: 59px;
}

.padding-60px {
  padding: 60px;
}

.padding-61px {
  padding: 61px;
}

.padding-62px {
  padding: 62px;
}

.padding-63px {
  padding: 63px;
}

.padding-64px {
  padding: 64px;
}

.padding-65px {
  padding: 65px;
}

.padding-66px {
  padding: 66px;
}

.padding-67px {
  padding: 67px;
}

.padding-68px {
  padding: 68px;
}

.padding-69px {
  padding: 69px;
}

.padding-70px {
  padding: 70px;
}

.padding-71px {
  padding: 71px;
}

.padding-72px {
  padding: 72px;
}

.padding-73px {
  padding: 73px;
}

.padding-74px {
  padding: 74px;
}

.padding-75px {
  padding: 75px;
}

.padding-76px {
  padding: 76px;
}

.padding-77px {
  padding: 77px;
}

.padding-78px {
  padding: 78px;
}

.padding-79px {
  padding: 79px;
}

.padding-80px {
  padding: 80px;
}

.padding-81px {
  padding: 81px;
}

.padding-82px {
  padding: 82px;
}

.padding-83px {
  padding: 83px;
}

.padding-84px {
  padding: 84px;
}

.padding-85px {
  padding: 85px;
}

.padding-86px {
  padding: 86px;
}

.padding-87px {
  padding: 87px;
}

.padding-88px {
  padding: 88px;
}

.padding-89px {
  padding: 89px;
}

.padding-90px {
  padding: 90px;
}

.padding-91px {
  padding: 91px;
}

.padding-92px {
  padding: 92px;
}

.padding-93px {
  padding: 93px;
}

.padding-94px {
  padding: 94px;
}

.padding-95px {
  padding: 95px;
}

.padding-96px {
  padding: 96px;
}

.padding-97px {
  padding: 97px;
}

.padding-98px {
  padding: 98px;
}

.padding-99px {
  padding: 99px;
}

.padding-100px {
  padding: 100px;
}

.padding-101px {
  padding: 101px;
}

.padding-102px {
  padding: 102px;
}

.padding-103px {
  padding: 103px;
}

.padding-104px {
  padding: 104px;
}

.padding-105px {
  padding: 105px;
}

.padding-106px {
  padding: 106px;
}

.padding-107px {
  padding: 107px;
}

.padding-108px {
  padding: 108px;
}

.padding-109px {
  padding: 109px;
}

.padding-110px {
  padding: 110px;
}

.padding-111px {
  padding: 111px;
}

.padding-112px {
  padding: 112px;
}

.padding-113px {
  padding: 113px;
}

.padding-114px {
  padding: 114px;
}

.padding-115px {
  padding: 115px;
}

.padding-116px {
  padding: 116px;
}

.padding-117px {
  padding: 117px;
}

.padding-118px {
  padding: 118px;
}

.padding-119px {
  padding: 119px;
}

.padding-120px {
  padding: 120px;
}

.padding-121px {
  padding: 121px;
}

.padding-122px {
  padding: 122px;
}

.padding-123px {
  padding: 123px;
}

.padding-124px {
  padding: 124px;
}

.padding-125px {
  padding: 125px;
}

.padding-126px {
  padding: 126px;
}

.padding-127px {
  padding: 127px;
}

.padding-128px {
  padding: 128px;
}

.padding-129px {
  padding: 129px;
}

.padding-130px {
  padding: 130px;
}

.padding-131px {
  padding: 131px;
}

.padding-132px {
  padding: 132px;
}

.padding-133px {
  padding: 133px;
}

.padding-134px {
  padding: 134px;
}

.padding-135px {
  padding: 135px;
}

.padding-136px {
  padding: 136px;
}

.padding-137px {
  padding: 137px;
}

.padding-138px {
  padding: 138px;
}

.padding-139px {
  padding: 139px;
}

.padding-140px {
  padding: 140px;
}

.padding-141px {
  padding: 141px;
}

.padding-142px {
  padding: 142px;
}

.padding-143px {
  padding: 143px;
}

.padding-144px {
  padding: 144px;
}

.padding-145px {
  padding: 145px;
}

.padding-146px {
  padding: 146px;
}

.padding-147px {
  padding: 147px;
}

.padding-148px {
  padding: 148px;
}

.padding-149px {
  padding: 149px;
}

.padding-150px {
  padding: 150px;
}

.padding-151px {
  padding: 151px;
}

.padding-152px {
  padding: 152px;
}

.padding-153px {
  padding: 153px;
}

.padding-154px {
  padding: 154px;
}

.padding-155px {
  padding: 155px;
}

.padding-156px {
  padding: 156px;
}

.padding-157px {
  padding: 157px;
}

.padding-158px {
  padding: 158px;
}

.padding-159px {
  padding: 159px;
}

.padding-160px {
  padding: 160px;
}

.padding-161px {
  padding: 161px;
}

.padding-162px {
  padding: 162px;
}

.padding-163px {
  padding: 163px;
}

.padding-164px {
  padding: 164px;
}

.padding-165px {
  padding: 165px;
}

.padding-166px {
  padding: 166px;
}

.padding-167px {
  padding: 167px;
}

.padding-168px {
  padding: 168px;
}

.padding-169px {
  padding: 169px;
}

.padding-170px {
  padding: 170px;
}

.padding-171px {
  padding: 171px;
}

.padding-172px {
  padding: 172px;
}

.padding-173px {
  padding: 173px;
}

.padding-174px {
  padding: 174px;
}

.padding-175px {
  padding: 175px;
}

.padding-176px {
  padding: 176px;
}

.padding-177px {
  padding: 177px;
}

.padding-178px {
  padding: 178px;
}

.padding-179px {
  padding: 179px;
}

.padding-180px {
  padding: 180px;
}

.padding-181px {
  padding: 181px;
}

.padding-182px {
  padding: 182px;
}

.padding-183px {
  padding: 183px;
}

.padding-184px {
  padding: 184px;
}

.padding-185px {
  padding: 185px;
}

.padding-186px {
  padding: 186px;
}

.padding-187px {
  padding: 187px;
}

.padding-188px {
  padding: 188px;
}

.padding-189px {
  padding: 189px;
}

.padding-190px {
  padding: 190px;
}

.padding-191px {
  padding: 191px;
}

.padding-192px {
  padding: 192px;
}

.padding-193px {
  padding: 193px;
}

.padding-194px {
  padding: 194px;
}

.padding-195px {
  padding: 195px;
}

.padding-196px {
  padding: 196px;
}

.padding-197px {
  padding: 197px;
}

.padding-198px {
  padding: 198px;
}

.padding-199px {
  padding: 199px;
}

.padding-200px {
  padding: 200px;
}

.padding-201px {
  padding: 201px;
}

.padding-202px {
  padding: 202px;
}

.padding-203px {
  padding: 203px;
}

.padding-204px {
  padding: 204px;
}

.padding-205px {
  padding: 205px;
}

.padding-206px {
  padding: 206px;
}

.padding-207px {
  padding: 207px;
}

.padding-208px {
  padding: 208px;
}

.padding-209px {
  padding: 209px;
}

.padding-210px {
  padding: 210px;
}

.padding-211px {
  padding: 211px;
}

.padding-212px {
  padding: 212px;
}

.padding-213px {
  padding: 213px;
}

.padding-214px {
  padding: 214px;
}

.padding-215px {
  padding: 215px;
}

.padding-216px {
  padding: 216px;
}

.padding-217px {
  padding: 217px;
}

.padding-218px {
  padding: 218px;
}

.padding-219px {
  padding: 219px;
}

.padding-220px {
  padding: 220px;
}

.padding-221px {
  padding: 221px;
}

.padding-222px {
  padding: 222px;
}

.padding-223px {
  padding: 223px;
}

.padding-224px {
  padding: 224px;
}

.padding-225px {
  padding: 225px;
}

.padding-226px {
  padding: 226px;
}

.padding-227px {
  padding: 227px;
}

.padding-228px {
  padding: 228px;
}

.padding-229px {
  padding: 229px;
}

.padding-230px {
  padding: 230px;
}

.padding-231px {
  padding: 231px;
}

.padding-232px {
  padding: 232px;
}

.padding-233px {
  padding: 233px;
}

.padding-234px {
  padding: 234px;
}

.padding-235px {
  padding: 235px;
}

.padding-236px {
  padding: 236px;
}

.padding-237px {
  padding: 237px;
}

.padding-238px {
  padding: 238px;
}

.padding-239px {
  padding: 239px;
}

.padding-240px {
  padding: 240px;
}

.padding-241px {
  padding: 241px;
}

.padding-242px {
  padding: 242px;
}

.padding-243px {
  padding: 243px;
}

.padding-244px {
  padding: 244px;
}

.padding-245px {
  padding: 245px;
}

.padding-246px {
  padding: 246px;
}

.padding-247px {
  padding: 247px;
}

.padding-248px {
  padding: 248px;
}

.padding-249px {
  padding: 249px;
}

.padding-250px {
  padding: 250px;
}

.padding-251px {
  padding: 251px;
}

.padding-252px {
  padding: 252px;
}

.padding-253px {
  padding: 253px;
}

.padding-254px {
  padding: 254px;
}

.padding-255px {
  padding: 255px;
}

.padding-256px {
  padding: 256px;
}

.padding-257px {
  padding: 257px;
}

.padding-258px {
  padding: 258px;
}

.padding-259px {
  padding: 259px;
}

.padding-260px {
  padding: 260px;
}

.padding-261px {
  padding: 261px;
}

.padding-262px {
  padding: 262px;
}

.padding-263px {
  padding: 263px;
}

.padding-264px {
  padding: 264px;
}

.padding-265px {
  padding: 265px;
}

.padding-266px {
  padding: 266px;
}

.padding-267px {
  padding: 267px;
}

.padding-268px {
  padding: 268px;
}

.padding-269px {
  padding: 269px;
}

.padding-270px {
  padding: 270px;
}

.padding-271px {
  padding: 271px;
}

.padding-272px {
  padding: 272px;
}

.padding-273px {
  padding: 273px;
}

.padding-274px {
  padding: 274px;
}

.padding-275px {
  padding: 275px;
}

.padding-276px {
  padding: 276px;
}

.padding-277px {
  padding: 277px;
}

.padding-278px {
  padding: 278px;
}

.padding-279px {
  padding: 279px;
}

.padding-280px {
  padding: 280px;
}

.padding-281px {
  padding: 281px;
}

.padding-282px {
  padding: 282px;
}

.padding-283px {
  padding: 283px;
}

.padding-284px {
  padding: 284px;
}

.padding-285px {
  padding: 285px;
}

.padding-286px {
  padding: 286px;
}

.padding-287px {
  padding: 287px;
}

.padding-288px {
  padding: 288px;
}

.padding-289px {
  padding: 289px;
}

.padding-290px {
  padding: 290px;
}

.padding-291px {
  padding: 291px;
}

.padding-292px {
  padding: 292px;
}

.padding-293px {
  padding: 293px;
}

.padding-294px {
  padding: 294px;
}

.padding-295px {
  padding: 295px;
}

.padding-296px {
  padding: 296px;
}

.padding-297px {
  padding: 297px;
}

.padding-298px {
  padding: 298px;
}

.padding-299px {
  padding: 299px;
}

.padding-300px {
  padding: 300px;
}

/*--- Padding top & bottom ---*/

.padding-tb-0px {
  padding-top: 0px;
  padding-bottom: 0px;
}

.padding-tb-1px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.padding-tb-2px {
  padding-top: 2px;
  padding-bottom: 2px;
}

.padding-tb-3px {
  padding-top: 3px;
  padding-bottom: 3px;
}

.padding-tb-4px {
  padding-top: 4px;
  padding-bottom: 4px;
}

.padding-tb-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-tb-6px {
  padding-top: 6px;
  padding-bottom: 6px;
}

.padding-tb-7px {
  padding-top: 7px;
  padding-bottom: 7px;
}

.padding-tb-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-tb-9px {
  padding-top: 9px;
  padding-bottom: 9px;
}

.padding-tb-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-tb-11px {
  padding-top: 11px;
  padding-bottom: 11px;
}

.padding-tb-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.padding-tb-13px {
  padding-top: 13px;
  padding-bottom: 13px;
}

.padding-tb-14px {
  padding-top: 14px;
  padding-bottom: 14px;
}

.padding-tb-15px {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-tb-16px {
  padding-top: 16px;
  padding-bottom: 16px;
}

.padding-tb-17px {
  padding-top: 17px;
  padding-bottom: 17px;
}

.padding-tb-18px {
  padding-top: 18px;
  padding-bottom: 18px;
}

.padding-tb-19px {
  padding-top: 19px;
  padding-bottom: 19px;
}

.padding-tb-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-tb-21px {
  padding-top: 21px;
  padding-bottom: 21px;
}

.padding-tb-22px {
  padding-top: 22px;
  padding-bottom: 22px;
}

.padding-tb-23px {
  padding-top: 23px;
  padding-bottom: 23px;
}

.padding-tb-24px {
  padding-top: 24px;
  padding-bottom: 24px;
}

.padding-tb-25px {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding-tb-26px {
  padding-top: 26px;
  padding-bottom: 26px;
}

.padding-tb-27px {
  padding-top: 27px;
  padding-bottom: 27px;
}

.padding-tb-28px {
  padding-top: 28px;
  padding-bottom: 28px;
}

.padding-tb-29px {
  padding-top: 29px;
  padding-bottom: 29px;
}

.padding-tb-30px {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-tb-31px {
  padding-top: 31px;
  padding-bottom: 31px;
}

.padding-tb-32px {
  padding-top: 32px;
  padding-bottom: 32px;
}

.padding-tb-33px {
  padding-top: 33px;
  padding-bottom: 33px;
}

.padding-tb-34px {
  padding-top: 34px;
  padding-bottom: 34px;
}

.padding-tb-35px {
  padding-top: 35px;
  padding-bottom: 35px;
}

.padding-tb-36px {
  padding-top: 36px;
  padding-bottom: 36px;
}

.padding-tb-37px {
  padding-top: 37px;
  padding-bottom: 37px;
}

.padding-tb-38px {
  padding-top: 38px;
  padding-bottom: 38px;
}

.padding-tb-39px {
  padding-top: 39px;
  padding-bottom: 39px;
}

.padding-tb-40px {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-tb-41px {
  padding-top: 41px;
  padding-bottom: 41px;
}

.padding-tb-42px {
  padding-top: 42px;
  padding-bottom: 42px;
}

.padding-tb-43px {
  padding-top: 43px;
  padding-bottom: 43px;
}

.padding-tb-44px {
  padding-top: 44px;
  padding-bottom: 44px;
}

.padding-tb-45px {
  padding-top: 45px;
  padding-bottom: 45px;
}

.padding-tb-46px {
  padding-top: 46px;
  padding-bottom: 46px;
}

.padding-tb-47px {
  padding-top: 47px;
  padding-bottom: 47px;
}

.padding-tb-48px {
  padding-top: 48px;
  padding-bottom: 48px;
}

.padding-tb-49px {
  padding-top: 49px;
  padding-bottom: 49px;
}

.padding-tb-50px {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-tb-51px {
  padding-top: 51px;
  padding-bottom: 51px;
}

.padding-tb-52px {
  padding-top: 52px;
  padding-bottom: 52px;
}

.padding-tb-53px {
  padding-top: 53px;
  padding-bottom: 53px;
}

.padding-tb-54px {
  padding-top: 54px;
  padding-bottom: 54px;
}

.padding-tb-55px {
  padding-top: 55px;
  padding-bottom: 55px;
}

.padding-tb-56px {
  padding-top: 56px;
  padding-bottom: 56px;
}

.padding-tb-57px {
  padding-top: 57px;
  padding-bottom: 57px;
}

.padding-tb-58px {
  padding-top: 58px;
  padding-bottom: 58px;
}

.padding-tb-59px {
  padding-top: 59px;
  padding-bottom: 59px;
}

.padding-tb-60px {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding-tb-61px {
  padding-top: 61px;
  padding-bottom: 61px;
}

.padding-tb-62px {
  padding-top: 62px;
  padding-bottom: 62px;
}

.padding-tb-63px {
  padding-top: 63px;
  padding-bottom: 63px;
}

.padding-tb-64px {
  padding-top: 64px;
  padding-bottom: 64px;
}

.padding-tb-65px {
  padding-top: 65px;
  padding-bottom: 65px;
}

.padding-tb-66px {
  padding-top: 66px;
  padding-bottom: 66px;
}

.padding-tb-67px {
  padding-top: 67px;
  padding-bottom: 67px;
}

.padding-tb-68px {
  padding-top: 68px;
  padding-bottom: 68px;
}

.padding-tb-69px {
  padding-top: 69px;
  padding-bottom: 69px;
}

.padding-tb-70px {
  padding-top: 70px;
  padding-bottom: 70px;
}

.padding-tb-71px {
  padding-top: 71px;
  padding-bottom: 71px;
}

.padding-tb-72px {
  padding-top: 72px;
  padding-bottom: 72px;
}

.padding-tb-73px {
  padding-top: 73px;
  padding-bottom: 73px;
}

.padding-tb-74px {
  padding-top: 74px;
  padding-bottom: 74px;
}

.padding-tb-75px {
  padding-top: 75px;
  padding-bottom: 75px;
}

.padding-tb-76px {
  padding-top: 76px;
  padding-bottom: 76px;
}

.padding-tb-77px {
  padding-top: 77px;
  padding-bottom: 77px;
}

.padding-tb-78px {
  padding-top: 78px;
  padding-bottom: 78px;
}

.padding-tb-79px {
  padding-top: 79px;
  padding-bottom: 79px;
}

.padding-tb-80px {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-tb-81px {
  padding-top: 81px;
  padding-bottom: 81px;
}

.padding-tb-82px {
  padding-top: 82px;
  padding-bottom: 82px;
}

.padding-tb-83px {
  padding-top: 83px;
  padding-bottom: 83px;
}

.padding-tb-84px {
  padding-top: 84px;
  padding-bottom: 84px;
}

.padding-tb-85px {
  padding-top: 85px;
  padding-bottom: 85px;
}

.padding-tb-86px {
  padding-top: 86px;
  padding-bottom: 86px;
}

.padding-tb-87px {
  padding-top: 87px;
  padding-bottom: 87px;
}

.padding-tb-88px {
  padding-top: 88px;
  padding-bottom: 88px;
}

.padding-tb-89px {
  padding-top: 89px;
  padding-bottom: 89px;
}

.padding-tb-90px {
  padding-top: 90px;
  padding-bottom: 90px;
}

.padding-tb-91px {
  padding-top: 91px;
  padding-bottom: 91px;
}

.padding-tb-92px {
  padding-top: 92px;
  padding-bottom: 92px;
}

.padding-tb-93px {
  padding-top: 93px;
  padding-bottom: 93px;
}

.padding-tb-94px {
  padding-top: 94px;
  padding-bottom: 94px;
}

.padding-tb-95px {
  padding-top: 95px;
  padding-bottom: 95px;
}

.padding-tb-96px {
  padding-top: 96px;
  padding-bottom: 96px;
}

.padding-tb-97px {
  padding-top: 97px;
  padding-bottom: 97px;
}

.padding-tb-98px {
  padding-top: 98px;
  padding-bottom: 98px;
}

.padding-tb-99px {
  padding-top: 99px;
  padding-bottom: 99px;
}

.padding-tb-100px {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-tb-101px {
  padding-top: 101px;
  padding-bottom: 101px;
}

.padding-tb-102px {
  padding-top: 102px;
  padding-bottom: 102px;
}

.padding-tb-103px {
  padding-top: 103px;
  padding-bottom: 103px;
}

.padding-tb-104px {
  padding-top: 104px;
  padding-bottom: 104px;
}

.padding-tb-105px {
  padding-top: 105px;
  padding-bottom: 105px;
}

.padding-tb-106px {
  padding-top: 106px;
  padding-bottom: 106px;
}

.padding-tb-107px {
  padding-top: 107px;
  padding-bottom: 107px;
}

.padding-tb-108px {
  padding-top: 108px;
  padding-bottom: 108px;
}

.padding-tb-109px {
  padding-top: 109px;
  padding-bottom: 109px;
}

.padding-tb-110px {
  padding-top: 110px;
  padding-bottom: 110px;
}

.padding-tb-111px {
  padding-top: 111px;
  padding-bottom: 111px;
}

.padding-tb-112px {
  padding-top: 112px;
  padding-bottom: 112px;
}

.padding-tb-113px {
  padding-top: 113px;
  padding-bottom: 113px;
}

.padding-tb-114px {
  padding-top: 114px;
  padding-bottom: 114px;
}

.padding-tb-115px {
  padding-top: 115px;
  padding-bottom: 115px;
}

.padding-tb-116px {
  padding-top: 116px;
  padding-bottom: 116px;
}

.padding-tb-117px {
  padding-top: 117px;
  padding-bottom: 117px;
}

.padding-tb-118px {
  padding-top: 118px;
  padding-bottom: 118px;
}

.padding-tb-119px {
  padding-top: 119px;
  padding-bottom: 119px;
}

.padding-tb-120px {
  padding-top: 120px;
  padding-bottom: 120px;
}

.padding-tb-121px {
  padding-top: 121px;
  padding-bottom: 121px;
}

.padding-tb-122px {
  padding-top: 122px;
  padding-bottom: 122px;
}

.padding-tb-123px {
  padding-top: 123px;
  padding-bottom: 123px;
}

.padding-tb-124px {
  padding-top: 124px;
  padding-bottom: 124px;
}

.padding-tb-125px {
  padding-top: 125px;
  padding-bottom: 125px;
}

.padding-tb-126px {
  padding-top: 126px;
  padding-bottom: 126px;
}

.padding-tb-127px {
  padding-top: 127px;
  padding-bottom: 127px;
}

.padding-tb-128px {
  padding-top: 128px;
  padding-bottom: 128px;
}

.padding-tb-129px {
  padding-top: 129px;
  padding-bottom: 129px;
}

.padding-tb-130px {
  padding-top: 130px;
  padding-bottom: 130px;
}

.padding-tb-131px {
  padding-top: 131px;
  padding-bottom: 131px;
}

.padding-tb-132px {
  padding-top: 132px;
  padding-bottom: 132px;
}

.padding-tb-133px {
  padding-top: 133px;
  padding-bottom: 133px;
}

.padding-tb-134px {
  padding-top: 134px;
  padding-bottom: 134px;
}

.padding-tb-135px {
  padding-top: 135px;
  padding-bottom: 135px;
}

.padding-tb-136px {
  padding-top: 136px;
  padding-bottom: 136px;
}

.padding-tb-137px {
  padding-top: 137px;
  padding-bottom: 137px;
}

.padding-tb-138px {
  padding-top: 138px;
  padding-bottom: 138px;
}

.padding-tb-139px {
  padding-top: 139px;
  padding-bottom: 139px;
}

.padding-tb-140px {
  padding-top: 140px;
  padding-bottom: 140px;
}

.padding-tb-141px {
  padding-top: 141px;
  padding-bottom: 141px;
}

.padding-tb-142px {
  padding-top: 142px;
  padding-bottom: 142px;
}

.padding-tb-143px {
  padding-top: 143px;
  padding-bottom: 143px;
}

.padding-tb-144px {
  padding-top: 144px;
  padding-bottom: 144px;
}

.padding-tb-145px {
  padding-top: 145px;
  padding-bottom: 145px;
}

.padding-tb-146px {
  padding-top: 146px;
  padding-bottom: 146px;
}

.padding-tb-147px {
  padding-top: 147px;
  padding-bottom: 147px;
}

.padding-tb-148px {
  padding-top: 148px;
  padding-bottom: 148px;
}

.padding-tb-149px {
  padding-top: 149px;
  padding-bottom: 149px;
}

.padding-tb-150px {
  padding-top: 150px;
  padding-bottom: 150px;
}

.padding-tb-151px {
  padding-top: 151px;
  padding-bottom: 151px;
}

.padding-tb-152px {
  padding-top: 152px;
  padding-bottom: 152px;
}

.padding-tb-153px {
  padding-top: 153px;
  padding-bottom: 153px;
}

.padding-tb-154px {
  padding-top: 154px;
  padding-bottom: 154px;
}

.padding-tb-155px {
  padding-top: 155px;
  padding-bottom: 155px;
}

.padding-tb-156px {
  padding-top: 156px;
  padding-bottom: 156px;
}

.padding-tb-157px {
  padding-top: 157px;
  padding-bottom: 157px;
}

.padding-tb-158px {
  padding-top: 158px;
  padding-bottom: 158px;
}

.padding-tb-159px {
  padding-top: 159px;
  padding-bottom: 159px;
}

.padding-tb-160px {
  padding-top: 160px;
  padding-bottom: 160px;
}

.padding-tb-161px {
  padding-top: 161px;
  padding-bottom: 161px;
}

.padding-tb-162px {
  padding-top: 162px;
  padding-bottom: 162px;
}

.padding-tb-163px {
  padding-top: 163px;
  padding-bottom: 163px;
}

.padding-tb-164px {
  padding-top: 164px;
  padding-bottom: 164px;
}

.padding-tb-165px {
  padding-top: 165px;
  padding-bottom: 165px;
}

.padding-tb-166px {
  padding-top: 166px;
  padding-bottom: 166px;
}

.padding-tb-167px {
  padding-top: 167px;
  padding-bottom: 167px;
}

.padding-tb-168px {
  padding-top: 168px;
  padding-bottom: 168px;
}

.padding-tb-169px {
  padding-top: 169px;
  padding-bottom: 169px;
}

.padding-tb-170px {
  padding-top: 170px;
  padding-bottom: 170px;
}

.padding-tb-171px {
  padding-top: 171px;
  padding-bottom: 171px;
}

.padding-tb-172px {
  padding-top: 172px;
  padding-bottom: 172px;
}

.padding-tb-173px {
  padding-top: 173px;
  padding-bottom: 173px;
}

.padding-tb-174px {
  padding-top: 174px;
  padding-bottom: 174px;
}

.padding-tb-175px {
  padding-top: 175px;
  padding-bottom: 175px;
}

.padding-tb-176px {
  padding-top: 176px;
  padding-bottom: 176px;
}

.padding-tb-177px {
  padding-top: 177px;
  padding-bottom: 177px;
}

.padding-tb-178px {
  padding-top: 178px;
  padding-bottom: 178px;
}

.padding-tb-179px {
  padding-top: 179px;
  padding-bottom: 179px;
}

.padding-tb-180px {
  padding-top: 180px;
  padding-bottom: 180px;
}

.padding-tb-181px {
  padding-top: 181px;
  padding-bottom: 181px;
}

.padding-tb-182px {
  padding-top: 182px;
  padding-bottom: 182px;
}

.padding-tb-183px {
  padding-top: 183px;
  padding-bottom: 183px;
}

.padding-tb-184px {
  padding-top: 184px;
  padding-bottom: 184px;
}

.padding-tb-185px {
  padding-top: 185px;
  padding-bottom: 185px;
}

.padding-tb-186px {
  padding-top: 186px;
  padding-bottom: 186px;
}

.padding-tb-187px {
  padding-top: 187px;
  padding-bottom: 187px;
}

.padding-tb-188px {
  padding-top: 188px;
  padding-bottom: 188px;
}

.padding-tb-189px {
  padding-top: 189px;
  padding-bottom: 189px;
}

.padding-tb-190px {
  padding-top: 190px;
  padding-bottom: 190px;
}

.padding-tb-191px {
  padding-top: 191px;
  padding-bottom: 191px;
}

.padding-tb-192px {
  padding-top: 192px;
  padding-bottom: 192px;
}

.padding-tb-193px {
  padding-top: 193px;
  padding-bottom: 193px;
}

.padding-tb-194px {
  padding-top: 194px;
  padding-bottom: 194px;
}

.padding-tb-195px {
  padding-top: 195px;
  padding-bottom: 195px;
}

.padding-tb-196px {
  padding-top: 196px;
  padding-bottom: 196px;
}

.padding-tb-197px {
  padding-top: 197px;
  padding-bottom: 197px;
}

.padding-tb-198px {
  padding-top: 198px;
  padding-bottom: 198px;
}

.padding-tb-199px {
  padding-top: 199px;
  padding-bottom: 199px;
}

.padding-tb-200px {
  padding-top: 200px;
  padding-bottom: 200px;
}

.padding-tb-201px {
  padding-top: 201px;
  padding-bottom: 201px;
}

.padding-tb-202px {
  padding-top: 202px;
  padding-bottom: 202px;
}

.padding-tb-203px {
  padding-top: 203px;
  padding-bottom: 203px;
}

.padding-tb-204px {
  padding-top: 204px;
  padding-bottom: 204px;
}

.padding-tb-205px {
  padding-top: 205px;
  padding-bottom: 205px;
}

.padding-tb-206px {
  padding-top: 206px;
  padding-bottom: 206px;
}

.padding-tb-207px {
  padding-top: 207px;
  padding-bottom: 207px;
}

.padding-tb-208px {
  padding-top: 208px;
  padding-bottom: 208px;
}

.padding-tb-209px {
  padding-top: 209px;
  padding-bottom: 209px;
}

.padding-tb-210px {
  padding-top: 210px;
  padding-bottom: 210px;
}

.padding-tb-211px {
  padding-top: 211px;
  padding-bottom: 211px;
}

.padding-tb-212px {
  padding-top: 212px;
  padding-bottom: 212px;
}

.padding-tb-213px {
  padding-top: 213px;
  padding-bottom: 213px;
}

.padding-tb-214px {
  padding-top: 214px;
  padding-bottom: 214px;
}

.padding-tb-215px {
  padding-top: 215px;
  padding-bottom: 215px;
}

.padding-tb-216px {
  padding-top: 216px;
  padding-bottom: 216px;
}

.padding-tb-217px {
  padding-top: 217px;
  padding-bottom: 217px;
}

.padding-tb-218px {
  padding-top: 218px;
  padding-bottom: 218px;
}

.padding-tb-219px {
  padding-top: 219px;
  padding-bottom: 219px;
}

.padding-tb-220px {
  padding-top: 220px;
  padding-bottom: 220px;
}

.padding-tb-221px {
  padding-top: 221px;
  padding-bottom: 221px;
}

.padding-tb-222px {
  padding-top: 222px;
  padding-bottom: 222px;
}

.padding-tb-223px {
  padding-top: 223px;
  padding-bottom: 223px;
}

.padding-tb-224px {
  padding-top: 224px;
  padding-bottom: 224px;
}

.padding-tb-225px {
  padding-top: 225px;
  padding-bottom: 225px;
}

.padding-tb-226px {
  padding-top: 226px;
  padding-bottom: 226px;
}

.padding-tb-227px {
  padding-top: 227px;
  padding-bottom: 227px;
}

.padding-tb-228px {
  padding-top: 228px;
  padding-bottom: 228px;
}

.padding-tb-229px {
  padding-top: 229px;
  padding-bottom: 229px;
}

.padding-tb-230px {
  padding-top: 230px;
  padding-bottom: 230px;
}

.padding-tb-231px {
  padding-top: 231px;
  padding-bottom: 231px;
}

.padding-tb-232px {
  padding-top: 232px;
  padding-bottom: 232px;
}

.padding-tb-233px {
  padding-top: 233px;
  padding-bottom: 233px;
}

.padding-tb-234px {
  padding-top: 234px;
  padding-bottom: 234px;
}

.padding-tb-235px {
  padding-top: 235px;
  padding-bottom: 235px;
}

.padding-tb-236px {
  padding-top: 236px;
  padding-bottom: 236px;
}

.padding-tb-237px {
  padding-top: 237px;
  padding-bottom: 237px;
}

.padding-tb-238px {
  padding-top: 238px;
  padding-bottom: 238px;
}

.padding-tb-239px {
  padding-top: 239px;
  padding-bottom: 239px;
}

.padding-tb-240px {
  padding-top: 240px;
  padding-bottom: 240px;
}

.padding-tb-241px {
  padding-top: 241px;
  padding-bottom: 241px;
}

.padding-tb-242px {
  padding-top: 242px;
  padding-bottom: 242px;
}

.padding-tb-243px {
  padding-top: 243px;
  padding-bottom: 243px;
}

.padding-tb-244px {
  padding-top: 244px;
  padding-bottom: 244px;
}

.padding-tb-245px {
  padding-top: 245px;
  padding-bottom: 245px;
}

.padding-tb-246px {
  padding-top: 246px;
  padding-bottom: 246px;
}

.padding-tb-247px {
  padding-top: 247px;
  padding-bottom: 247px;
}

.padding-tb-248px {
  padding-top: 248px;
  padding-bottom: 248px;
}

.padding-tb-249px {
  padding-top: 249px;
  padding-bottom: 249px;
}

.padding-tb-250px {
  padding-top: 250px;
  padding-bottom: 250px;
}

.padding-tb-251px {
  padding-top: 251px;
  padding-bottom: 251px;
}

.padding-tb-252px {
  padding-top: 252px;
  padding-bottom: 252px;
}

.padding-tb-253px {
  padding-top: 253px;
  padding-bottom: 253px;
}

.padding-tb-254px {
  padding-top: 254px;
  padding-bottom: 254px;
}

.padding-tb-255px {
  padding-top: 255px;
  padding-bottom: 255px;
}

.padding-tb-256px {
  padding-top: 256px;
  padding-bottom: 256px;
}

.padding-tb-257px {
  padding-top: 257px;
  padding-bottom: 257px;
}

.padding-tb-258px {
  padding-top: 258px;
  padding-bottom: 258px;
}

.padding-tb-259px {
  padding-top: 259px;
  padding-bottom: 259px;
}

.padding-tb-260px {
  padding-top: 260px;
  padding-bottom: 260px;
}

.padding-tb-261px {
  padding-top: 261px;
  padding-bottom: 261px;
}

.padding-tb-262px {
  padding-top: 262px;
  padding-bottom: 262px;
}

.padding-tb-263px {
  padding-top: 263px;
  padding-bottom: 263px;
}

.padding-tb-264px {
  padding-top: 264px;
  padding-bottom: 264px;
}

.padding-tb-265px {
  padding-top: 265px;
  padding-bottom: 265px;
}

.padding-tb-266px {
  padding-top: 266px;
  padding-bottom: 266px;
}

.padding-tb-267px {
  padding-top: 267px;
  padding-bottom: 267px;
}

.padding-tb-268px {
  padding-top: 268px;
  padding-bottom: 268px;
}

.padding-tb-269px {
  padding-top: 269px;
  padding-bottom: 269px;
}

.padding-tb-270px {
  padding-top: 270px;
  padding-bottom: 270px;
}

.padding-tb-271px {
  padding-top: 271px;
  padding-bottom: 271px;
}

.padding-tb-272px {
  padding-top: 272px;
  padding-bottom: 272px;
}

.padding-tb-273px {
  padding-top: 273px;
  padding-bottom: 273px;
}

.padding-tb-274px {
  padding-top: 274px;
  padding-bottom: 274px;
}

.padding-tb-275px {
  padding-top: 275px;
  padding-bottom: 275px;
}

.padding-tb-276px {
  padding-top: 276px;
  padding-bottom: 276px;
}

.padding-tb-277px {
  padding-top: 277px;
  padding-bottom: 277px;
}

.padding-tb-278px {
  padding-top: 278px;
  padding-bottom: 278px;
}

.padding-tb-279px {
  padding-top: 279px;
  padding-bottom: 279px;
}

.padding-tb-280px {
  padding-top: 280px;
  padding-bottom: 280px;
}

.padding-tb-281px {
  padding-top: 281px;
  padding-bottom: 281px;
}

.padding-tb-282px {
  padding-top: 282px;
  padding-bottom: 282px;
}

.padding-tb-283px {
  padding-top: 283px;
  padding-bottom: 283px;
}

.padding-tb-284px {
  padding-top: 284px;
  padding-bottom: 284px;
}

.padding-tb-285px {
  padding-top: 285px;
  padding-bottom: 285px;
}

.padding-tb-286px {
  padding-top: 286px;
  padding-bottom: 286px;
}

.padding-tb-287px {
  padding-top: 287px;
  padding-bottom: 287px;
}

.padding-tb-288px {
  padding-top: 288px;
  padding-bottom: 288px;
}

.padding-tb-289px {
  padding-top: 289px;
  padding-bottom: 289px;
}

.padding-tb-290px {
  padding-top: 290px;
  padding-bottom: 290px;
}

.padding-tb-291px {
  padding-top: 291px;
  padding-bottom: 291px;
}

.padding-tb-292px {
  padding-top: 292px;
  padding-bottom: 292px;
}

.padding-tb-293px {
  padding-top: 293px;
  padding-bottom: 293px;
}

.padding-tb-294px {
  padding-top: 294px;
  padding-bottom: 294px;
}

.padding-tb-295px {
  padding-top: 295px;
  padding-bottom: 295px;
}

.padding-tb-296px {
  padding-top: 296px;
  padding-bottom: 296px;
}

.padding-tb-297px {
  padding-top: 297px;
  padding-bottom: 297px;
}

.padding-tb-298px {
  padding-top: 298px;
  padding-bottom: 298px;
}

.padding-tb-299px {
  padding-top: 299px;
  padding-bottom: 299px;
}

.padding-tb-300px {
  padding-top: 300px;
  padding-bottom: 300px;
}

/*--- Padding top ---*/

.padding-top-0px {
  padding-top: 0px;
}

.padding-top-1px {
  padding-top: 1px;
}

.padding-top-2px {
  padding-top: 2px;
}

.padding-top-3px {
  padding-top: 3px;
}

.padding-top-4px {
  padding-top: 4px;
}

.padding-top-5px {
  padding-top: 5px;
}

.padding-top-6px {
  padding-top: 6px;
}

.padding-top-7px {
  padding-top: 7px;
}

.padding-top-8px {
  padding-top: 8px;
}

.padding-top-9px {
  padding-top: 9px;
}

.padding-top-10px {
  padding-top: 10px;
}

.padding-top-11px {
  padding-top: 11px;
}

.padding-top-12px {
  padding-top: 12px;
}

.padding-top-13px {
  padding-top: 13px;
}

.padding-top-14px {
  padding-top: 14px;
}

.padding-top-15px {
  padding-top: 15px;
}

.padding-top-16px {
  padding-top: 16px;
}

.padding-top-17px {
  padding-top: 17px;
}

.padding-top-18px {
  padding-top: 18px;
}

.padding-top-19px {
  padding-top: 19px;
}

.padding-top-20px {
  padding-top: 20px;
}

.padding-top-21px {
  padding-top: 21px;
}

.padding-top-22px {
  padding-top: 22px;
}

.padding-top-23px {
  padding-top: 23px;
}

.padding-top-24px {
  padding-top: 24px;
}

.padding-top-25px {
  padding-top: 25px;
}

.padding-top-26px {
  padding-top: 26px;
}

.padding-top-27px {
  padding-top: 27px;
}

.padding-top-28px {
  padding-top: 28px;
}

.padding-top-29px {
  padding-top: 29px;
}

.padding-top-30px {
  padding-top: 30px;
}

.padding-top-31px {
  padding-top: 31px;
}

.padding-top-32px {
  padding-top: 32px;
}

.padding-top-33px {
  padding-top: 33px;
}

.padding-top-34px {
  padding-top: 34px;
}

.padding-top-35px {
  padding-top: 35px;
}

.padding-top-36px {
  padding-top: 36px;
}

.padding-top-37px {
  padding-top: 37px;
}

.padding-top-38px {
  padding-top: 38px;
}

.padding-top-39px {
  padding-top: 39px;
}

.padding-top-40px {
  padding-top: 40px;
}

.padding-top-41px {
  padding-top: 41px;
}

.padding-top-42px {
  padding-top: 42px;
}

.padding-top-43px {
  padding-top: 43px;
}

.padding-top-44px {
  padding-top: 44px;
}

.padding-top-45px {
  padding-top: 45px;
}

.padding-top-46px {
  padding-top: 46px;
}

.padding-top-47px {
  padding-top: 47px;
}

.padding-top-48px {
  padding-top: 48px;
}

.padding-top-49px {
  padding-top: 49px;
}

.padding-top-50px {
  padding-top: 50px;
}

.padding-top-51px {
  padding-top: 51px;
}

.padding-top-52px {
  padding-top: 52px;
}

.padding-top-53px {
  padding-top: 53px;
}

.padding-top-54px {
  padding-top: 54px;
}

.padding-top-55px {
  padding-top: 55px;
}

.padding-top-56px {
  padding-top: 56px;
}

.padding-top-57px {
  padding-top: 57px;
}

.padding-top-58px {
  padding-top: 58px;
}

.padding-top-59px {
  padding-top: 59px;
}

.padding-top-60px {
  padding-top: 60px;
}

.padding-top-61px {
  padding-top: 61px;
}

.padding-top-62px {
  padding-top: 62px;
}

.padding-top-63px {
  padding-top: 63px;
}

.padding-top-64px {
  padding-top: 64px;
}

.padding-top-65px {
  padding-top: 65px;
}

.padding-top-66px {
  padding-top: 66px;
}

.padding-top-67px {
  padding-top: 67px;
}

.padding-top-68px {
  padding-top: 68px;
}

.padding-top-69px {
  padding-top: 69px;
}

.padding-top-70px {
  padding-top: 70px;
}

.padding-top-71px {
  padding-top: 71px;
}

.padding-top-72px {
  padding-top: 72px;
}

.padding-top-73px {
  padding-top: 73px;
}

.padding-top-74px {
  padding-top: 74px;
}

.padding-top-75px {
  padding-top: 75px;
}

.padding-top-76px {
  padding-top: 76px;
}

.padding-top-77px {
  padding-top: 77px;
}

.padding-top-78px {
  padding-top: 78px;
}

.padding-top-79px {
  padding-top: 79px;
}

.padding-top-80px {
  padding-top: 80px;
}

.padding-top-81px {
  padding-top: 81px;
}

.padding-top-82px {
  padding-top: 82px;
}

.padding-top-83px {
  padding-top: 83px;
}

.padding-top-84px {
  padding-top: 84px;
}

.padding-top-85px {
  padding-top: 85px;
}

.padding-top-86px {
  padding-top: 86px;
}

.padding-top-87px {
  padding-top: 87px;
}

.padding-top-88px {
  padding-top: 88px;
}

.padding-top-89px {
  padding-top: 89px;
}

.padding-top-90px {
  padding-top: 90px;
}

.padding-top-91px {
  padding-top: 91px;
}

.padding-top-92px {
  padding-top: 92px;
}

.padding-top-93px {
  padding-top: 93px;
}

.padding-top-94px {
  padding-top: 94px;
}

.padding-top-95px {
  padding-top: 95px;
}

.padding-top-96px {
  padding-top: 96px;
}

.padding-top-97px {
  padding-top: 97px;
}

.padding-top-98px {
  padding-top: 98px;
}

.padding-top-99px {
  padding-top: 99px;
}

.padding-top-100px {
  padding-top: 100px;
}

.padding-top-101px {
  padding-top: 101px;
}

.padding-top-102px {
  padding-top: 102px;
}

.padding-top-103px {
  padding-top: 103px;
}

.padding-top-104px {
  padding-top: 104px;
}

.padding-top-105px {
  padding-top: 105px;
}

.padding-top-106px {
  padding-top: 106px;
}

.padding-top-107px {
  padding-top: 107px;
}

.padding-top-108px {
  padding-top: 108px;
}

.padding-top-109px {
  padding-top: 109px;
}

.padding-top-110px {
  padding-top: 110px;
}

.padding-top-111px {
  padding-top: 111px;
}

.padding-top-112px {
  padding-top: 112px;
}

.padding-top-113px {
  padding-top: 113px;
}

.padding-top-114px {
  padding-top: 114px;
}

.padding-top-115px {
  padding-top: 115px;
}

.padding-top-116px {
  padding-top: 116px;
}

.padding-top-117px {
  padding-top: 117px;
}

.padding-top-118px {
  padding-top: 118px;
}

.padding-top-119px {
  padding-top: 119px;
}

.padding-top-120px {
  padding-top: 120px;
}

.padding-top-121px {
  padding-top: 121px;
}

.padding-top-122px {
  padding-top: 122px;
}

.padding-top-123px {
  padding-top: 123px;
}

.padding-top-124px {
  padding-top: 124px;
}

.padding-top-125px {
  padding-top: 125px;
}

.padding-top-126px {
  padding-top: 126px;
}

.padding-top-127px {
  padding-top: 127px;
}

.padding-top-128px {
  padding-top: 128px;
}

.padding-top-129px {
  padding-top: 129px;
}

.padding-top-130px {
  padding-top: 130px;
}

.padding-top-131px {
  padding-top: 131px;
}

.padding-top-132px {
  padding-top: 132px;
}

.padding-top-133px {
  padding-top: 133px;
}

.padding-top-134px {
  padding-top: 134px;
}

.padding-top-135px {
  padding-top: 135px;
}

.padding-top-136px {
  padding-top: 136px;
}

.padding-top-137px {
  padding-top: 137px;
}

.padding-top-138px {
  padding-top: 138px;
}

.padding-top-139px {
  padding-top: 139px;
}

.padding-top-140px {
  padding-top: 140px;
}

.padding-top-141px {
  padding-top: 141px;
}

.padding-top-142px {
  padding-top: 142px;
}

.padding-top-143px {
  padding-top: 143px;
}

.padding-top-144px {
  padding-top: 144px;
}

.padding-top-145px {
  padding-top: 145px;
}

.padding-top-146px {
  padding-top: 146px;
}

.padding-top-147px {
  padding-top: 147px;
}

.padding-top-148px {
  padding-top: 148px;
}

.padding-top-149px {
  padding-top: 149px;
}

.padding-top-150px {
  padding-top: 150px;
}

.padding-top-151px {
  padding-top: 151px;
}

.padding-top-152px {
  padding-top: 152px;
}

.padding-top-153px {
  padding-top: 153px;
}

.padding-top-154px {
  padding-top: 154px;
}

.padding-top-155px {
  padding-top: 155px;
}

.padding-top-156px {
  padding-top: 156px;
}

.padding-top-157px {
  padding-top: 157px;
}

.padding-top-158px {
  padding-top: 158px;
}

.padding-top-159px {
  padding-top: 159px;
}

.padding-top-160px {
  padding-top: 160px;
}

.padding-top-161px {
  padding-top: 161px;
}

.padding-top-162px {
  padding-top: 162px;
}

.padding-top-163px {
  padding-top: 163px;
}

.padding-top-164px {
  padding-top: 164px;
}

.padding-top-165px {
  padding-top: 165px;
}

.padding-top-166px {
  padding-top: 166px;
}

.padding-top-167px {
  padding-top: 167px;
}

.padding-top-168px {
  padding-top: 168px;
}

.padding-top-169px {
  padding-top: 169px;
}

.padding-top-170px {
  padding-top: 170px;
}

.padding-top-171px {
  padding-top: 171px;
}

.padding-top-172px {
  padding-top: 172px;
}

.padding-top-173px {
  padding-top: 173px;
}

.padding-top-174px {
  padding-top: 174px;
}

.padding-top-175px {
  padding-top: 175px;
}

.padding-top-176px {
  padding-top: 176px;
}

.padding-top-177px {
  padding-top: 177px;
}

.padding-top-178px {
  padding-top: 178px;
}

.padding-top-179px {
  padding-top: 179px;
}

.padding-top-180px {
  padding-top: 180px;
}

.padding-top-181px {
  padding-top: 181px;
}

.padding-top-182px {
  padding-top: 182px;
}

.padding-top-183px {
  padding-top: 183px;
}

.padding-top-184px {
  padding-top: 184px;
}

.padding-top-185px {
  padding-top: 185px;
}

.padding-top-186px {
  padding-top: 186px;
}

.padding-top-187px {
  padding-top: 187px;
}

.padding-top-188px {
  padding-top: 188px;
}

.padding-top-189px {
  padding-top: 189px;
}

.padding-top-190px {
  padding-top: 190px;
}

.padding-top-191px {
  padding-top: 191px;
}

.padding-top-192px {
  padding-top: 192px;
}

.padding-top-193px {
  padding-top: 193px;
}

.padding-top-194px {
  padding-top: 194px;
}

.padding-top-195px {
  padding-top: 195px;
}

.padding-top-196px {
  padding-top: 196px;
}

.padding-top-197px {
  padding-top: 197px;
}

.padding-top-198px {
  padding-top: 198px;
}

.padding-top-199px {
  padding-top: 199px;
}

.padding-top-200px {
  padding-top: 200px;
}

.padding-top-201px {
  padding-top: 201px;
}

.padding-top-202px {
  padding-top: 202px;
}

.padding-top-203px {
  padding-top: 203px;
}

.padding-top-204px {
  padding-top: 204px;
}

.padding-top-205px {
  padding-top: 205px;
}

.padding-top-206px {
  padding-top: 206px;
}

.padding-top-207px {
  padding-top: 207px;
}

.padding-top-208px {
  padding-top: 208px;
}

.padding-top-209px {
  padding-top: 209px;
}

.padding-top-210px {
  padding-top: 210px;
}

.padding-top-211px {
  padding-top: 211px;
}

.padding-top-212px {
  padding-top: 212px;
}

.padding-top-213px {
  padding-top: 213px;
}

.padding-top-214px {
  padding-top: 214px;
}

.padding-top-215px {
  padding-top: 215px;
}

.padding-top-216px {
  padding-top: 216px;
}

.padding-top-217px {
  padding-top: 217px;
}

.padding-top-218px {
  padding-top: 218px;
}

.padding-top-219px {
  padding-top: 219px;
}

.padding-top-220px {
  padding-top: 220px;
}

.padding-top-221px {
  padding-top: 221px;
}

.padding-top-222px {
  padding-top: 222px;
}

.padding-top-223px {
  padding-top: 223px;
}

.padding-top-224px {
  padding-top: 224px;
}

.padding-top-225px {
  padding-top: 225px;
}

.padding-top-226px {
  padding-top: 226px;
}

.padding-top-227px {
  padding-top: 227px;
}

.padding-top-228px {
  padding-top: 228px;
}

.padding-top-229px {
  padding-top: 229px;
}

.padding-top-230px {
  padding-top: 230px;
}

.padding-top-231px {
  padding-top: 231px;
}

.padding-top-232px {
  padding-top: 232px;
}

.padding-top-233px {
  padding-top: 233px;
}

.padding-top-234px {
  padding-top: 234px;
}

.padding-top-235px {
  padding-top: 235px;
}

.padding-top-236px {
  padding-top: 236px;
}

.padding-top-237px {
  padding-top: 237px;
}

.padding-top-238px {
  padding-top: 238px;
}

.padding-top-239px {
  padding-top: 239px;
}

.padding-top-240px {
  padding-top: 240px;
}

.padding-top-241px {
  padding-top: 241px;
}

.padding-top-242px {
  padding-top: 242px;
}

.padding-top-243px {
  padding-top: 243px;
}

.padding-top-244px {
  padding-top: 244px;
}

.padding-top-245px {
  padding-top: 245px;
}

.padding-top-246px {
  padding-top: 246px;
}

.padding-top-247px {
  padding-top: 247px;
}

.padding-top-248px {
  padding-top: 248px;
}

.padding-top-249px {
  padding-top: 249px;
}

.padding-top-250px {
  padding-top: 250px;
}

.padding-top-251px {
  padding-top: 251px;
}

.padding-top-252px {
  padding-top: 252px;
}

.padding-top-253px {
  padding-top: 253px;
}

.padding-top-254px {
  padding-top: 254px;
}

.padding-top-255px {
  padding-top: 255px;
}

.padding-top-256px {
  padding-top: 256px;
}

.padding-top-257px {
  padding-top: 257px;
}

.padding-top-258px {
  padding-top: 258px;
}

.padding-top-259px {
  padding-top: 259px;
}

.padding-top-260px {
  padding-top: 260px;
}

.padding-top-261px {
  padding-top: 261px;
}

.padding-top-262px {
  padding-top: 262px;
}

.padding-top-263px {
  padding-top: 263px;
}

.padding-top-264px {
  padding-top: 264px;
}

.padding-top-265px {
  padding-top: 265px;
}

.padding-top-266px {
  padding-top: 266px;
}

.padding-top-267px {
  padding-top: 267px;
}

.padding-top-268px {
  padding-top: 268px;
}

.padding-top-269px {
  padding-top: 269px;
}

.padding-top-270px {
  padding-top: 270px;
}

.padding-top-271px {
  padding-top: 271px;
}

.padding-top-272px {
  padding-top: 272px;
}

.padding-top-273px {
  padding-top: 273px;
}

.padding-top-274px {
  padding-top: 274px;
}

.padding-top-275px {
  padding-top: 275px;
}

.padding-top-276px {
  padding-top: 276px;
}

.padding-top-277px {
  padding-top: 277px;
}

.padding-top-278px {
  padding-top: 278px;
}

.padding-top-279px {
  padding-top: 279px;
}

.padding-top-280px {
  padding-top: 280px;
}

.padding-top-281px {
  padding-top: 281px;
}

.padding-top-282px {
  padding-top: 282px;
}

.padding-top-283px {
  padding-top: 283px;
}

.padding-top-284px {
  padding-top: 284px;
}

.padding-top-285px {
  padding-top: 285px;
}

.padding-top-286px {
  padding-top: 286px;
}

.padding-top-287px {
  padding-top: 287px;
}

.padding-top-288px {
  padding-top: 288px;
}

.padding-top-289px {
  padding-top: 289px;
}

.padding-top-290px {
  padding-top: 290px;
}

.padding-top-291px {
  padding-top: 291px;
}

.padding-top-292px {
  padding-top: 292px;
}

.padding-top-293px {
  padding-top: 293px;
}

.padding-top-294px {
  padding-top: 294px;
}

.padding-top-295px {
  padding-top: 295px;
}

.padding-top-296px {
  padding-top: 296px;
}

.padding-top-297px {
  padding-top: 297px;
}

.padding-top-298px {
  padding-top: 298px;
}

.padding-top-299px {
  padding-top: 299px;
}

.padding-top-300px {
  padding-top: 300px;
}

/*--- Padding bottom ---*/

.padding-bottom-0px {
  padding-bottom: 0px;
}

.padding-bottom-1px {
  padding-bottom: 1px;
}

.padding-bottom-2px {
  padding-bottom: 2px;
}

.padding-bottom-3px {
  padding-bottom: 3px;
}

.padding-bottom-4px {
  padding-bottom: 4px;
}

.padding-bottom-5px {
  padding-bottom: 5px;
}

.padding-bottom-6px {
  padding-bottom: 6px;
}

.padding-bottom-7px {
  padding-bottom: 7px;
}

.padding-bottom-8px {
  padding-bottom: 8px;
}

.padding-bottom-9px {
  padding-bottom: 9px;
}

.padding-bottom-10px {
  padding-bottom: 10px;
}

.padding-bottom-11px {
  padding-bottom: 11px;
}

.padding-bottom-12px {
  padding-bottom: 12px;
}

.padding-bottom-13px {
  padding-bottom: 13px;
}

.padding-bottom-14px {
  padding-bottom: 14px;
}

.padding-bottom-15px {
  padding-bottom: 15px;
}

.padding-bottom-16px {
  padding-bottom: 16px;
}

.padding-bottom-17px {
  padding-bottom: 17px;
}

.padding-bottom-18px {
  padding-bottom: 18px;
}

.padding-bottom-19px {
  padding-bottom: 19px;
}

.padding-bottom-20px {
  padding-bottom: 20px;
}

.padding-bottom-21px {
  padding-bottom: 21px;
}

.padding-bottom-22px {
  padding-bottom: 22px;
}

.padding-bottom-23px {
  padding-bottom: 23px;
}

.padding-bottom-24px {
  padding-bottom: 24px;
}

.padding-bottom-25px {
  padding-bottom: 25px;
}

.padding-bottom-26px {
  padding-bottom: 26px;
}

.padding-bottom-27px {
  padding-bottom: 27px;
}

.padding-bottom-28px {
  padding-bottom: 28px;
}

.padding-bottom-29px {
  padding-bottom: 29px;
}

.padding-bottom-30px {
  padding-bottom: 30px;
}

.padding-bottom-31px {
  padding-bottom: 31px;
}

.padding-bottom-32px {
  padding-bottom: 32px;
}

.padding-bottom-33px {
  padding-bottom: 33px;
}

.padding-bottom-34px {
  padding-bottom: 34px;
}

.padding-bottom-35px {
  padding-bottom: 35px;
}

.padding-bottom-36px {
  padding-bottom: 36px;
}

.padding-bottom-37px {
  padding-bottom: 37px;
}

.padding-bottom-38px {
  padding-bottom: 38px;
}

.padding-bottom-39px {
  padding-bottom: 39px;
}

.padding-bottom-40px {
  padding-bottom: 40px;
}

.padding-bottom-41px {
  padding-bottom: 41px;
}

.padding-bottom-42px {
  padding-bottom: 42px;
}

.padding-bottom-43px {
  padding-bottom: 43px;
}

.padding-bottom-44px {
  padding-bottom: 44px;
}

.padding-bottom-45px {
  padding-bottom: 45px;
}

.padding-bottom-46px {
  padding-bottom: 46px;
}

.padding-bottom-47px {
  padding-bottom: 47px;
}

.padding-bottom-48px {
  padding-bottom: 48px;
}

.padding-bottom-49px {
  padding-bottom: 49px;
}

.padding-bottom-50px {
  padding-bottom: 50px;
}

.padding-bottom-51px {
  padding-bottom: 51px;
}

.padding-bottom-52px {
  padding-bottom: 52px;
}

.padding-bottom-53px {
  padding-bottom: 53px;
}

.padding-bottom-54px {
  padding-bottom: 54px;
}

.padding-bottom-55px {
  padding-bottom: 55px;
}

.padding-bottom-56px {
  padding-bottom: 56px;
}

.padding-bottom-57px {
  padding-bottom: 57px;
}

.padding-bottom-58px {
  padding-bottom: 58px;
}

.padding-bottom-59px {
  padding-bottom: 59px;
}

.padding-bottom-60px {
  padding-bottom: 60px;
}

.padding-bottom-61px {
  padding-bottom: 61px;
}

.padding-bottom-62px {
  padding-bottom: 62px;
}

.padding-bottom-63px {
  padding-bottom: 63px;
}

.padding-bottom-64px {
  padding-bottom: 64px;
}

.padding-bottom-65px {
  padding-bottom: 65px;
}

.padding-bottom-66px {
  padding-bottom: 66px;
}

.padding-bottom-67px {
  padding-bottom: 67px;
}

.padding-bottom-68px {
  padding-bottom: 68px;
}

.padding-bottom-69px {
  padding-bottom: 69px;
}

.padding-bottom-70px {
  padding-bottom: 70px;
}

.padding-bottom-71px {
  padding-bottom: 71px;
}

.padding-bottom-72px {
  padding-bottom: 72px;
}

.padding-bottom-73px {
  padding-bottom: 73px;
}

.padding-bottom-74px {
  padding-bottom: 74px;
}

.padding-bottom-75px {
  padding-bottom: 75px;
}

.padding-bottom-76px {
  padding-bottom: 76px;
}

.padding-bottom-77px {
  padding-bottom: 77px;
}

.padding-bottom-78px {
  padding-bottom: 78px;
}

.padding-bottom-79px {
  padding-bottom: 79px;
}

.padding-bottom-80px {
  padding-bottom: 80px;
}

.padding-bottom-81px {
  padding-bottom: 81px;
}

.padding-bottom-82px {
  padding-bottom: 82px;
}

.padding-bottom-83px {
  padding-bottom: 83px;
}

.padding-bottom-84px {
  padding-bottom: 84px;
}

.padding-bottom-85px {
  padding-bottom: 85px;
}

.padding-bottom-86px {
  padding-bottom: 86px;
}

.padding-bottom-87px {
  padding-bottom: 87px;
}

.padding-bottom-88px {
  padding-bottom: 88px;
}

.padding-bottom-89px {
  padding-bottom: 89px;
}

.padding-bottom-90px {
  padding-bottom: 90px;
}

.padding-bottom-91px {
  padding-bottom: 91px;
}

.padding-bottom-92px {
  padding-bottom: 92px;
}

.padding-bottom-93px {
  padding-bottom: 93px;
}

.padding-bottom-94px {
  padding-bottom: 94px;
}

.padding-bottom-95px {
  padding-bottom: 95px;
}

.padding-bottom-96px {
  padding-bottom: 96px;
}

.padding-bottom-97px {
  padding-bottom: 97px;
}

.padding-bottom-98px {
  padding-bottom: 98px;
}

.padding-bottom-99px {
  padding-bottom: 99px;
}

.padding-bottom-100px {
  padding-bottom: 100px;
}

.padding-bottom-101px {
  padding-bottom: 101px;
}

.padding-bottom-102px {
  padding-bottom: 102px;
}

.padding-bottom-103px {
  padding-bottom: 103px;
}

.padding-bottom-104px {
  padding-bottom: 104px;
}

.padding-bottom-105px {
  padding-bottom: 105px;
}

.padding-bottom-106px {
  padding-bottom: 106px;
}

.padding-bottom-107px {
  padding-bottom: 107px;
}

.padding-bottom-108px {
  padding-bottom: 108px;
}

.padding-bottom-109px {
  padding-bottom: 109px;
}

.padding-bottom-110px {
  padding-bottom: 110px;
}

.padding-bottom-111px {
  padding-bottom: 111px;
}

.padding-bottom-112px {
  padding-bottom: 112px;
}

.padding-bottom-113px {
  padding-bottom: 113px;
}

.padding-bottom-114px {
  padding-bottom: 114px;
}

.padding-bottom-115px {
  padding-bottom: 115px;
}

.padding-bottom-116px {
  padding-bottom: 116px;
}

.padding-bottom-117px {
  padding-bottom: 117px;
}

.padding-bottom-118px {
  padding-bottom: 118px;
}

.padding-bottom-119px {
  padding-bottom: 119px;
}

.padding-bottom-120px {
  padding-bottom: 120px;
}

.padding-bottom-121px {
  padding-bottom: 121px;
}

.padding-bottom-122px {
  padding-bottom: 122px;
}

.padding-bottom-123px {
  padding-bottom: 123px;
}

.padding-bottom-124px {
  padding-bottom: 124px;
}

.padding-bottom-125px {
  padding-bottom: 125px;
}

.padding-bottom-126px {
  padding-bottom: 126px;
}

.padding-bottom-127px {
  padding-bottom: 127px;
}

.padding-bottom-128px {
  padding-bottom: 128px;
}

.padding-bottom-129px {
  padding-bottom: 129px;
}

.padding-bottom-130px {
  padding-bottom: 130px;
}

.padding-bottom-131px {
  padding-bottom: 131px;
}

.padding-bottom-132px {
  padding-bottom: 132px;
}

.padding-bottom-133px {
  padding-bottom: 133px;
}

.padding-bottom-134px {
  padding-bottom: 134px;
}

.padding-bottom-135px {
  padding-bottom: 135px;
}

.padding-bottom-136px {
  padding-bottom: 136px;
}

.padding-bottom-137px {
  padding-bottom: 137px;
}

.padding-bottom-138px {
  padding-bottom: 138px;
}

.padding-bottom-139px {
  padding-bottom: 139px;
}

.padding-bottom-140px {
  padding-bottom: 140px;
}

.padding-bottom-141px {
  padding-bottom: 141px;
}

.padding-bottom-142px {
  padding-bottom: 142px;
}

.padding-bottom-143px {
  padding-bottom: 143px;
}

.padding-bottom-144px {
  padding-bottom: 144px;
}

.padding-bottom-145px {
  padding-bottom: 145px;
}

.padding-bottom-146px {
  padding-bottom: 146px;
}

.padding-bottom-147px {
  padding-bottom: 147px;
}

.padding-bottom-148px {
  padding-bottom: 148px;
}

.padding-bottom-149px {
  padding-bottom: 149px;
}

.padding-bottom-150px {
  padding-bottom: 150px;
}

.padding-bottom-151px {
  padding-bottom: 151px;
}

.padding-bottom-152px {
  padding-bottom: 152px;
}

.padding-bottom-153px {
  padding-bottom: 153px;
}

.padding-bottom-154px {
  padding-bottom: 154px;
}

.padding-bottom-155px {
  padding-bottom: 155px;
}

.padding-bottom-156px {
  padding-bottom: 156px;
}

.padding-bottom-157px {
  padding-bottom: 157px;
}

.padding-bottom-158px {
  padding-bottom: 158px;
}

.padding-bottom-159px {
  padding-bottom: 159px;
}

.padding-bottom-160px {
  padding-bottom: 160px;
}

.padding-bottom-161px {
  padding-bottom: 161px;
}

.padding-bottom-162px {
  padding-bottom: 162px;
}

.padding-bottom-163px {
  padding-bottom: 163px;
}

.padding-bottom-164px {
  padding-bottom: 164px;
}

.padding-bottom-165px {
  padding-bottom: 165px;
}

.padding-bottom-166px {
  padding-bottom: 166px;
}

.padding-bottom-167px {
  padding-bottom: 167px;
}

.padding-bottom-168px {
  padding-bottom: 168px;
}

.padding-bottom-169px {
  padding-bottom: 169px;
}

.padding-bottom-170px {
  padding-bottom: 170px;
}

.padding-bottom-171px {
  padding-bottom: 171px;
}

.padding-bottom-172px {
  padding-bottom: 172px;
}

.padding-bottom-173px {
  padding-bottom: 173px;
}

.padding-bottom-174px {
  padding-bottom: 174px;
}

.padding-bottom-175px {
  padding-bottom: 175px;
}

.padding-bottom-176px {
  padding-bottom: 176px;
}

.padding-bottom-177px {
  padding-bottom: 177px;
}

.padding-bottom-178px {
  padding-bottom: 178px;
}

.padding-bottom-179px {
  padding-bottom: 179px;
}

.padding-bottom-180px {
  padding-bottom: 180px;
}

.padding-bottom-181px {
  padding-bottom: 181px;
}

.padding-bottom-182px {
  padding-bottom: 182px;
}

.padding-bottom-183px {
  padding-bottom: 183px;
}

.padding-bottom-184px {
  padding-bottom: 184px;
}

.padding-bottom-185px {
  padding-bottom: 185px;
}

.padding-bottom-186px {
  padding-bottom: 186px;
}

.padding-bottom-187px {
  padding-bottom: 187px;
}

.padding-bottom-188px {
  padding-bottom: 188px;
}

.padding-bottom-189px {
  padding-bottom: 189px;
}

.padding-bottom-190px {
  padding-bottom: 190px;
}

.padding-bottom-191px {
  padding-bottom: 191px;
}

.padding-bottom-192px {
  padding-bottom: 192px;
}

.padding-bottom-193px {
  padding-bottom: 193px;
}

.padding-bottom-194px {
  padding-bottom: 194px;
}

.padding-bottom-195px {
  padding-bottom: 195px;
}

.padding-bottom-196px {
  padding-bottom: 196px;
}

.padding-bottom-197px {
  padding-bottom: 197px;
}

.padding-bottom-198px {
  padding-bottom: 198px;
}

.padding-bottom-199px {
  padding-bottom: 199px;
}

.padding-bottom-200px {
  padding-bottom: 200px;
}

.padding-bottom-201px {
  padding-bottom: 201px;
}

.padding-bottom-202px {
  padding-bottom: 202px;
}

.padding-bottom-203px {
  padding-bottom: 203px;
}

.padding-bottom-204px {
  padding-bottom: 204px;
}

.padding-bottom-205px {
  padding-bottom: 205px;
}

.padding-bottom-206px {
  padding-bottom: 206px;
}

.padding-bottom-207px {
  padding-bottom: 207px;
}

.padding-bottom-208px {
  padding-bottom: 208px;
}

.padding-bottom-209px {
  padding-bottom: 209px;
}

.padding-bottom-210px {
  padding-bottom: 210px;
}

.padding-bottom-211px {
  padding-bottom: 211px;
}

.padding-bottom-212px {
  padding-bottom: 212px;
}

.padding-bottom-213px {
  padding-bottom: 213px;
}

.padding-bottom-214px {
  padding-bottom: 214px;
}

.padding-bottom-215px {
  padding-bottom: 215px;
}

.padding-bottom-216px {
  padding-bottom: 216px;
}

.padding-bottom-217px {
  padding-bottom: 217px;
}

.padding-bottom-218px {
  padding-bottom: 218px;
}

.padding-bottom-219px {
  padding-bottom: 219px;
}

.padding-bottom-220px {
  padding-bottom: 220px;
}

.padding-bottom-221px {
  padding-bottom: 221px;
}

.padding-bottom-222px {
  padding-bottom: 222px;
}

.padding-bottom-223px {
  padding-bottom: 223px;
}

.padding-bottom-224px {
  padding-bottom: 224px;
}

.padding-bottom-225px {
  padding-bottom: 225px;
}

.padding-bottom-226px {
  padding-bottom: 226px;
}

.padding-bottom-227px {
  padding-bottom: 227px;
}

.padding-bottom-228px {
  padding-bottom: 228px;
}

.padding-bottom-229px {
  padding-bottom: 229px;
}

.padding-bottom-230px {
  padding-bottom: 230px;
}

.padding-bottom-231px {
  padding-bottom: 231px;
}

.padding-bottom-232px {
  padding-bottom: 232px;
}

.padding-bottom-233px {
  padding-bottom: 233px;
}

.padding-bottom-234px {
  padding-bottom: 234px;
}

.padding-bottom-235px {
  padding-bottom: 235px;
}

.padding-bottom-236px {
  padding-bottom: 236px;
}

.padding-bottom-237px {
  padding-bottom: 237px;
}

.padding-bottom-238px {
  padding-bottom: 238px;
}

.padding-bottom-239px {
  padding-bottom: 239px;
}

.padding-bottom-240px {
  padding-bottom: 240px;
}

.padding-bottom-241px {
  padding-bottom: 241px;
}

.padding-bottom-242px {
  padding-bottom: 242px;
}

.padding-bottom-243px {
  padding-bottom: 243px;
}

.padding-bottom-244px {
  padding-bottom: 244px;
}

.padding-bottom-245px {
  padding-bottom: 245px;
}

.padding-bottom-246px {
  padding-bottom: 246px;
}

.padding-bottom-247px {
  padding-bottom: 247px;
}

.padding-bottom-248px {
  padding-bottom: 248px;
}

.padding-bottom-249px {
  padding-bottom: 249px;
}

.padding-bottom-250px {
  padding-bottom: 250px;
}

.padding-bottom-251px {
  padding-bottom: 251px;
}

.padding-bottom-252px {
  padding-bottom: 252px;
}

.padding-bottom-253px {
  padding-bottom: 253px;
}

.padding-bottom-254px {
  padding-bottom: 254px;
}

.padding-bottom-255px {
  padding-bottom: 255px;
}

.padding-bottom-256px {
  padding-bottom: 256px;
}

.padding-bottom-257px {
  padding-bottom: 257px;
}

.padding-bottom-258px {
  padding-bottom: 258px;
}

.padding-bottom-259px {
  padding-bottom: 259px;
}

.padding-bottom-260px {
  padding-bottom: 260px;
}

.padding-bottom-261px {
  padding-bottom: 261px;
}

.padding-bottom-262px {
  padding-bottom: 262px;
}

.padding-bottom-263px {
  padding-bottom: 263px;
}

.padding-bottom-264px {
  padding-bottom: 264px;
}

.padding-bottom-265px {
  padding-bottom: 265px;
}

.padding-bottom-266px {
  padding-bottom: 266px;
}

.padding-bottom-267px {
  padding-bottom: 267px;
}

.padding-bottom-268px {
  padding-bottom: 268px;
}

.padding-bottom-269px {
  padding-bottom: 269px;
}

.padding-bottom-270px {
  padding-bottom: 270px;
}

.padding-bottom-271px {
  padding-bottom: 271px;
}

.padding-bottom-272px {
  padding-bottom: 272px;
}

.padding-bottom-273px {
  padding-bottom: 273px;
}

.padding-bottom-274px {
  padding-bottom: 274px;
}

.padding-bottom-275px {
  padding-bottom: 275px;
}

.padding-bottom-276px {
  padding-bottom: 276px;
}

.padding-bottom-277px {
  padding-bottom: 277px;
}

.padding-bottom-278px {
  padding-bottom: 278px;
}

.padding-bottom-279px {
  padding-bottom: 279px;
}

.padding-bottom-280px {
  padding-bottom: 280px;
}

.padding-bottom-281px {
  padding-bottom: 281px;
}

.padding-bottom-282px {
  padding-bottom: 282px;
}

.padding-bottom-283px {
  padding-bottom: 283px;
}

.padding-bottom-284px {
  padding-bottom: 284px;
}

.padding-bottom-285px {
  padding-bottom: 285px;
}

.padding-bottom-286px {
  padding-bottom: 286px;
}

.padding-bottom-287px {
  padding-bottom: 287px;
}

.padding-bottom-288px {
  padding-bottom: 288px;
}

.padding-bottom-289px {
  padding-bottom: 289px;
}

.padding-bottom-290px {
  padding-bottom: 290px;
}

.padding-bottom-291px {
  padding-bottom: 291px;
}

.padding-bottom-292px {
  padding-bottom: 292px;
}

.padding-bottom-293px {
  padding-bottom: 293px;
}

.padding-bottom-294px {
  padding-bottom: 294px;
}

.padding-bottom-295px {
  padding-bottom: 295px;
}

.padding-bottom-296px {
  padding-bottom: 296px;
}

.padding-bottom-297px {
  padding-bottom: 297px;
}

.padding-bottom-298px {
  padding-bottom: 298px;
}

.padding-bottom-299px {
  padding-bottom: 299px;
}

.padding-bottom-300px {
  padding-bottom: 300px;
}

/*--- Padding Right & Left ---*/

.padding-lr-0px {
  padding-left: 0px;
  padding-right: 0px;
}

.padding-lr-1px {
  padding-left: 1px;
  padding-right: 1px;
}

.padding-lr-2px {
  padding-left: 2px;
  padding-right: 2px;
}

.padding-lr-3px {
  padding-left: 3px;
  padding-right: 3px;
}

.padding-lr-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.padding-lr-5px {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-lr-6px {
  padding-left: 6px;
  padding-right: 6px;
}

.padding-lr-7px {
  padding-left: 7px;
  padding-right: 7px;
}

.padding-lr-8px {
  padding-left: 8px;
  padding-right: 8px;
}

.padding-lr-9px {
  padding-left: 9px;
  padding-right: 9px;
}

.padding-lr-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-lr-11px {
  padding-left: 11px;
  padding-right: 11px;
}

.padding-lr-12px {
  padding-left: 12px;
  padding-right: 12px;
}

.padding-lr-13px {
  padding-left: 13px;
  padding-right: 13px;
}

.padding-lr-14px {
  padding-left: 14px;
  padding-right: 14px;
}

.padding-lr-15px {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-lr-16px {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-lr-17px {
  padding-left: 17px;
  padding-right: 17px;
}

.padding-lr-18px {
  padding-left: 18px;
  padding-right: 18px;
}

.padding-lr-19px {
  padding-left: 19px;
  padding-right: 19px;
}

.padding-lr-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-lr-21px {
  padding-left: 21px;
  padding-right: 21px;
}

.padding-lr-22px {
  padding-left: 22px;
  padding-right: 22px;
}

.padding-lr-23px {
  padding-left: 23px;
  padding-right: 23px;
}

.padding-lr-24px {
  padding-left: 24px;
  padding-right: 24px;
}

.padding-lr-25px {
  padding-left: 25px;
  padding-right: 25px;
}

.padding-lr-26px {
  padding-left: 26px;
  padding-right: 26px;
}

.padding-lr-27px {
  padding-left: 27px;
  padding-right: 27px;
}

.padding-lr-28px {
  padding-left: 28px;
  padding-right: 28px;
}

.padding-lr-29px {
  padding-left: 29px;
  padding-right: 29px;
}

.padding-lr-30px {
  padding-left: 30px;
  padding-right: 30px;
}

.padding-lr-31px {
  padding-left: 31px;
  padding-right: 31px;
}

.padding-lr-32px {
  padding-left: 32px;
  padding-right: 32px;
}

.padding-lr-33px {
  padding-left: 33px;
  padding-right: 33px;
}

.padding-lr-34px {
  padding-left: 34px;
  padding-right: 34px;
}

.padding-lr-35px {
  padding-left: 35px;
  padding-right: 35px;
}

.padding-lr-36px {
  padding-left: 36px;
  padding-right: 36px;
}

.padding-lr-37px {
  padding-left: 37px;
  padding-right: 37px;
}

.padding-lr-38px {
  padding-left: 38px;
  padding-right: 38px;
}

.padding-lr-39px {
  padding-left: 39px;
  padding-right: 39px;
}

.padding-lr-40px {
  padding-left: 40px;
  padding-right: 40px;
}

.padding-lr-41px {
  padding-left: 41px;
  padding-right: 41px;
}

.padding-lr-42px {
  padding-left: 42px;
  padding-right: 42px;
}

.padding-lr-43px {
  padding-left: 43px;
  padding-right: 43px;
}

.padding-lr-44px {
  padding-left: 44px;
  padding-right: 44px;
}

.padding-lr-45px {
  padding-left: 45px;
  padding-right: 45px;
}

.padding-lr-46px {
  padding-left: 46px;
  padding-right: 46px;
}

.padding-lr-47px {
  padding-left: 47px;
  padding-right: 47px;
}

.padding-lr-48px {
  padding-left: 48px;
  padding-right: 48px;
}

.padding-lr-49px {
  padding-left: 49px;
  padding-right: 49px;
}

.padding-lr-50px {
  padding-left: 50px;
  padding-right: 50px;
}

.padding-lr-51px {
  padding-left: 51px;
  padding-right: 51px;
}

.padding-lr-52px {
  padding-left: 52px;
  padding-right: 52px;
}

.padding-lr-53px {
  padding-left: 53px;
  padding-right: 53px;
}

.padding-lr-54px {
  padding-left: 54px;
  padding-right: 54px;
}

.padding-lr-55px {
  padding-left: 55px;
  padding-right: 55px;
}

.padding-lr-56px {
  padding-left: 56px;
  padding-right: 56px;
}

.padding-lr-57px {
  padding-left: 57px;
  padding-right: 57px;
}

.padding-lr-58px {
  padding-left: 58px;
  padding-right: 58px;
}

.padding-lr-59px {
  padding-left: 59px;
  padding-right: 59px;
}

.padding-lr-60px {
  padding-left: 60px;
  padding-right: 60px;
}

.padding-lr-61px {
  padding-left: 61px;
  padding-right: 61px;
}

.padding-lr-62px {
  padding-left: 62px;
  padding-right: 62px;
}

.padding-lr-63px {
  padding-left: 63px;
  padding-right: 63px;
}

.padding-lr-64px {
  padding-left: 64px;
  padding-right: 64px;
}

.padding-lr-65px {
  padding-left: 65px;
  padding-right: 65px;
}

.padding-lr-66px {
  padding-left: 66px;
  padding-right: 66px;
}

.padding-lr-67px {
  padding-left: 67px;
  padding-right: 67px;
}

.padding-lr-68px {
  padding-left: 68px;
  padding-right: 68px;
}

.padding-lr-69px {
  padding-left: 69px;
  padding-right: 69px;
}

.padding-lr-70px {
  padding-left: 70px;
  padding-right: 70px;
}

.padding-lr-71px {
  padding-left: 71px;
  padding-right: 71px;
}

.padding-lr-72px {
  padding-left: 72px;
  padding-right: 72px;
}

.padding-lr-73px {
  padding-left: 73px;
  padding-right: 73px;
}

.padding-lr-74px {
  padding-left: 74px;
  padding-right: 74px;
}

.padding-lr-75px {
  padding-left: 75px;
  padding-right: 75px;
}

.padding-lr-76px {
  padding-left: 76px;
  padding-right: 76px;
}

.padding-lr-77px {
  padding-left: 77px;
  padding-right: 77px;
}

.padding-lr-78px {
  padding-left: 78px;
  padding-right: 78px;
}

.padding-lr-79px {
  padding-left: 79px;
  padding-right: 79px;
}

.padding-lr-80px {
  padding-left: 80px;
  padding-right: 80px;
}

.padding-lr-81px {
  padding-left: 81px;
  padding-right: 81px;
}

.padding-lr-82px {
  padding-left: 82px;
  padding-right: 82px;
}

.padding-lr-83px {
  padding-left: 83px;
  padding-right: 83px;
}

.padding-lr-84px {
  padding-left: 84px;
  padding-right: 84px;
}

.padding-lr-85px {
  padding-left: 85px;
  padding-right: 85px;
}

.padding-lr-86px {
  padding-left: 86px;
  padding-right: 86px;
}

.padding-lr-87px {
  padding-left: 87px;
  padding-right: 87px;
}

.padding-lr-88px {
  padding-left: 88px;
  padding-right: 88px;
}

.padding-lr-89px {
  padding-left: 89px;
  padding-right: 89px;
}

.padding-lr-90px {
  padding-left: 90px;
  padding-right: 90px;
}

.padding-lr-91px {
  padding-left: 91px;
  padding-right: 91px;
}

.padding-lr-92px {
  padding-left: 92px;
  padding-right: 92px;
}

.padding-lr-93px {
  padding-left: 93px;
  padding-right: 93px;
}

.padding-lr-94px {
  padding-left: 94px;
  padding-right: 94px;
}

.padding-lr-95px {
  padding-left: 95px;
  padding-right: 95px;
}

.padding-lr-96px {
  padding-left: 96px;
  padding-right: 96px;
}

.padding-lr-97px {
  padding-left: 97px;
  padding-right: 97px;
}

.padding-lr-98px {
  padding-left: 98px;
  padding-right: 98px;
}

.padding-lr-99px {
  padding-left: 99px;
  padding-right: 99px;
}

.padding-lr-100px {
  padding-left: 100px;
  padding-right: 100px;
}

.padding-lr-101px {
  padding-left: 101px;
  padding-right: 101px;
}

.padding-lr-102px {
  padding-left: 102px;
  padding-right: 102px;
}

.padding-lr-103px {
  padding-left: 103px;
  padding-right: 103px;
}

.padding-lr-104px {
  padding-left: 104px;
  padding-right: 104px;
}

.padding-lr-105px {
  padding-left: 105px;
  padding-right: 105px;
}

.padding-lr-106px {
  padding-left: 106px;
  padding-right: 106px;
}

.padding-lr-107px {
  padding-left: 107px;
  padding-right: 107px;
}

.padding-lr-108px {
  padding-left: 108px;
  padding-right: 108px;
}

.padding-lr-109px {
  padding-left: 109px;
  padding-right: 109px;
}

.padding-lr-110px {
  padding-left: 110px;
  padding-right: 110px;
}

.padding-lr-111px {
  padding-left: 111px;
  padding-right: 111px;
}

.padding-lr-112px {
  padding-left: 112px;
  padding-right: 112px;
}

.padding-lr-113px {
  padding-left: 113px;
  padding-right: 113px;
}

.padding-lr-114px {
  padding-left: 114px;
  padding-right: 114px;
}

.padding-lr-115px {
  padding-left: 115px;
  padding-right: 115px;
}

.padding-lr-116px {
  padding-left: 116px;
  padding-right: 116px;
}

.padding-lr-117px {
  padding-left: 117px;
  padding-right: 117px;
}

.padding-lr-118px {
  padding-left: 118px;
  padding-right: 118px;
}

.padding-lr-119px {
  padding-left: 119px;
  padding-right: 119px;
}

.padding-lr-120px {
  padding-left: 120px;
  padding-right: 120px;
}

.padding-lr-121px {
  padding-left: 121px;
  padding-right: 121px;
}

.padding-lr-122px {
  padding-left: 122px;
  padding-right: 122px;
}

.padding-lr-123px {
  padding-left: 123px;
  padding-right: 123px;
}

.padding-lr-124px {
  padding-left: 124px;
  padding-right: 124px;
}

.padding-lr-125px {
  padding-left: 125px;
  padding-right: 125px;
}

.padding-lr-126px {
  padding-left: 126px;
  padding-right: 126px;
}

.padding-lr-127px {
  padding-left: 127px;
  padding-right: 127px;
}

.padding-lr-128px {
  padding-left: 128px;
  padding-right: 128px;
}

.padding-lr-129px {
  padding-left: 129px;
  padding-right: 129px;
}

.padding-lr-130px {
  padding-left: 130px;
  padding-right: 130px;
}

.padding-lr-131px {
  padding-left: 131px;
  padding-right: 131px;
}

.padding-lr-132px {
  padding-left: 132px;
  padding-right: 132px;
}

.padding-lr-133px {
  padding-left: 133px;
  padding-right: 133px;
}

.padding-lr-134px {
  padding-left: 134px;
  padding-right: 134px;
}

.padding-lr-135px {
  padding-left: 135px;
  padding-right: 135px;
}

.padding-lr-136px {
  padding-left: 136px;
  padding-right: 136px;
}

.padding-lr-137px {
  padding-left: 137px;
  padding-right: 137px;
}

.padding-lr-138px {
  padding-left: 138px;
  padding-right: 138px;
}

.padding-lr-139px {
  padding-left: 139px;
  padding-right: 139px;
}

.padding-lr-140px {
  padding-left: 140px;
  padding-right: 140px;
}

.padding-lr-141px {
  padding-left: 141px;
  padding-right: 141px;
}

.padding-lr-142px {
  padding-left: 142px;
  padding-right: 142px;
}

.padding-lr-143px {
  padding-left: 143px;
  padding-right: 143px;
}

.padding-lr-144px {
  padding-left: 144px;
  padding-right: 144px;
}

.padding-lr-145px {
  padding-left: 145px;
  padding-right: 145px;
}

.padding-lr-146px {
  padding-left: 146px;
  padding-right: 146px;
}

.padding-lr-147px {
  padding-left: 147px;
  padding-right: 147px;
}

.padding-lr-148px {
  padding-left: 148px;
  padding-right: 148px;
}

.padding-lr-149px {
  padding-left: 149px;
  padding-right: 149px;
}

.padding-lr-150px {
  padding-left: 150px;
  padding-right: 150px;
}

.padding-lr-151px {
  padding-left: 151px;
  padding-right: 151px;
}

.padding-lr-152px {
  padding-left: 152px;
  padding-right: 152px;
}

.padding-lr-153px {
  padding-left: 153px;
  padding-right: 153px;
}

.padding-lr-154px {
  padding-left: 154px;
  padding-right: 154px;
}

.padding-lr-155px {
  padding-left: 155px;
  padding-right: 155px;
}

.padding-lr-156px {
  padding-left: 156px;
  padding-right: 156px;
}

.padding-lr-157px {
  padding-left: 157px;
  padding-right: 157px;
}

.padding-lr-158px {
  padding-left: 158px;
  padding-right: 158px;
}

.padding-lr-159px {
  padding-left: 159px;
  padding-right: 159px;
}

.padding-lr-160px {
  padding-left: 160px;
  padding-right: 160px;
}

.padding-lr-161px {
  padding-left: 161px;
  padding-right: 161px;
}

.padding-lr-162px {
  padding-left: 162px;
  padding-right: 162px;
}

.padding-lr-163px {
  padding-left: 163px;
  padding-right: 163px;
}

.padding-lr-164px {
  padding-left: 164px;
  padding-right: 164px;
}

.padding-lr-165px {
  padding-left: 165px;
  padding-right: 165px;
}

.padding-lr-166px {
  padding-left: 166px;
  padding-right: 166px;
}

.padding-lr-167px {
  padding-left: 167px;
  padding-right: 167px;
}

.padding-lr-168px {
  padding-left: 168px;
  padding-right: 168px;
}

.padding-lr-169px {
  padding-left: 169px;
  padding-right: 169px;
}

.padding-lr-170px {
  padding-left: 170px;
  padding-right: 170px;
}

.padding-lr-171px {
  padding-left: 171px;
  padding-right: 171px;
}

.padding-lr-172px {
  padding-left: 172px;
  padding-right: 172px;
}

.padding-lr-173px {
  padding-left: 173px;
  padding-right: 173px;
}

.padding-lr-174px {
  padding-left: 174px;
  padding-right: 174px;
}

.padding-lr-175px {
  padding-left: 175px;
  padding-right: 175px;
}

.padding-lr-176px {
  padding-left: 176px;
  padding-right: 176px;
}

.padding-lr-177px {
  padding-left: 177px;
  padding-right: 177px;
}

.padding-lr-178px {
  padding-left: 178px;
  padding-right: 178px;
}

.padding-lr-179px {
  padding-left: 179px;
  padding-right: 179px;
}

.padding-lr-180px {
  padding-left: 180px;
  padding-right: 180px;
}

.padding-lr-181px {
  padding-left: 181px;
  padding-right: 181px;
}

.padding-lr-182px {
  padding-left: 182px;
  padding-right: 182px;
}

.padding-lr-183px {
  padding-left: 183px;
  padding-right: 183px;
}

.padding-lr-184px {
  padding-left: 184px;
  padding-right: 184px;
}

.padding-lr-185px {
  padding-left: 185px;
  padding-right: 185px;
}

.padding-lr-186px {
  padding-left: 186px;
  padding-right: 186px;
}

.padding-lr-187px {
  padding-left: 187px;
  padding-right: 187px;
}

.padding-lr-188px {
  padding-left: 188px;
  padding-right: 188px;
}

.padding-lr-189px {
  padding-left: 189px;
  padding-right: 189px;
}

.padding-lr-190px {
  padding-left: 190px;
  padding-right: 190px;
}

.padding-lr-191px {
  padding-left: 191px;
  padding-right: 191px;
}

.padding-lr-192px {
  padding-left: 192px;
  padding-right: 192px;
}

.padding-lr-193px {
  padding-left: 193px;
  padding-right: 193px;
}

.padding-lr-194px {
  padding-left: 194px;
  padding-right: 194px;
}

.padding-lr-195px {
  padding-left: 195px;
  padding-right: 195px;
}

.padding-lr-196px {
  padding-left: 196px;
  padding-right: 196px;
}

.padding-lr-197px {
  padding-left: 197px;
  padding-right: 197px;
}

.padding-lr-198px {
  padding-left: 198px;
  padding-right: 198px;
}

.padding-lr-199px {
  padding-left: 199px;
  padding-right: 199px;
}

.padding-lr-200px {
  padding-left: 200px;
  padding-right: 200px;
}

.padding-lr-201px {
  padding-left: 201px;
  padding-right: 201px;
}

.padding-lr-202px {
  padding-left: 202px;
  padding-right: 202px;
}

.padding-lr-203px {
  padding-left: 203px;
  padding-right: 203px;
}

.padding-lr-204px {
  padding-left: 204px;
  padding-right: 204px;
}

.padding-lr-205px {
  padding-left: 205px;
  padding-right: 205px;
}

.padding-lr-206px {
  padding-left: 206px;
  padding-right: 206px;
}

.padding-lr-207px {
  padding-left: 207px;
  padding-right: 207px;
}

.padding-lr-208px {
  padding-left: 208px;
  padding-right: 208px;
}

.padding-lr-209px {
  padding-left: 209px;
  padding-right: 209px;
}

.padding-lr-210px {
  padding-left: 210px;
  padding-right: 210px;
}

.padding-lr-211px {
  padding-left: 211px;
  padding-right: 211px;
}

.padding-lr-212px {
  padding-left: 212px;
  padding-right: 212px;
}

.padding-lr-213px {
  padding-left: 213px;
  padding-right: 213px;
}

.padding-lr-214px {
  padding-left: 214px;
  padding-right: 214px;
}

.padding-lr-215px {
  padding-left: 215px;
  padding-right: 215px;
}

.padding-lr-216px {
  padding-left: 216px;
  padding-right: 216px;
}

.padding-lr-217px {
  padding-left: 217px;
  padding-right: 217px;
}

.padding-lr-218px {
  padding-left: 218px;
  padding-right: 218px;
}

.padding-lr-219px {
  padding-left: 219px;
  padding-right: 219px;
}

.padding-lr-220px {
  padding-left: 220px;
  padding-right: 220px;
}

.padding-lr-221px {
  padding-left: 221px;
  padding-right: 221px;
}

.padding-lr-222px {
  padding-left: 222px;
  padding-right: 222px;
}

.padding-lr-223px {
  padding-left: 223px;
  padding-right: 223px;
}

.padding-lr-224px {
  padding-left: 224px;
  padding-right: 224px;
}

.padding-lr-225px {
  padding-left: 225px;
  padding-right: 225px;
}

.padding-lr-226px {
  padding-left: 226px;
  padding-right: 226px;
}

.padding-lr-227px {
  padding-left: 227px;
  padding-right: 227px;
}

.padding-lr-228px {
  padding-left: 228px;
  padding-right: 228px;
}

.padding-lr-229px {
  padding-left: 229px;
  padding-right: 229px;
}

.padding-lr-230px {
  padding-left: 230px;
  padding-right: 230px;
}

.padding-lr-231px {
  padding-left: 231px;
  padding-right: 231px;
}

.padding-lr-232px {
  padding-left: 232px;
  padding-right: 232px;
}

.padding-lr-233px {
  padding-left: 233px;
  padding-right: 233px;
}

.padding-lr-234px {
  padding-left: 234px;
  padding-right: 234px;
}

.padding-lr-235px {
  padding-left: 235px;
  padding-right: 235px;
}

.padding-lr-236px {
  padding-left: 236px;
  padding-right: 236px;
}

.padding-lr-237px {
  padding-left: 237px;
  padding-right: 237px;
}

.padding-lr-238px {
  padding-left: 238px;
  padding-right: 238px;
}

.padding-lr-239px {
  padding-left: 239px;
  padding-right: 239px;
}

.padding-lr-240px {
  padding-left: 240px;
  padding-right: 240px;
}

.padding-lr-241px {
  padding-left: 241px;
  padding-right: 241px;
}

.padding-lr-242px {
  padding-left: 242px;
  padding-right: 242px;
}

.padding-lr-243px {
  padding-left: 243px;
  padding-right: 243px;
}

.padding-lr-244px {
  padding-left: 244px;
  padding-right: 244px;
}

.padding-lr-245px {
  padding-left: 245px;
  padding-right: 245px;
}

.padding-lr-246px {
  padding-left: 246px;
  padding-right: 246px;
}

.padding-lr-247px {
  padding-left: 247px;
  padding-right: 247px;
}

.padding-lr-248px {
  padding-left: 248px;
  padding-right: 248px;
}

.padding-lr-249px {
  padding-left: 249px;
  padding-right: 249px;
}

.padding-lr-250px {
  padding-left: 250px;
  padding-right: 250px;
}

.padding-lr-251px {
  padding-left: 251px;
  padding-right: 251px;
}

.padding-lr-252px {
  padding-left: 252px;
  padding-right: 252px;
}

.padding-lr-253px {
  padding-left: 253px;
  padding-right: 253px;
}

.padding-lr-254px {
  padding-left: 254px;
  padding-right: 254px;
}

.padding-lr-255px {
  padding-left: 255px;
  padding-right: 255px;
}

.padding-lr-256px {
  padding-left: 256px;
  padding-right: 256px;
}

.padding-lr-257px {
  padding-left: 257px;
  padding-right: 257px;
}

.padding-lr-258px {
  padding-left: 258px;
  padding-right: 258px;
}

.padding-lr-259px {
  padding-left: 259px;
  padding-right: 259px;
}

.padding-lr-260px {
  padding-left: 260px;
  padding-right: 260px;
}

.padding-lr-261px {
  padding-left: 261px;
  padding-right: 261px;
}

.padding-lr-262px {
  padding-left: 262px;
  padding-right: 262px;
}

.padding-lr-263px {
  padding-left: 263px;
  padding-right: 263px;
}

.padding-lr-264px {
  padding-left: 264px;
  padding-right: 264px;
}

.padding-lr-265px {
  padding-left: 265px;
  padding-right: 265px;
}

.padding-lr-266px {
  padding-left: 266px;
  padding-right: 266px;
}

.padding-lr-267px {
  padding-left: 267px;
  padding-right: 267px;
}

.padding-lr-268px {
  padding-left: 268px;
  padding-right: 268px;
}

.padding-lr-269px {
  padding-left: 269px;
  padding-right: 269px;
}

.padding-lr-270px {
  padding-left: 270px;
  padding-right: 270px;
}

.padding-lr-271px {
  padding-left: 271px;
  padding-right: 271px;
}

.padding-lr-272px {
  padding-left: 272px;
  padding-right: 272px;
}

.padding-lr-273px {
  padding-left: 273px;
  padding-right: 273px;
}

.padding-lr-274px {
  padding-left: 274px;
  padding-right: 274px;
}

.padding-lr-275px {
  padding-left: 275px;
  padding-right: 275px;
}

.padding-lr-276px {
  padding-left: 276px;
  padding-right: 276px;
}

.padding-lr-277px {
  padding-left: 277px;
  padding-right: 277px;
}

.padding-lr-278px {
  padding-left: 278px;
  padding-right: 278px;
}

.padding-lr-279px {
  padding-left: 279px;
  padding-right: 279px;
}

.padding-lr-280px {
  padding-left: 280px;
  padding-right: 280px;
}

.padding-lr-281px {
  padding-left: 281px;
  padding-right: 281px;
}

.padding-lr-282px {
  padding-left: 282px;
  padding-right: 282px;
}

.padding-lr-283px {
  padding-left: 283px;
  padding-right: 283px;
}

.padding-lr-284px {
  padding-left: 284px;
  padding-right: 284px;
}

.padding-lr-285px {
  padding-left: 285px;
  padding-right: 285px;
}

.padding-lr-286px {
  padding-left: 286px;
  padding-right: 286px;
}

.padding-lr-287px {
  padding-left: 287px;
  padding-right: 287px;
}

.padding-lr-288px {
  padding-left: 288px;
  padding-right: 288px;
}

.padding-lr-289px {
  padding-left: 289px;
  padding-right: 289px;
}

.padding-lr-290px {
  padding-left: 290px;
  padding-right: 290px;
}

.padding-lr-291px {
  padding-left: 291px;
  padding-right: 291px;
}

.padding-lr-292px {
  padding-left: 292px;
  padding-right: 292px;
}

.padding-lr-293px {
  padding-left: 293px;
  padding-right: 293px;
}

.padding-lr-294px {
  padding-left: 294px;
  padding-right: 294px;
}

.padding-lr-295px {
  padding-left: 295px;
  padding-right: 295px;
}

.padding-lr-296px {
  padding-left: 296px;
  padding-right: 296px;
}

.padding-lr-297px {
  padding-left: 297px;
  padding-right: 297px;
}

.padding-lr-298px {
  padding-left: 298px;
  padding-right: 298px;
}

.padding-lr-299px {
  padding-left: 299px;
  padding-right: 299px;
}

.padding-lr-300px {
  padding-left: 300px;
  padding-right: 300px;
}

/*--- Padding Left ---*/

.padding-left-0px {
  padding-left: 0px;
}

.padding-left-1px {
  padding-left: 1px;
}

.padding-left-2px {
  padding-left: 2px;
}

.padding-left-3px {
  padding-left: 3px;
}

.padding-left-4px {
  padding-left: 4px;
}

.padding-left-5px {
  padding-left: 5px;
}

.padding-left-6px {
  padding-left: 6px;
}

.padding-left-7px {
  padding-left: 7px;
}

.padding-left-8px {
  padding-left: 8px;
}

.padding-left-9px {
  padding-left: 9px;
}

.padding-left-10px {
  padding-left: 10px;
}

.padding-left-11px {
  padding-left: 11px;
}

.padding-left-12px {
  padding-left: 12px;
}

.padding-left-13px {
  padding-left: 13px;
}

.padding-left-14px {
  padding-left: 14px;
}

.padding-left-15px {
  padding-left: 15px;
}

.padding-left-16px {
  padding-left: 16px;
}

.padding-left-17px {
  padding-left: 17px;
}

.padding-left-18px {
  padding-left: 18px;
}

.padding-left-19px {
  padding-left: 19px;
}

.padding-left-20px {
  padding-left: 20px;
}

.padding-left-21px {
  padding-left: 21px;
}

.padding-left-22px {
  padding-left: 22px;
}

.padding-left-23px {
  padding-left: 23px;
}

.padding-left-24px {
  padding-left: 24px;
}

.padding-left-25px {
  padding-left: 25px;
}

.padding-left-26px {
  padding-left: 26px;
}

.padding-left-27px {
  padding-left: 27px;
}

.padding-left-28px {
  padding-left: 28px;
}

.padding-left-29px {
  padding-left: 29px;
}

.padding-left-30px {
  padding-left: 30px;
}

.padding-left-31px {
  padding-left: 31px;
}

.padding-left-32px {
  padding-left: 32px;
}

.padding-left-33px {
  padding-left: 33px;
}

.padding-left-34px {
  padding-left: 34px;
}

.padding-left-35px {
  padding-left: 35px;
}

.padding-left-36px {
  padding-left: 36px;
}

.padding-left-37px {
  padding-left: 37px;
}

.padding-left-38px {
  padding-left: 38px;
}

.padding-left-39px {
  padding-left: 39px;
}

.padding-left-40px {
  padding-left: 40px;
}

.padding-left-41px {
  padding-left: 41px;
}

.padding-left-42px {
  padding-left: 42px;
}

.padding-left-43px {
  padding-left: 43px;
}

.padding-left-44px {
  padding-left: 44px;
}

.padding-left-45px {
  padding-left: 45px;
}

.padding-left-46px {
  padding-left: 46px;
}

.padding-left-47px {
  padding-left: 47px;
}

.padding-left-48px {
  padding-left: 48px;
}

.padding-left-49px {
  padding-left: 49px;
}

.padding-left-50px {
  padding-left: 50px;
}

.padding-left-51px {
  padding-left: 51px;
}

.padding-left-52px {
  padding-left: 52px;
}

.padding-left-53px {
  padding-left: 53px;
}

.padding-left-54px {
  padding-left: 54px;
}

.padding-left-55px {
  padding-left: 55px;
}

.padding-left-56px {
  padding-left: 56px;
}

.padding-left-57px {
  padding-left: 57px;
}

.padding-left-58px {
  padding-left: 58px;
}

.padding-left-59px {
  padding-left: 59px;
}

.padding-left-60px {
  padding-left: 60px;
}

.padding-left-61px {
  padding-left: 61px;
}

.padding-left-62px {
  padding-left: 62px;
}

.padding-left-63px {
  padding-left: 63px;
}

.padding-left-64px {
  padding-left: 64px;
}

.padding-left-65px {
  padding-left: 65px;
}

.padding-left-66px {
  padding-left: 66px;
}

.padding-left-67px {
  padding-left: 67px;
}

.padding-left-68px {
  padding-left: 68px;
}

.padding-left-69px {
  padding-left: 69px;
}

.padding-left-70px {
  padding-left: 70px;
}

.padding-left-71px {
  padding-left: 71px;
}

.padding-left-72px {
  padding-left: 72px;
}

.padding-left-73px {
  padding-left: 73px;
}

.padding-left-74px {
  padding-left: 74px;
}

.padding-left-75px {
  padding-left: 75px;
}

.padding-left-76px {
  padding-left: 76px;
}

.padding-left-77px {
  padding-left: 77px;
}

.padding-left-78px {
  padding-left: 78px;
}

.padding-left-79px {
  padding-left: 79px;
}

.padding-left-80px {
  padding-left: 80px;
}

.padding-left-81px {
  padding-left: 81px;
}

.padding-left-82px {
  padding-left: 82px;
}

.padding-left-83px {
  padding-left: 83px;
}

.padding-left-84px {
  padding-left: 84px;
}

.padding-left-85px {
  padding-left: 85px;
}

.padding-left-86px {
  padding-left: 86px;
}

.padding-left-87px {
  padding-left: 87px;
}

.padding-left-88px {
  padding-left: 88px;
}

.padding-left-89px {
  padding-left: 89px;
}

.padding-left-90px {
  padding-left: 90px;
}

.padding-left-91px {
  padding-left: 91px;
}

.padding-left-92px {
  padding-left: 92px;
}

.padding-left-93px {
  padding-left: 93px;
}

.padding-left-94px {
  padding-left: 94px;
}

.padding-left-95px {
  padding-left: 95px;
}

.padding-left-96px {
  padding-left: 96px;
}

.padding-left-97px {
  padding-left: 97px;
}

.padding-left-98px {
  padding-left: 98px;
}

.padding-left-99px {
  padding-left: 99px;
}

.padding-left-100px {
  padding-left: 100px;
}

.padding-left-101px {
  padding-left: 101px;
}

.padding-left-102px {
  padding-left: 102px;
}

.padding-left-103px {
  padding-left: 103px;
}

.padding-left-104px {
  padding-left: 104px;
}

.padding-left-105px {
  padding-left: 105px;
}

.padding-left-106px {
  padding-left: 106px;
}

.padding-left-107px {
  padding-left: 107px;
}

.padding-left-108px {
  padding-left: 108px;
}

.padding-left-109px {
  padding-left: 109px;
}

.padding-left-110px {
  padding-left: 110px;
}

.padding-left-111px {
  padding-left: 111px;
}

.padding-left-112px {
  padding-left: 112px;
}

.padding-left-113px {
  padding-left: 113px;
}

.padding-left-114px {
  padding-left: 114px;
}

.padding-left-115px {
  padding-left: 115px;
}

.padding-left-116px {
  padding-left: 116px;
}

.padding-left-117px {
  padding-left: 117px;
}

.padding-left-118px {
  padding-left: 118px;
}

.padding-left-119px {
  padding-left: 119px;
}

.padding-left-120px {
  padding-left: 120px;
}

.padding-left-121px {
  padding-left: 121px;
}

.padding-left-122px {
  padding-left: 122px;
}

.padding-left-123px {
  padding-left: 123px;
}

.padding-left-124px {
  padding-left: 124px;
}

.padding-left-125px {
  padding-left: 125px;
}

.padding-left-126px {
  padding-left: 126px;
}

.padding-left-127px {
  padding-left: 127px;
}

.padding-left-128px {
  padding-left: 128px;
}

.padding-left-129px {
  padding-left: 129px;
}

.padding-left-130px {
  padding-left: 130px;
}

.padding-left-131px {
  padding-left: 131px;
}

.padding-left-132px {
  padding-left: 132px;
}

.padding-left-133px {
  padding-left: 133px;
}

.padding-left-134px {
  padding-left: 134px;
}

.padding-left-135px {
  padding-left: 135px;
}

.padding-left-136px {
  padding-left: 136px;
}

.padding-left-137px {
  padding-left: 137px;
}

.padding-left-138px {
  padding-left: 138px;
}

.padding-left-139px {
  padding-left: 139px;
}

.padding-left-140px {
  padding-left: 140px;
}

.padding-left-141px {
  padding-left: 141px;
}

.padding-left-142px {
  padding-left: 142px;
}

.padding-left-143px {
  padding-left: 143px;
}

.padding-left-144px {
  padding-left: 144px;
}

.padding-left-145px {
  padding-left: 145px;
}

.padding-left-146px {
  padding-left: 146px;
}

.padding-left-147px {
  padding-left: 147px;
}

.padding-left-148px {
  padding-left: 148px;
}

.padding-left-149px {
  padding-left: 149px;
}

.padding-left-150px {
  padding-left: 150px;
}

.padding-left-151px {
  padding-left: 151px;
}

.padding-left-152px {
  padding-left: 152px;
}

.padding-left-153px {
  padding-left: 153px;
}

.padding-left-154px {
  padding-left: 154px;
}

.padding-left-155px {
  padding-left: 155px;
}

.padding-left-156px {
  padding-left: 156px;
}

.padding-left-157px {
  padding-left: 157px;
}

.padding-left-158px {
  padding-left: 158px;
}

.padding-left-159px {
  padding-left: 159px;
}

.padding-left-160px {
  padding-left: 160px;
}

.padding-left-161px {
  padding-left: 161px;
}

.padding-left-162px {
  padding-left: 162px;
}

.padding-left-163px {
  padding-left: 163px;
}

.padding-left-164px {
  padding-left: 164px;
}

.padding-left-165px {
  padding-left: 165px;
}

.padding-left-166px {
  padding-left: 166px;
}

.padding-left-167px {
  padding-left: 167px;
}

.padding-left-168px {
  padding-left: 168px;
}

.padding-left-169px {
  padding-left: 169px;
}

.padding-left-170px {
  padding-left: 170px;
}

.padding-left-171px {
  padding-left: 171px;
}

.padding-left-172px {
  padding-left: 172px;
}

.padding-left-173px {
  padding-left: 173px;
}

.padding-left-174px {
  padding-left: 174px;
}

.padding-left-175px {
  padding-left: 175px;
}

.padding-left-176px {
  padding-left: 176px;
}

.padding-left-177px {
  padding-left: 177px;
}

.padding-left-178px {
  padding-left: 178px;
}

.padding-left-179px {
  padding-left: 179px;
}

.padding-left-180px {
  padding-left: 180px;
}

.padding-left-181px {
  padding-left: 181px;
}

.padding-left-182px {
  padding-left: 182px;
}

.padding-left-183px {
  padding-left: 183px;
}

.padding-left-184px {
  padding-left: 184px;
}

.padding-left-185px {
  padding-left: 185px;
}

.padding-left-186px {
  padding-left: 186px;
}

.padding-left-187px {
  padding-left: 187px;
}

.padding-left-188px {
  padding-left: 188px;
}

.padding-left-189px {
  padding-left: 189px;
}

.padding-left-190px {
  padding-left: 190px;
}

.padding-left-191px {
  padding-left: 191px;
}

.padding-left-192px {
  padding-left: 192px;
}

.padding-left-193px {
  padding-left: 193px;
}

.padding-left-194px {
  padding-left: 194px;
}

.padding-left-195px {
  padding-left: 195px;
}

.padding-left-196px {
  padding-left: 196px;
}

.padding-left-197px {
  padding-left: 197px;
}

.padding-left-198px {
  padding-left: 198px;
}

.padding-left-199px {
  padding-left: 199px;
}

.padding-left-200px {
  padding-left: 200px;
}

.padding-left-201px {
  padding-left: 201px;
}

.padding-left-202px {
  padding-left: 202px;
}

.padding-left-203px {
  padding-left: 203px;
}

.padding-left-204px {
  padding-left: 204px;
}

.padding-left-205px {
  padding-left: 205px;
}

.padding-left-206px {
  padding-left: 206px;
}

.padding-left-207px {
  padding-left: 207px;
}

.padding-left-208px {
  padding-left: 208px;
}

.padding-left-209px {
  padding-left: 209px;
}

.padding-left-210px {
  padding-left: 210px;
}

.padding-left-211px {
  padding-left: 211px;
}

.padding-left-212px {
  padding-left: 212px;
}

.padding-left-213px {
  padding-left: 213px;
}

.padding-left-214px {
  padding-left: 214px;
}

.padding-left-215px {
  padding-left: 215px;
}

.padding-left-216px {
  padding-left: 216px;
}

.padding-left-217px {
  padding-left: 217px;
}

.padding-left-218px {
  padding-left: 218px;
}

.padding-left-219px {
  padding-left: 219px;
}

.padding-left-220px {
  padding-left: 220px;
}

.padding-left-221px {
  padding-left: 221px;
}

.padding-left-222px {
  padding-left: 222px;
}

.padding-left-223px {
  padding-left: 223px;
}

.padding-left-224px {
  padding-left: 224px;
}

.padding-left-225px {
  padding-left: 225px;
}

.padding-left-226px {
  padding-left: 226px;
}

.padding-left-227px {
  padding-left: 227px;
}

.padding-left-228px {
  padding-left: 228px;
}

.padding-left-229px {
  padding-left: 229px;
}

.padding-left-230px {
  padding-left: 230px;
}

.padding-left-231px {
  padding-left: 231px;
}

.padding-left-232px {
  padding-left: 232px;
}

.padding-left-233px {
  padding-left: 233px;
}

.padding-left-234px {
  padding-left: 234px;
}

.padding-left-235px {
  padding-left: 235px;
}

.padding-left-236px {
  padding-left: 236px;
}

.padding-left-237px {
  padding-left: 237px;
}

.padding-left-238px {
  padding-left: 238px;
}

.padding-left-239px {
  padding-left: 239px;
}

.padding-left-240px {
  padding-left: 240px;
}

.padding-left-241px {
  padding-left: 241px;
}

.padding-left-242px {
  padding-left: 242px;
}

.padding-left-243px {
  padding-left: 243px;
}

.padding-left-244px {
  padding-left: 244px;
}

.padding-left-245px {
  padding-left: 245px;
}

.padding-left-246px {
  padding-left: 246px;
}

.padding-left-247px {
  padding-left: 247px;
}

.padding-left-248px {
  padding-left: 248px;
}

.padding-left-249px {
  padding-left: 249px;
}

.padding-left-250px {
  padding-left: 250px;
}

.padding-left-251px {
  padding-left: 251px;
}

.padding-left-252px {
  padding-left: 252px;
}

.padding-left-253px {
  padding-left: 253px;
}

.padding-left-254px {
  padding-left: 254px;
}

.padding-left-255px {
  padding-left: 255px;
}

.padding-left-256px {
  padding-left: 256px;
}

.padding-left-257px {
  padding-left: 257px;
}

.padding-left-258px {
  padding-left: 258px;
}

.padding-left-259px {
  padding-left: 259px;
}

.padding-left-260px {
  padding-left: 260px;
}

.padding-left-261px {
  padding-left: 261px;
}

.padding-left-262px {
  padding-left: 262px;
}

.padding-left-263px {
  padding-left: 263px;
}

.padding-left-264px {
  padding-left: 264px;
}

.padding-left-265px {
  padding-left: 265px;
}

.padding-left-266px {
  padding-left: 266px;
}

.padding-left-267px {
  padding-left: 267px;
}

.padding-left-268px {
  padding-left: 268px;
}

.padding-left-269px {
  padding-left: 269px;
}

.padding-left-270px {
  padding-left: 270px;
}

.padding-left-271px {
  padding-left: 271px;
}

.padding-left-272px {
  padding-left: 272px;
}

.padding-left-273px {
  padding-left: 273px;
}

.padding-left-274px {
  padding-left: 274px;
}

.padding-left-275px {
  padding-left: 275px;
}

.padding-left-276px {
  padding-left: 276px;
}

.padding-left-277px {
  padding-left: 277px;
}

.padding-left-278px {
  padding-left: 278px;
}

.padding-left-279px {
  padding-left: 279px;
}

.padding-left-280px {
  padding-left: 280px;
}

.padding-left-281px {
  padding-left: 281px;
}

.padding-left-282px {
  padding-left: 282px;
}

.padding-left-283px {
  padding-left: 283px;
}

.padding-left-284px {
  padding-left: 284px;
}

.padding-left-285px {
  padding-left: 285px;
}

.padding-left-286px {
  padding-left: 286px;
}

.padding-left-287px {
  padding-left: 287px;
}

.padding-left-288px {
  padding-left: 288px;
}

.padding-left-289px {
  padding-left: 289px;
}

.padding-left-290px {
  padding-left: 290px;
}

.padding-left-291px {
  padding-left: 291px;
}

.padding-left-292px {
  padding-left: 292px;
}

.padding-left-293px {
  padding-left: 293px;
}

.padding-left-294px {
  padding-left: 294px;
}

.padding-left-295px {
  padding-left: 295px;
}

.padding-left-296px {
  padding-left: 296px;
}

.padding-left-297px {
  padding-left: 297px;
}

.padding-left-298px {
  padding-left: 298px;
}

.padding-left-299px {
  padding-left: 299px;
}

.padding-left-300px {
  padding-left: 300px;
}

/*--- Padding right ---*/

.padding-right-0px {
  padding-right: 0px;
}

.padding-right-1px {
  padding-right: 1px;
}

.padding-right-2px {
  padding-right: 2px;
}

.padding-right-3px {
  padding-right: 3px;
}

.padding-right-4px {
  padding-right: 4px;
}

.padding-right-5px {
  padding-right: 5px;
}

.padding-right-6px {
  padding-right: 6px;
}

.padding-right-7px {
  padding-right: 7px;
}

.padding-right-8px {
  padding-right: 8px;
}

.padding-right-9px {
  padding-right: 9px;
}

.padding-right-10px {
  padding-right: 10px;
}

.padding-right-11px {
  padding-right: 11px;
}

.padding-right-12px {
  padding-right: 12px;
}

.padding-right-13px {
  padding-right: 13px;
}

.padding-right-14px {
  padding-right: 14px;
}

.padding-right-15px {
  padding-right: 15px;
}

.padding-right-16px {
  padding-right: 16px;
}

.padding-right-17px {
  padding-right: 17px;
}

.padding-right-18px {
  padding-right: 18px;
}

.padding-right-19px {
  padding-right: 19px;
}

.padding-right-20px {
  padding-right: 20px;
}

.padding-right-21px {
  padding-right: 21px;
}

.padding-right-22px {
  padding-right: 22px;
}

.padding-right-23px {
  padding-right: 23px;
}

.padding-right-24px {
  padding-right: 24px;
}

.padding-right-25px {
  padding-right: 25px;
}

.padding-right-26px {
  padding-right: 26px;
}

.padding-right-27px {
  padding-right: 27px;
}

.padding-right-28px {
  padding-right: 28px;
}

.padding-right-29px {
  padding-right: 29px;
}

.padding-right-30px {
  padding-right: 30px;
}

.padding-right-31px {
  padding-right: 31px;
}

.padding-right-32px {
  padding-right: 32px;
}

.padding-right-33px {
  padding-right: 33px;
}

.padding-right-34px {
  padding-right: 34px;
}

.padding-right-35px {
  padding-right: 35px;
}

.padding-right-36px {
  padding-right: 36px;
}

.padding-right-37px {
  padding-right: 37px;
}

.padding-right-38px {
  padding-right: 38px;
}

.padding-right-39px {
  padding-right: 39px;
}

.padding-right-40px {
  padding-right: 40px;
}

.padding-right-41px {
  padding-right: 41px;
}

.padding-right-42px {
  padding-right: 42px;
}

.padding-right-43px {
  padding-right: 43px;
}

.padding-right-44px {
  padding-right: 44px;
}

.padding-right-45px {
  padding-right: 45px;
}

.padding-right-46px {
  padding-right: 46px;
}

.padding-right-47px {
  padding-right: 47px;
}

.padding-right-48px {
  padding-right: 48px;
}

.padding-right-49px {
  padding-right: 49px;
}

.padding-right-50px {
  padding-right: 50px;
}

.padding-right-51px {
  padding-right: 51px;
}

.padding-right-52px {
  padding-right: 52px;
}

.padding-right-53px {
  padding-right: 53px;
}

.padding-right-54px {
  padding-right: 54px;
}

.padding-right-55px {
  padding-right: 55px;
}

.padding-right-56px {
  padding-right: 56px;
}

.padding-right-57px {
  padding-right: 57px;
}

.padding-right-58px {
  padding-right: 58px;
}

.padding-right-59px {
  padding-right: 59px;
}

.padding-right-60px {
  padding-right: 60px;
}

.padding-right-61px {
  padding-right: 61px;
}

.padding-right-62px {
  padding-right: 62px;
}

.padding-right-63px {
  padding-right: 63px;
}

.padding-right-64px {
  padding-right: 64px;
}

.padding-right-65px {
  padding-right: 65px;
}

.padding-right-66px {
  padding-right: 66px;
}

.padding-right-67px {
  padding-right: 67px;
}

.padding-right-68px {
  padding-right: 68px;
}

.padding-right-69px {
  padding-right: 69px;
}

.padding-right-70px {
  padding-right: 70px;
}

.padding-right-71px {
  padding-right: 71px;
}

.padding-right-72px {
  padding-right: 72px;
}

.padding-right-73px {
  padding-right: 73px;
}

.padding-right-74px {
  padding-right: 74px;
}

.padding-right-75px {
  padding-right: 75px;
}

.padding-right-76px {
  padding-right: 76px;
}

.padding-right-77px {
  padding-right: 77px;
}

.padding-right-78px {
  padding-right: 78px;
}

.padding-right-79px {
  padding-right: 79px;
}

.padding-right-80px {
  padding-right: 80px;
}

.padding-right-81px {
  padding-right: 81px;
}

.padding-right-82px {
  padding-right: 82px;
}

.padding-right-83px {
  padding-right: 83px;
}

.padding-right-84px {
  padding-right: 84px;
}

.padding-right-85px {
  padding-right: 85px;
}

.padding-right-86px {
  padding-right: 86px;
}

.padding-right-87px {
  padding-right: 87px;
}

.padding-right-88px {
  padding-right: 88px;
}

.padding-right-89px {
  padding-right: 89px;
}

.padding-right-90px {
  padding-right: 90px;
}

.padding-right-91px {
  padding-right: 91px;
}

.padding-right-92px {
  padding-right: 92px;
}

.padding-right-93px {
  padding-right: 93px;
}

.padding-right-94px {
  padding-right: 94px;
}

.padding-right-95px {
  padding-right: 95px;
}

.padding-right-96px {
  padding-right: 96px;
}

.padding-right-97px {
  padding-right: 97px;
}

.padding-right-98px {
  padding-right: 98px;
}

.padding-right-99px {
  padding-right: 99px;
}

.padding-right-100px {
  padding-right: 100px;
}

.padding-right-101px {
  padding-right: 101px;
}

.padding-right-102px {
  padding-right: 102px;
}

.padding-right-103px {
  padding-right: 103px;
}

.padding-right-104px {
  padding-right: 104px;
}

.padding-right-105px {
  padding-right: 105px;
}

.padding-right-106px {
  padding-right: 106px;
}

.padding-right-107px {
  padding-right: 107px;
}

.padding-right-108px {
  padding-right: 108px;
}

.padding-right-109px {
  padding-right: 109px;
}

.padding-right-110px {
  padding-right: 110px;
}

.padding-right-111px {
  padding-right: 111px;
}

.padding-right-112px {
  padding-right: 112px;
}

.padding-right-113px {
  padding-right: 113px;
}

.padding-right-114px {
  padding-right: 114px;
}

.padding-right-115px {
  padding-right: 115px;
}

.padding-right-116px {
  padding-right: 116px;
}

.padding-right-117px {
  padding-right: 117px;
}

.padding-right-118px {
  padding-right: 118px;
}

.padding-right-119px {
  padding-right: 119px;
}

.padding-right-120px {
  padding-right: 120px;
}

.padding-right-121px {
  padding-right: 121px;
}

.padding-right-122px {
  padding-right: 122px;
}

.padding-right-123px {
  padding-right: 123px;
}

.padding-right-124px {
  padding-right: 124px;
}

.padding-right-125px {
  padding-right: 125px;
}

.padding-right-126px {
  padding-right: 126px;
}

.padding-right-127px {
  padding-right: 127px;
}

.padding-right-128px {
  padding-right: 128px;
}

.padding-right-129px {
  padding-right: 129px;
}

.padding-right-130px {
  padding-right: 130px;
}

.padding-right-131px {
  padding-right: 131px;
}

.padding-right-132px {
  padding-right: 132px;
}

.padding-right-133px {
  padding-right: 133px;
}

.padding-right-134px {
  padding-right: 134px;
}

.padding-right-135px {
  padding-right: 135px;
}

.padding-right-136px {
  padding-right: 136px;
}

.padding-right-137px {
  padding-right: 137px;
}

.padding-right-138px {
  padding-right: 138px;
}

.padding-right-139px {
  padding-right: 139px;
}

.padding-right-140px {
  padding-right: 140px;
}

.padding-right-141px {
  padding-right: 141px;
}

.padding-right-142px {
  padding-right: 142px;
}

.padding-right-143px {
  padding-right: 143px;
}

.padding-right-144px {
  padding-right: 144px;
}

.padding-right-145px {
  padding-right: 145px;
}

.padding-right-146px {
  padding-right: 146px;
}

.padding-right-147px {
  padding-right: 147px;
}

.padding-right-148px {
  padding-right: 148px;
}

.padding-right-149px {
  padding-right: 149px;
}

.padding-right-150px {
  padding-right: 150px;
}

.padding-right-151px {
  padding-right: 151px;
}

.padding-right-152px {
  padding-right: 152px;
}

.padding-right-153px {
  padding-right: 153px;
}

.padding-right-154px {
  padding-right: 154px;
}

.padding-right-155px {
  padding-right: 155px;
}

.padding-right-156px {
  padding-right: 156px;
}

.padding-right-157px {
  padding-right: 157px;
}

.padding-right-158px {
  padding-right: 158px;
}

.padding-right-159px {
  padding-right: 159px;
}

.padding-right-160px {
  padding-right: 160px;
}

.padding-right-161px {
  padding-right: 161px;
}

.padding-right-162px {
  padding-right: 162px;
}

.padding-right-163px {
  padding-right: 163px;
}

.padding-right-164px {
  padding-right: 164px;
}

.padding-right-165px {
  padding-right: 165px;
}

.padding-right-166px {
  padding-right: 166px;
}

.padding-right-167px {
  padding-right: 167px;
}

.padding-right-168px {
  padding-right: 168px;
}

.padding-right-169px {
  padding-right: 169px;
}

.padding-right-170px {
  padding-right: 170px;
}

.padding-right-171px {
  padding-right: 171px;
}

.padding-right-172px {
  padding-right: 172px;
}

.padding-right-173px {
  padding-right: 173px;
}

.padding-right-174px {
  padding-right: 174px;
}

.padding-right-175px {
  padding-right: 175px;
}

.padding-right-176px {
  padding-right: 176px;
}

.padding-right-177px {
  padding-right: 177px;
}

.padding-right-178px {
  padding-right: 178px;
}

.padding-right-179px {
  padding-right: 179px;
}

.padding-right-180px {
  padding-right: 180px;
}

.padding-right-181px {
  padding-right: 181px;
}

.padding-right-182px {
  padding-right: 182px;
}

.padding-right-183px {
  padding-right: 183px;
}

.padding-right-184px {
  padding-right: 184px;
}

.padding-right-185px {
  padding-right: 185px;
}

.padding-right-186px {
  padding-right: 186px;
}

.padding-right-187px {
  padding-right: 187px;
}

.padding-right-188px {
  padding-right: 188px;
}

.padding-right-189px {
  padding-right: 189px;
}

.padding-right-190px {
  padding-right: 190px;
}

.padding-right-191px {
  padding-right: 191px;
}

.padding-right-192px {
  padding-right: 192px;
}

.padding-right-193px {
  padding-right: 193px;
}

.padding-right-194px {
  padding-right: 194px;
}

.padding-right-195px {
  padding-right: 195px;
}

.padding-right-196px {
  padding-right: 196px;
}

.padding-right-197px {
  padding-right: 197px;
}

.padding-right-198px {
  padding-right: 198px;
}

.padding-right-199px {
  padding-right: 199px;
}

.padding-right-200px {
  padding-right: 200px;
}

.padding-right-201px {
  padding-right: 201px;
}

.padding-right-202px {
  padding-right: 202px;
}

.padding-right-203px {
  padding-right: 203px;
}

.padding-right-204px {
  padding-right: 204px;
}

.padding-right-205px {
  padding-right: 205px;
}

.padding-right-206px {
  padding-right: 206px;
}

.padding-right-207px {
  padding-right: 207px;
}

.padding-right-208px {
  padding-right: 208px;
}

.padding-right-209px {
  padding-right: 209px;
}

.padding-right-210px {
  padding-right: 210px;
}

.padding-right-211px {
  padding-right: 211px;
}

.padding-right-212px {
  padding-right: 212px;
}

.padding-right-213px {
  padding-right: 213px;
}

.padding-right-214px {
  padding-right: 214px;
}

.padding-right-215px {
  padding-right: 215px;
}

.padding-right-216px {
  padding-right: 216px;
}

.padding-right-217px {
  padding-right: 217px;
}

.padding-right-218px {
  padding-right: 218px;
}

.padding-right-219px {
  padding-right: 219px;
}

.padding-right-220px {
  padding-right: 220px;
}

.padding-right-221px {
  padding-right: 221px;
}

.padding-right-222px {
  padding-right: 222px;
}

.padding-right-223px {
  padding-right: 223px;
}

.padding-right-224px {
  padding-right: 224px;
}

.padding-right-225px {
  padding-right: 225px;
}

.padding-right-226px {
  padding-right: 226px;
}

.padding-right-227px {
  padding-right: 227px;
}

.padding-right-228px {
  padding-right: 228px;
}

.padding-right-229px {
  padding-right: 229px;
}

.padding-right-230px {
  padding-right: 230px;
}

.padding-right-231px {
  padding-right: 231px;
}

.padding-right-232px {
  padding-right: 232px;
}

.padding-right-233px {
  padding-right: 233px;
}

.padding-right-234px {
  padding-right: 234px;
}

.padding-right-235px {
  padding-right: 235px;
}

.padding-right-236px {
  padding-right: 236px;
}

.padding-right-237px {
  padding-right: 237px;
}

.padding-right-238px {
  padding-right: 238px;
}

.padding-right-239px {
  padding-right: 239px;
}

.padding-right-240px {
  padding-right: 240px;
}

.padding-right-241px {
  padding-right: 241px;
}

.padding-right-242px {
  padding-right: 242px;
}

.padding-right-243px {
  padding-right: 243px;
}

.padding-right-244px {
  padding-right: 244px;
}

.padding-right-245px {
  padding-right: 245px;
}

.padding-right-246px {
  padding-right: 246px;
}

.padding-right-247px {
  padding-right: 247px;
}

.padding-right-248px {
  padding-right: 248px;
}

.padding-right-249px {
  padding-right: 249px;
}

.padding-right-250px {
  padding-right: 250px;
}

.padding-right-251px {
  padding-right: 251px;
}

.padding-right-252px {
  padding-right: 252px;
}

.padding-right-253px {
  padding-right: 253px;
}

.padding-right-254px {
  padding-right: 254px;
}

.padding-right-255px {
  padding-right: 255px;
}

.padding-right-256px {
  padding-right: 256px;
}

.padding-right-257px {
  padding-right: 257px;
}

.padding-right-258px {
  padding-right: 258px;
}

.padding-right-259px {
  padding-right: 259px;
}

.padding-right-260px {
  padding-right: 260px;
}

.padding-right-261px {
  padding-right: 261px;
}

.padding-right-262px {
  padding-right: 262px;
}

.padding-right-263px {
  padding-right: 263px;
}

.padding-right-264px {
  padding-right: 264px;
}

.padding-right-265px {
  padding-right: 265px;
}

.padding-right-266px {
  padding-right: 266px;
}

.padding-right-267px {
  padding-right: 267px;
}

.padding-right-268px {
  padding-right: 268px;
}

.padding-right-269px {
  padding-right: 269px;
}

.padding-right-270px {
  padding-right: 270px;
}

.padding-right-271px {
  padding-right: 271px;
}

.padding-right-272px {
  padding-right: 272px;
}

.padding-right-273px {
  padding-right: 273px;
}

.padding-right-274px {
  padding-right: 274px;
}

.padding-right-275px {
  padding-right: 275px;
}

.padding-right-276px {
  padding-right: 276px;
}

.padding-right-277px {
  padding-right: 277px;
}

.padding-right-278px {
  padding-right: 278px;
}

.padding-right-279px {
  padding-right: 279px;
}

.padding-right-280px {
  padding-right: 280px;
}

.padding-right-281px {
  padding-right: 281px;
}

.padding-right-282px {
  padding-right: 282px;
}

.padding-right-283px {
  padding-right: 283px;
}

.padding-right-284px {
  padding-right: 284px;
}

.padding-right-285px {
  padding-right: 285px;
}

.padding-right-286px {
  padding-right: 286px;
}

.padding-right-287px {
  padding-right: 287px;
}

.padding-right-288px {
  padding-right: 288px;
}

.padding-right-289px {
  padding-right: 289px;
}

.padding-right-290px {
  padding-right: 290px;
}

.padding-right-291px {
  padding-right: 291px;
}

.padding-right-292px {
  padding-right: 292px;
}

.padding-right-293px {
  padding-right: 293px;
}

.padding-right-294px {
  padding-right: 294px;
}

.padding-right-295px {
  padding-right: 295px;
}

.padding-right-296px {
  padding-right: 296px;
}

.padding-right-297px {
  padding-right: 297px;
}

.padding-right-298px {
  padding-right: 298px;
}

.padding-right-299px {
  padding-right: 299px;
}

.padding-right-300px {
  padding-right: 300px;
}

/*--- Padding Top N ---*/

.padding-top-n-0 {
  padding-top: 0%;
}

.padding-top-n-1 {
  padding-top: 1%;
}

.padding-top-n-2 {
  padding-top: 2%;
}

.padding-top-n-3 {
  padding-top: 3%;
}

.padding-top-n-4 {
  padding-top: 4%;
}

.padding-top-n-5 {
  padding-top: 5%;
}

.padding-top-n-6 {
  padding-top: 6%;
}

.padding-top-n-7 {
  padding-top: 7%;
}

.padding-top-n-8 {
  padding-top: 8%;
}

.padding-top-n-9 {
  padding-top: 9%;
}

.padding-top-n-10 {
  padding-top: 10%;
}

.padding-top-n-11 {
  padding-top: 11%;
}

.padding-top-n-12 {
  padding-top: 12%;
}

.padding-top-n-13 {
  padding-top: 13%;
}

.padding-top-n-14 {
  padding-top: 14%;
}

.padding-top-n-15 {
  padding-top: 15%;
}

.padding-top-n-16 {
  padding-top: 16%;
}

.padding-top-n-17 {
  padding-top: 17%;
}

.padding-top-n-18 {
  padding-top: 18%;
}

.padding-top-n-19 {
  padding-top: 19%;
}

.padding-top-n-20 {
  padding-top: 20%;
}

.padding-top-n-21 {
  padding-top: 21%;
}

.padding-top-n-22 {
  padding-top: 22%;
}

.padding-top-n-23 {
  padding-top: 23%;
}

.padding-top-n-24 {
  padding-top: 24%;
}

.padding-top-n-25 {
  padding-top: 25%;
}

.padding-top-n-26 {
  padding-top: 26%;
}

.padding-top-n-27 {
  padding-top: 27%;
}

.padding-top-n-28 {
  padding-top: 28%;
}

.padding-top-n-29 {
  padding-top: 29%;
}

.padding-top-n-30 {
  padding-top: 30%;
}

.padding-top-n-31 {
  padding-top: 31%;
}

.padding-top-n-32 {
  padding-top: 32%;
}

.padding-top-n-33 {
  padding-top: 33%;
}

.padding-top-n-34 {
  padding-top: 34%;
}

.padding-top-n-35 {
  padding-top: 35%;
}

.padding-top-n-36 {
  padding-top: 36%;
}

.padding-top-n-37 {
  padding-top: 37%;
}

.padding-top-n-38 {
  padding-top: 38%;
}

.padding-top-n-39 {
  padding-top: 39%;
}

.padding-top-n-40 {
  padding-top: 40%;
}

.padding-top-n-41 {
  padding-top: 41%;
}

.padding-top-n-42 {
  padding-top: 42%;
}

.padding-top-n-43 {
  padding-top: 43%;
}

.padding-top-n-44 {
  padding-top: 44%;
}

.padding-top-n-45 {
  padding-top: 45%;
}

.padding-top-n-46 {
  padding-top: 46%;
}

.padding-top-n-47 {
  padding-top: 47%;
}

.padding-top-n-48 {
  padding-top: 48%;
}

.padding-top-n-49 {
  padding-top: 49%;
}

.padding-top-n-50 {
  padding-top: 50%;
}

.padding-top-n-51 {
  padding-top: 51%;
}

.padding-top-n-52 {
  padding-top: 52%;
}

.padding-top-n-53 {
  padding-top: 53%;
}

.padding-top-n-54 {
  padding-top: 54%;
}

.padding-top-n-55 {
  padding-top: 55%;
}

.padding-top-n-56 {
  padding-top: 56%;
}

.padding-top-n-57 {
  padding-top: 57%;
}

.padding-top-n-58 {
  padding-top: 58%;
}

.padding-top-n-59 {
  padding-top: 59%;
}

.padding-top-n-60 {
  padding-top: 60%;
}

.padding-top-n-61 {
  padding-top: 61%;
}

.padding-top-n-62 {
  padding-top: 62%;
}

.padding-top-n-63 {
  padding-top: 63%;
}

.padding-top-n-64 {
  padding-top: 64%;
}

.padding-top-n-65 {
  padding-top: 65%;
}

.padding-top-n-66 {
  padding-top: 66%;
}

.padding-top-n-67 {
  padding-top: 67%;
}

.padding-top-n-68 {
  padding-top: 68%;
}

.padding-top-n-69 {
  padding-top: 69%;
}

.padding-top-n-70 {
  padding-top: 70%;
}

.padding-top-n-71 {
  padding-top: 71%;
}

.padding-top-n-72 {
  padding-top: 72%;
}

.padding-top-n-73 {
  padding-top: 73%;
}

.padding-top-n-74 {
  padding-top: 74%;
}

.padding-top-n-75 {
  padding-top: 75%;
}

.padding-top-n-76 {
  padding-top: 76%;
}

.padding-top-n-77 {
  padding-top: 77%;
}

.padding-top-n-78 {
  padding-top: 78%;
}

.padding-top-n-79 {
  padding-top: 79%;
}

.padding-top-n-80 {
  padding-top: 80%;
}

.padding-top-n-81 {
  padding-top: 81%;
}

.padding-top-n-82 {
  padding-top: 82%;
}

.padding-top-n-83 {
  padding-top: 83%;
}

.padding-top-n-84 {
  padding-top: 84%;
}

.padding-top-n-85 {
  padding-top: 85%;
}

.padding-top-n-86 {
  padding-top: 86%;
}

.padding-top-n-87 {
  padding-top: 87%;
}

.padding-top-n-88 {
  padding-top: 88%;
}

.padding-top-n-89 {
  padding-top: 89%;
}

.padding-top-n-90 {
  padding-top: 90%;
}

.padding-top-n-91 {
  padding-top: 91%;
}

.padding-top-n-92 {
  padding-top: 92%;
}

.padding-top-n-93 {
  padding-top: 93%;
}

.padding-top-n-94 {
  padding-top: 94%;
}

.padding-top-n-95 {
  padding-top: 95%;
}

.padding-top-n-96 {
  padding-top: 96%;
}

.padding-top-n-97 {
  padding-top: 97%;
}

.padding-top-n-98 {
  padding-top: 98%;
}

.padding-top-n-99 {
  padding-top: 99%;
}

.padding-top-n-100 {
  padding-top: 100%;
}

/*--- Margin top & bottom ---*/

.margin-tb-0px {
  margin-top: 0px;
  margin-bottom: 0px;
}

.margin-tb-1px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.margin-tb-2px {
  margin-top: 2px;
  margin-bottom: 2px;
}

.margin-tb-3px {
  margin-top: 3px;
  margin-bottom: 3px;
}

.margin-tb-4px {
  margin-top: 4px;
  margin-bottom: 4px;
}

.margin-tb-5px {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-tb-6px {
  margin-top: 6px;
  margin-bottom: 6px;
}

.margin-tb-7px {
  margin-top: 7px;
  margin-bottom: 7px;
}

.margin-tb-8px {
  margin-top: 8px;
  margin-bottom: 8px;
}

.margin-tb-9px {
  margin-top: 9px;
  margin-bottom: 9px;
}

.margin-tb-10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-tb-11px {
  margin-top: 11px;
  margin-bottom: 11px;
}

.margin-tb-12px {
  margin-top: 12px;
  margin-bottom: 12px;
}

.margin-tb-13px {
  margin-top: 13px;
  margin-bottom: 13px;
}

.margin-tb-14px {
  margin-top: 14px;
  margin-bottom: 14px;
}

.margin-tb-15px {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin-tb-16px {
  margin-top: 16px;
  margin-bottom: 16px;
}

.margin-tb-17px {
  margin-top: 17px;
  margin-bottom: 17px;
}

.margin-tb-18px {
  margin-top: 18px;
  margin-bottom: 18px;
}

.margin-tb-19px {
  margin-top: 19px;
  margin-bottom: 19px;
}

.margin-tb-20px {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-tb-21px {
  margin-top: 21px;
  margin-bottom: 21px;
}

.margin-tb-22px {
  margin-top: 22px;
  margin-bottom: 22px;
}

.margin-tb-23px {
  margin-top: 23px;
  margin-bottom: 23px;
}

.margin-tb-24px {
  margin-top: 24px;
  margin-bottom: 24px;
}

.margin-tb-25px {
  margin-top: 25px;
  margin-bottom: 25px;
}

.margin-tb-26px {
  margin-top: 26px;
  margin-bottom: 26px;
}

.margin-tb-27px {
  margin-top: 27px;
  margin-bottom: 27px;
}

.margin-tb-28px {
  margin-top: 28px;
  margin-bottom: 28px;
}

.margin-tb-29px {
  margin-top: 29px;
  margin-bottom: 29px;
}

.margin-tb-30px {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-tb-31px {
  margin-top: 31px;
  margin-bottom: 31px;
}

.margin-tb-32px {
  margin-top: 32px;
  margin-bottom: 32px;
}

.margin-tb-33px {
  margin-top: 33px;
  margin-bottom: 33px;
}

.margin-tb-34px {
  margin-top: 34px;
  margin-bottom: 34px;
}

.margin-tb-35px {
  margin-top: 35px;
  margin-bottom: 35px;
}

.margin-tb-36px {
  margin-top: 36px;
  margin-bottom: 36px;
}

.margin-tb-37px {
  margin-top: 37px;
  margin-bottom: 37px;
}

.margin-tb-38px {
  margin-top: 38px;
  margin-bottom: 38px;
}

.margin-tb-39px {
  margin-top: 39px;
  margin-bottom: 39px;
}

.margin-tb-40px {
  margin-top: 40px;
  margin-bottom: 40px;
}

.margin-tb-41px {
  margin-top: 41px;
  margin-bottom: 41px;
}

.margin-tb-42px {
  margin-top: 42px;
  margin-bottom: 42px;
}

.margin-tb-43px {
  margin-top: 43px;
  margin-bottom: 43px;
}

.margin-tb-44px {
  margin-top: 44px;
  margin-bottom: 44px;
}

.margin-tb-45px {
  margin-top: 45px;
  margin-bottom: 45px;
}

.margin-tb-46px {
  margin-top: 46px;
  margin-bottom: 46px;
}

.margin-tb-47px {
  margin-top: 47px;
  margin-bottom: 47px;
}

.margin-tb-48px {
  margin-top: 48px;
  margin-bottom: 48px;
}

.margin-tb-49px {
  margin-top: 49px;
  margin-bottom: 49px;
}

.margin-tb-50px {
  margin-top: 50px;
  margin-bottom: 50px;
}

.margin-tb-51px {
  margin-top: 51px;
  margin-bottom: 51px;
}

.margin-tb-52px {
  margin-top: 52px;
  margin-bottom: 52px;
}

.margin-tb-53px {
  margin-top: 53px;
  margin-bottom: 53px;
}

.margin-tb-54px {
  margin-top: 54px;
  margin-bottom: 54px;
}

.margin-tb-55px {
  margin-top: 55px;
  margin-bottom: 55px;
}

.margin-tb-56px {
  margin-top: 56px;
  margin-bottom: 56px;
}

.margin-tb-57px {
  margin-top: 57px;
  margin-bottom: 57px;
}

.margin-tb-58px {
  margin-top: 58px;
  margin-bottom: 58px;
}

.margin-tb-59px {
  margin-top: 59px;
  margin-bottom: 59px;
}

.margin-tb-60px {
  margin-top: 60px;
  margin-bottom: 60px;
}

.margin-tb-61px {
  margin-top: 61px;
  margin-bottom: 61px;
}

.margin-tb-62px {
  margin-top: 62px;
  margin-bottom: 62px;
}

.margin-tb-63px {
  margin-top: 63px;
  margin-bottom: 63px;
}

.margin-tb-64px {
  margin-top: 64px;
  margin-bottom: 64px;
}

.margin-tb-65px {
  margin-top: 65px;
  margin-bottom: 65px;
}

.margin-tb-66px {
  margin-top: 66px;
  margin-bottom: 66px;
}

.margin-tb-67px {
  margin-top: 67px;
  margin-bottom: 67px;
}

.margin-tb-68px {
  margin-top: 68px;
  margin-bottom: 68px;
}

.margin-tb-69px {
  margin-top: 69px;
  margin-bottom: 69px;
}

.margin-tb-70px {
  margin-top: 70px;
  margin-bottom: 70px;
}

.margin-tb-71px {
  margin-top: 71px;
  margin-bottom: 71px;
}

.margin-tb-72px {
  margin-top: 72px;
  margin-bottom: 72px;
}

.margin-tb-73px {
  margin-top: 73px;
  margin-bottom: 73px;
}

.margin-tb-74px {
  margin-top: 74px;
  margin-bottom: 74px;
}

.margin-tb-75px {
  margin-top: 75px;
  margin-bottom: 75px;
}

.margin-tb-76px {
  margin-top: 76px;
  margin-bottom: 76px;
}

.margin-tb-77px {
  margin-top: 77px;
  margin-bottom: 77px;
}

.margin-tb-78px {
  margin-top: 78px;
  margin-bottom: 78px;
}

.margin-tb-79px {
  margin-top: 79px;
  margin-bottom: 79px;
}

.margin-tb-80px {
  margin-top: 80px;
  margin-bottom: 80px;
}

.margin-tb-81px {
  margin-top: 81px;
  margin-bottom: 81px;
}

.margin-tb-82px {
  margin-top: 82px;
  margin-bottom: 82px;
}

.margin-tb-83px {
  margin-top: 83px;
  margin-bottom: 83px;
}

.margin-tb-84px {
  margin-top: 84px;
  margin-bottom: 84px;
}

.margin-tb-85px {
  margin-top: 85px;
  margin-bottom: 85px;
}

.margin-tb-86px {
  margin-top: 86px;
  margin-bottom: 86px;
}

.margin-tb-87px {
  margin-top: 87px;
  margin-bottom: 87px;
}

.margin-tb-88px {
  margin-top: 88px;
  margin-bottom: 88px;
}

.margin-tb-89px {
  margin-top: 89px;
  margin-bottom: 89px;
}

.margin-tb-90px {
  margin-top: 90px;
  margin-bottom: 90px;
}

.margin-tb-91px {
  margin-top: 91px;
  margin-bottom: 91px;
}

.margin-tb-92px {
  margin-top: 92px;
  margin-bottom: 92px;
}

.margin-tb-93px {
  margin-top: 93px;
  margin-bottom: 93px;
}

.margin-tb-94px {
  margin-top: 94px;
  margin-bottom: 94px;
}

.margin-tb-95px {
  margin-top: 95px;
  margin-bottom: 95px;
}

.margin-tb-96px {
  margin-top: 96px;
  margin-bottom: 96px;
}

.margin-tb-97px {
  margin-top: 97px;
  margin-bottom: 97px;
}

.margin-tb-98px {
  margin-top: 98px;
  margin-bottom: 98px;
}

.margin-tb-99px {
  margin-top: 99px;
  margin-bottom: 99px;
}

.margin-tb-100px {
  margin-top: 100px;
  margin-bottom: 100px;
}

.margin-tb-101px {
  margin-top: 101px;
  margin-bottom: 101px;
}

.margin-tb-102px {
  margin-top: 102px;
  margin-bottom: 102px;
}

.margin-tb-103px {
  margin-top: 103px;
  margin-bottom: 103px;
}

.margin-tb-104px {
  margin-top: 104px;
  margin-bottom: 104px;
}

.margin-tb-105px {
  margin-top: 105px;
  margin-bottom: 105px;
}

.margin-tb-106px {
  margin-top: 106px;
  margin-bottom: 106px;
}

.margin-tb-107px {
  margin-top: 107px;
  margin-bottom: 107px;
}

.margin-tb-108px {
  margin-top: 108px;
  margin-bottom: 108px;
}

.margin-tb-109px {
  margin-top: 109px;
  margin-bottom: 109px;
}

.margin-tb-110px {
  margin-top: 110px;
  margin-bottom: 110px;
}

.margin-tb-111px {
  margin-top: 111px;
  margin-bottom: 111px;
}

.margin-tb-112px {
  margin-top: 112px;
  margin-bottom: 112px;
}

.margin-tb-113px {
  margin-top: 113px;
  margin-bottom: 113px;
}

.margin-tb-114px {
  margin-top: 114px;
  margin-bottom: 114px;
}

.margin-tb-115px {
  margin-top: 115px;
  margin-bottom: 115px;
}

.margin-tb-116px {
  margin-top: 116px;
  margin-bottom: 116px;
}

.margin-tb-117px {
  margin-top: 117px;
  margin-bottom: 117px;
}

.margin-tb-118px {
  margin-top: 118px;
  margin-bottom: 118px;
}

.margin-tb-119px {
  margin-top: 119px;
  margin-bottom: 119px;
}

.margin-tb-120px {
  margin-top: 120px;
  margin-bottom: 120px;
}

.margin-tb-121px {
  margin-top: 121px;
  margin-bottom: 121px;
}

.margin-tb-122px {
  margin-top: 122px;
  margin-bottom: 122px;
}

.margin-tb-123px {
  margin-top: 123px;
  margin-bottom: 123px;
}

.margin-tb-124px {
  margin-top: 124px;
  margin-bottom: 124px;
}

.margin-tb-125px {
  margin-top: 125px;
  margin-bottom: 125px;
}

.margin-tb-126px {
  margin-top: 126px;
  margin-bottom: 126px;
}

.margin-tb-127px {
  margin-top: 127px;
  margin-bottom: 127px;
}

.margin-tb-128px {
  margin-top: 128px;
  margin-bottom: 128px;
}

.margin-tb-129px {
  margin-top: 129px;
  margin-bottom: 129px;
}

.margin-tb-130px {
  margin-top: 130px;
  margin-bottom: 130px;
}

.margin-tb-131px {
  margin-top: 131px;
  margin-bottom: 131px;
}

.margin-tb-132px {
  margin-top: 132px;
  margin-bottom: 132px;
}

.margin-tb-133px {
  margin-top: 133px;
  margin-bottom: 133px;
}

.margin-tb-134px {
  margin-top: 134px;
  margin-bottom: 134px;
}

.margin-tb-135px {
  margin-top: 135px;
  margin-bottom: 135px;
}

.margin-tb-136px {
  margin-top: 136px;
  margin-bottom: 136px;
}

.margin-tb-137px {
  margin-top: 137px;
  margin-bottom: 137px;
}

.margin-tb-138px {
  margin-top: 138px;
  margin-bottom: 138px;
}

.margin-tb-139px {
  margin-top: 139px;
  margin-bottom: 139px;
}

.margin-tb-140px {
  margin-top: 140px;
  margin-bottom: 140px;
}

.margin-tb-141px {
  margin-top: 141px;
  margin-bottom: 141px;
}

.margin-tb-142px {
  margin-top: 142px;
  margin-bottom: 142px;
}

.margin-tb-143px {
  margin-top: 143px;
  margin-bottom: 143px;
}

.margin-tb-144px {
  margin-top: 144px;
  margin-bottom: 144px;
}

.margin-tb-145px {
  margin-top: 145px;
  margin-bottom: 145px;
}

.margin-tb-146px {
  margin-top: 146px;
  margin-bottom: 146px;
}

.margin-tb-147px {
  margin-top: 147px;
  margin-bottom: 147px;
}

.margin-tb-148px {
  margin-top: 148px;
  margin-bottom: 148px;
}

.margin-tb-149px {
  margin-top: 149px;
  margin-bottom: 149px;
}

.margin-tb-150px {
  margin-top: 150px;
  margin-bottom: 150px;
}

.margin-tb-151px {
  margin-top: 151px;
  margin-bottom: 151px;
}

.margin-tb-152px {
  margin-top: 152px;
  margin-bottom: 152px;
}

.margin-tb-153px {
  margin-top: 153px;
  margin-bottom: 153px;
}

.margin-tb-154px {
  margin-top: 154px;
  margin-bottom: 154px;
}

.margin-tb-155px {
  margin-top: 155px;
  margin-bottom: 155px;
}

.margin-tb-156px {
  margin-top: 156px;
  margin-bottom: 156px;
}

.margin-tb-157px {
  margin-top: 157px;
  margin-bottom: 157px;
}

.margin-tb-158px {
  margin-top: 158px;
  margin-bottom: 158px;
}

.margin-tb-159px {
  margin-top: 159px;
  margin-bottom: 159px;
}

.margin-tb-160px {
  margin-top: 160px;
  margin-bottom: 160px;
}

.margin-tb-161px {
  margin-top: 161px;
  margin-bottom: 161px;
}

.margin-tb-162px {
  margin-top: 162px;
  margin-bottom: 162px;
}

.margin-tb-163px {
  margin-top: 163px;
  margin-bottom: 163px;
}

.margin-tb-164px {
  margin-top: 164px;
  margin-bottom: 164px;
}

.margin-tb-165px {
  margin-top: 165px;
  margin-bottom: 165px;
}

.margin-tb-166px {
  margin-top: 166px;
  margin-bottom: 166px;
}

.margin-tb-167px {
  margin-top: 167px;
  margin-bottom: 167px;
}

.margin-tb-168px {
  margin-top: 168px;
  margin-bottom: 168px;
}

.margin-tb-169px {
  margin-top: 169px;
  margin-bottom: 169px;
}

.margin-tb-170px {
  margin-top: 170px;
  margin-bottom: 170px;
}

.margin-tb-171px {
  margin-top: 171px;
  margin-bottom: 171px;
}

.margin-tb-172px {
  margin-top: 172px;
  margin-bottom: 172px;
}

.margin-tb-173px {
  margin-top: 173px;
  margin-bottom: 173px;
}

.margin-tb-174px {
  margin-top: 174px;
  margin-bottom: 174px;
}

.margin-tb-175px {
  margin-top: 175px;
  margin-bottom: 175px;
}

.margin-tb-176px {
  margin-top: 176px;
  margin-bottom: 176px;
}

.margin-tb-177px {
  margin-top: 177px;
  margin-bottom: 177px;
}

.margin-tb-178px {
  margin-top: 178px;
  margin-bottom: 178px;
}

.margin-tb-179px {
  margin-top: 179px;
  margin-bottom: 179px;
}

.margin-tb-180px {
  margin-top: 180px;
  margin-bottom: 180px;
}

.margin-tb-181px {
  margin-top: 181px;
  margin-bottom: 181px;
}

.margin-tb-182px {
  margin-top: 182px;
  margin-bottom: 182px;
}

.margin-tb-183px {
  margin-top: 183px;
  margin-bottom: 183px;
}

.margin-tb-184px {
  margin-top: 184px;
  margin-bottom: 184px;
}

.margin-tb-185px {
  margin-top: 185px;
  margin-bottom: 185px;
}

.margin-tb-186px {
  margin-top: 186px;
  margin-bottom: 186px;
}

.margin-tb-187px {
  margin-top: 187px;
  margin-bottom: 187px;
}

.margin-tb-188px {
  margin-top: 188px;
  margin-bottom: 188px;
}

.margin-tb-189px {
  margin-top: 189px;
  margin-bottom: 189px;
}

.margin-tb-190px {
  margin-top: 190px;
  margin-bottom: 190px;
}

.margin-tb-191px {
  margin-top: 191px;
  margin-bottom: 191px;
}

.margin-tb-192px {
  margin-top: 192px;
  margin-bottom: 192px;
}

.margin-tb-193px {
  margin-top: 193px;
  margin-bottom: 193px;
}

.margin-tb-194px {
  margin-top: 194px;
  margin-bottom: 194px;
}

.margin-tb-195px {
  margin-top: 195px;
  margin-bottom: 195px;
}

.margin-tb-196px {
  margin-top: 196px;
  margin-bottom: 196px;
}

.margin-tb-197px {
  margin-top: 197px;
  margin-bottom: 197px;
}

.margin-tb-198px {
  margin-top: 198px;
  margin-bottom: 198px;
}

.margin-tb-199px {
  margin-top: 199px;
  margin-bottom: 199px;
}

.margin-tb-200px {
  margin-top: 200px;
  margin-bottom: 200px;
}

/*--- Margin top ---*/

.margin-top-0px {
  margin-top: 0px;
}

.margin-top-1px {
  margin-top: 1px;
}

.margin-top-2px {
  margin-top: 2px;
}

.margin-top-3px {
  margin-top: 3px;
}

.margin-top-4px {
  margin-top: 4px;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-top-6px {
  margin-top: 6px;
}

.margin-top-7px {
  margin-top: 7px;
}

.margin-top-8px {
  margin-top: 8px;
}

.margin-top-9px {
  margin-top: 9px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-top-11px {
  margin-top: 11px;
}

.margin-top-12px {
  margin-top: 12px;
}

.margin-top-13px {
  margin-top: 13px;
}

.margin-top-14px {
  margin-top: 14px;
}

.margin-top-15px {
  margin-top: 15px;
}

.margin-top-16px {
  margin-top: 16px;
}

.margin-top-17px {
  margin-top: 17px;
}

.margin-top-18px {
  margin-top: 18px;
}

.margin-top-19px {
  margin-top: 19px;
}

.margin-top-20px {
  margin-top: 20px;
}

.margin-top-21px {
  margin-top: 21px;
}

.margin-top-22px {
  margin-top: 22px;
}

.margin-top-23px {
  margin-top: 23px;
}

.margin-top-24px {
  margin-top: 24px;
}

.margin-top-25px {
  margin-top: 25px;
}

.margin-top-26px {
  margin-top: 26px;
}

.margin-top-27px {
  margin-top: 27px;
}

.margin-top-28px {
  margin-top: 28px;
}

.margin-top-29px {
  margin-top: 29px;
}

.margin-top-30px {
  margin-top: 30px;
}

.margin-top-31px {
  margin-top: 31px;
}

.margin-top-32px {
  margin-top: 32px;
}

.margin-top-33px {
  margin-top: 33px;
}

.margin-top-34px {
  margin-top: 34px;
}

.margin-top-35px {
  margin-top: 35px;
}

.margin-top-36px {
  margin-top: 36px;
}

.margin-top-37px {
  margin-top: 37px;
}

.margin-top-38px {
  margin-top: 38px;
}

.margin-top-39px {
  margin-top: 39px;
}

.margin-top-40px {
  margin-top: 40px;
}

.margin-top-41px {
  margin-top: 41px;
}

.margin-top-42px {
  margin-top: 42px;
}

.margin-top-43px {
  margin-top: 43px;
}

.margin-top-44px {
  margin-top: 44px;
}

.margin-top-45px {
  margin-top: 45px;
}

.margin-top-46px {
  margin-top: 46px;
}

.margin-top-47px {
  margin-top: 47px;
}

.margin-top-48px {
  margin-top: 48px;
}

.margin-top-49px {
  margin-top: 49px;
}

.margin-top-50px {
  margin-top: 50px;
}

.margin-top-51px {
  margin-top: 51px;
}

.margin-top-52px {
  margin-top: 52px;
}

.margin-top-53px {
  margin-top: 53px;
}

.margin-top-54px {
  margin-top: 54px;
}

.margin-top-55px {
  margin-top: 55px;
}

.margin-top-56px {
  margin-top: 56px;
}

.margin-top-57px {
  margin-top: 57px;
}

.margin-top-58px {
  margin-top: 58px;
}

.margin-top-59px {
  margin-top: 59px;
}

.margin-top-60px {
  margin-top: 60px;
}

.margin-top-61px {
  margin-top: 61px;
}

.margin-top-62px {
  margin-top: 62px;
}

.margin-top-63px {
  margin-top: 63px;
}

.margin-top-64px {
  margin-top: 64px;
}

.margin-top-65px {
  margin-top: 65px;
}

.margin-top-66px {
  margin-top: 66px;
}

.margin-top-67px {
  margin-top: 67px;
}

.margin-top-68px {
  margin-top: 68px;
}

.margin-top-69px {
  margin-top: 69px;
}

.margin-top-70px {
  margin-top: 70px;
}

.margin-top-71px {
  margin-top: 71px;
}

.margin-top-72px {
  margin-top: 72px;
}

.margin-top-73px {
  margin-top: 73px;
}

.margin-top-74px {
  margin-top: 74px;
}

.margin-top-75px {
  margin-top: 75px;
}

.margin-top-76px {
  margin-top: 76px;
}

.margin-top-77px {
  margin-top: 77px;
}

.margin-top-78px {
  margin-top: 78px;
}

.margin-top-79px {
  margin-top: 79px;
}

.margin-top-80px {
  margin-top: 80px;
}

.margin-top-81px {
  margin-top: 81px;
}

.margin-top-82px {
  margin-top: 82px;
}

.margin-top-83px {
  margin-top: 83px;
}

.margin-top-84px {
  margin-top: 84px;
}

.margin-top-85px {
  margin-top: 85px;
}

.margin-top-86px {
  margin-top: 86px;
}

.margin-top-87px {
  margin-top: 87px;
}

.margin-top-88px {
  margin-top: 88px;
}

.margin-top-89px {
  margin-top: 89px;
}

.margin-top-90px {
  margin-top: 90px;
}

.margin-top-91px {
  margin-top: 91px;
}

.margin-top-92px {
  margin-top: 92px;
}

.margin-top-93px {
  margin-top: 93px;
}

.margin-top-94px {
  margin-top: 94px;
}

.margin-top-95px {
  margin-top: 95px;
}

.margin-top-96px {
  margin-top: 96px;
}

.margin-top-97px {
  margin-top: 97px;
}

.margin-top-98px {
  margin-top: 98px;
}

.margin-top-99px {
  margin-top: 99px;
}

.margin-top-100px {
  margin-top: 100px;
}

.margin-top-101px {
  margin-top: 101px;
}

.margin-top-102px {
  margin-top: 102px;
}

.margin-top-103px {
  margin-top: 103px;
}

.margin-top-104px {
  margin-top: 104px;
}

.margin-top-105px {
  margin-top: 105px;
}

.margin-top-106px {
  margin-top: 106px;
}

.margin-top-107px {
  margin-top: 107px;
}

.margin-top-108px {
  margin-top: 108px;
}

.margin-top-109px {
  margin-top: 109px;
}

.margin-top-110px {
  margin-top: 110px;
}

.margin-top-111px {
  margin-top: 111px;
}

.margin-top-112px {
  margin-top: 112px;
}

.margin-top-113px {
  margin-top: 113px;
}

.margin-top-114px {
  margin-top: 114px;
}

.margin-top-115px {
  margin-top: 115px;
}

.margin-top-116px {
  margin-top: 116px;
}

.margin-top-117px {
  margin-top: 117px;
}

.margin-top-118px {
  margin-top: 118px;
}

.margin-top-119px {
  margin-top: 119px;
}

.margin-top-120px {
  margin-top: 120px;
}

.margin-top-121px {
  margin-top: 121px;
}

.margin-top-122px {
  margin-top: 122px;
}

.margin-top-123px {
  margin-top: 123px;
}

.margin-top-124px {
  margin-top: 124px;
}

.margin-top-125px {
  margin-top: 125px;
}

.margin-top-126px {
  margin-top: 126px;
}

.margin-top-127px {
  margin-top: 127px;
}

.margin-top-128px {
  margin-top: 128px;
}

.margin-top-129px {
  margin-top: 129px;
}

.margin-top-130px {
  margin-top: 130px;
}

.margin-top-131px {
  margin-top: 131px;
}

.margin-top-132px {
  margin-top: 132px;
}

.margin-top-133px {
  margin-top: 133px;
}

.margin-top-134px {
  margin-top: 134px;
}

.margin-top-135px {
  margin-top: 135px;
}

.margin-top-136px {
  margin-top: 136px;
}

.margin-top-137px {
  margin-top: 137px;
}

.margin-top-138px {
  margin-top: 138px;
}

.margin-top-139px {
  margin-top: 139px;
}

.margin-top-140px {
  margin-top: 140px;
}

.margin-top-141px {
  margin-top: 141px;
}

.margin-top-142px {
  margin-top: 142px;
}

.margin-top-143px {
  margin-top: 143px;
}

.margin-top-144px {
  margin-top: 144px;
}

.margin-top-145px {
  margin-top: 145px;
}

.margin-top-146px {
  margin-top: 146px;
}

.margin-top-147px {
  margin-top: 147px;
}

.margin-top-148px {
  margin-top: 148px;
}

.margin-top-149px {
  margin-top: 149px;
}

.margin-top-150px {
  margin-top: 150px;
}

.margin-top-151px {
  margin-top: 151px;
}

.margin-top-152px {
  margin-top: 152px;
}

.margin-top-153px {
  margin-top: 153px;
}

.margin-top-154px {
  margin-top: 154px;
}

.margin-top-155px {
  margin-top: 155px;
}

.margin-top-156px {
  margin-top: 156px;
}

.margin-top-157px {
  margin-top: 157px;
}

.margin-top-158px {
  margin-top: 158px;
}

.margin-top-159px {
  margin-top: 159px;
}

.margin-top-160px {
  margin-top: 160px;
}

.margin-top-161px {
  margin-top: 161px;
}

.margin-top-162px {
  margin-top: 162px;
}

.margin-top-163px {
  margin-top: 163px;
}

.margin-top-164px {
  margin-top: 164px;
}

.margin-top-165px {
  margin-top: 165px;
}

.margin-top-166px {
  margin-top: 166px;
}

.margin-top-167px {
  margin-top: 167px;
}

.margin-top-168px {
  margin-top: 168px;
}

.margin-top-169px {
  margin-top: 169px;
}

.margin-top-170px {
  margin-top: 170px;
}

.margin-top-171px {
  margin-top: 171px;
}

.margin-top-172px {
  margin-top: 172px;
}

.margin-top-173px {
  margin-top: 173px;
}

.margin-top-174px {
  margin-top: 174px;
}

.margin-top-175px {
  margin-top: 175px;
}

.margin-top-176px {
  margin-top: 176px;
}

.margin-top-177px {
  margin-top: 177px;
}

.margin-top-178px {
  margin-top: 178px;
}

.margin-top-179px {
  margin-top: 179px;
}

.margin-top-180px {
  margin-top: 180px;
}

.margin-top-181px {
  margin-top: 181px;
}

.margin-top-182px {
  margin-top: 182px;
}

.margin-top-183px {
  margin-top: 183px;
}

.margin-top-184px {
  margin-top: 184px;
}

.margin-top-185px {
  margin-top: 185px;
}

.margin-top-186px {
  margin-top: 186px;
}

.margin-top-187px {
  margin-top: 187px;
}

.margin-top-188px {
  margin-top: 188px;
}

.margin-top-189px {
  margin-top: 189px;
}

.margin-top-190px {
  margin-top: 190px;
}

.margin-top-191px {
  margin-top: 191px;
}

.margin-top-192px {
  margin-top: 192px;
}

.margin-top-193px {
  margin-top: 193px;
}

.margin-top-194px {
  margin-top: 194px;
}

.margin-top-195px {
  margin-top: 195px;
}

.margin-top-196px {
  margin-top: 196px;
}

.margin-top-197px {
  margin-top: 197px;
}

.margin-top-198px {
  margin-top: 198px;
}

.margin-top-199px {
  margin-top: 199px;
}

.margin-top-200px {
  margin-top: 200px;
}

/*--- Margin bottom ---*/

.margin-bottom-0px {
  margin-bottom: 0px;
}

.margin-bottom-1px {
  margin-bottom: 1px;
}

.margin-bottom-2px {
  margin-bottom: 2px;
}

.margin-bottom-3px {
  margin-bottom: 3px;
}

.margin-bottom-4px {
  margin-bottom: 4px;
}

.margin-bottom-5px {
  margin-bottom: 5px;
}

.margin-bottom-6px {
  margin-bottom: 6px;
}

.margin-bottom-7px {
  margin-bottom: 7px;
}

.margin-bottom-8px {
  margin-bottom: 8px;
}

.margin-bottom-9px {
  margin-bottom: 9px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-bottom-11px {
  margin-bottom: 11px;
}

.margin-bottom-12px {
  margin-bottom: 12px;
}

.margin-bottom-13px {
  margin-bottom: 13px;
}

.margin-bottom-14px {
  margin-bottom: 14px;
}

.margin-bottom-15px {
  margin-bottom: 15px;
}

.margin-bottom-16px {
  margin-bottom: 16px;
}

.margin-bottom-17px {
  margin-bottom: 17px;
}

.margin-bottom-18px {
  margin-bottom: 18px;
}

.margin-bottom-19px {
  margin-bottom: 19px;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}

.margin-bottom-21px {
  margin-bottom: 21px;
}

.margin-bottom-22px {
  margin-bottom: 22px;
}

.margin-bottom-23px {
  margin-bottom: 23px;
}

.margin-bottom-24px {
  margin-bottom: 24px;
}

.margin-bottom-25px {
  margin-bottom: 25px;
}

.margin-bottom-26px {
  margin-bottom: 26px;
}

.margin-bottom-27px {
  margin-bottom: 27px;
}

.margin-bottom-28px {
  margin-bottom: 28px;
}

.margin-bottom-29px {
  margin-bottom: 29px;
}

.margin-bottom-30px {
  margin-bottom: 30px;
}

.margin-bottom-31px {
  margin-bottom: 31px;
}

.margin-bottom-32px {
  margin-bottom: 32px;
}

.margin-bottom-33px {
  margin-bottom: 33px;
}

.margin-bottom-34px {
  margin-bottom: 34px;
}

.margin-bottom-35px {
  margin-bottom: 35px;
}

.margin-bottom-36px {
  margin-bottom: 36px;
}

.margin-bottom-37px {
  margin-bottom: 37px;
}

.margin-bottom-38px {
  margin-bottom: 38px;
}

.margin-bottom-39px {
  margin-bottom: 39px;
}

.margin-bottom-40px {
  margin-bottom: 40px;
}

.margin-bottom-41px {
  margin-bottom: 41px;
}

.margin-bottom-42px {
  margin-bottom: 42px;
}

.margin-bottom-43px {
  margin-bottom: 43px;
}

.margin-bottom-44px {
  margin-bottom: 44px;
}

.margin-bottom-45px {
  margin-bottom: 45px;
}

.margin-bottom-46px {
  margin-bottom: 46px;
}

.margin-bottom-47px {
  margin-bottom: 47px;
}

.margin-bottom-48px {
  margin-bottom: 48px;
}

.margin-bottom-49px {
  margin-bottom: 49px;
}

.margin-bottom-50px {
  margin-bottom: 50px;
}

.margin-bottom-51px {
  margin-bottom: 51px;
}

.margin-bottom-52px {
  margin-bottom: 52px;
}

.margin-bottom-53px {
  margin-bottom: 53px;
}

.margin-bottom-54px {
  margin-bottom: 54px;
}

.margin-bottom-55px {
  margin-bottom: 55px;
}

.margin-bottom-56px {
  margin-bottom: 56px;
}

.margin-bottom-57px {
  margin-bottom: 57px;
}

.margin-bottom-58px {
  margin-bottom: 58px;
}

.margin-bottom-59px {
  margin-bottom: 59px;
}

.margin-bottom-60px {
  margin-bottom: 60px;
}

.margin-bottom-61px {
  margin-bottom: 61px;
}

.margin-bottom-62px {
  margin-bottom: 62px;
}

.margin-bottom-63px {
  margin-bottom: 63px;
}

.margin-bottom-64px {
  margin-bottom: 64px;
}

.margin-bottom-65px {
  margin-bottom: 65px;
}

.margin-bottom-66px {
  margin-bottom: 66px;
}

.margin-bottom-67px {
  margin-bottom: 67px;
}

.margin-bottom-68px {
  margin-bottom: 68px;
}

.margin-bottom-69px {
  margin-bottom: 69px;
}

.margin-bottom-70px {
  margin-bottom: 70px;
}

.margin-bottom-71px {
  margin-bottom: 71px;
}

.margin-bottom-72px {
  margin-bottom: 72px;
}

.margin-bottom-73px {
  margin-bottom: 73px;
}

.margin-bottom-74px {
  margin-bottom: 74px;
}

.margin-bottom-75px {
  margin-bottom: 75px;
}

.margin-bottom-76px {
  margin-bottom: 76px;
}

.margin-bottom-77px {
  margin-bottom: 77px;
}

.margin-bottom-78px {
  margin-bottom: 78px;
}

.margin-bottom-79px {
  margin-bottom: 79px;
}

.margin-bottom-80px {
  margin-bottom: 80px;
}

.margin-bottom-81px {
  margin-bottom: 81px;
}

.margin-bottom-82px {
  margin-bottom: 82px;
}

.margin-bottom-83px {
  margin-bottom: 83px;
}

.margin-bottom-84px {
  margin-bottom: 84px;
}

.margin-bottom-85px {
  margin-bottom: 85px;
}

.margin-bottom-86px {
  margin-bottom: 86px;
}

.margin-bottom-87px {
  margin-bottom: 87px;
}

.margin-bottom-88px {
  margin-bottom: 88px;
}

.margin-bottom-89px {
  margin-bottom: 89px;
}

.margin-bottom-90px {
  margin-bottom: 90px;
}

.margin-bottom-91px {
  margin-bottom: 91px;
}

.margin-bottom-92px {
  margin-bottom: 92px;
}

.margin-bottom-93px {
  margin-bottom: 93px;
}

.margin-bottom-94px {
  margin-bottom: 94px;
}

.margin-bottom-95px {
  margin-bottom: 95px;
}

.margin-bottom-96px {
  margin-bottom: 96px;
}

.margin-bottom-97px {
  margin-bottom: 97px;
}

.margin-bottom-98px {
  margin-bottom: 98px;
}

.margin-bottom-99px {
  margin-bottom: 99px;
}

.margin-bottom-100px {
  margin-bottom: 100px;
}

/*--- Margin Right & Left ---*/

.margin-lr-0px {
  margin-left: 0px;
  margin-right: 0px;
}

.margin-lr-1px {
  margin-left: 1px;
  margin-right: 1px;
}

.margin-lr-2px {
  margin-left: 2px;
  margin-right: 2px;
}

.margin-lr-3px {
  margin-left: 3px;
  margin-right: 3px;
}

.margin-lr-4px {
  margin-left: 4px;
  margin-right: 4px;
}

.margin-lr-5px {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-lr-6px {
  margin-left: 6px;
  margin-right: 6px;
}

.margin-lr-7px {
  margin-left: 7px;
  margin-right: 7px;
}

.margin-lr-8px {
  margin-left: 8px;
  margin-right: 8px;
}

.margin-lr-9px {
  margin-left: 9px;
  margin-right: 9px;
}

.margin-lr-10px {
  margin-left: 10px;
  margin-right: 10px;
}

.margin-lr-11px {
  margin-left: 11px;
  margin-right: 11px;
}

.margin-lr-12px {
  margin-left: 12px;
  margin-right: 12px;
}

.margin-lr-13px {
  margin-left: 13px;
  margin-right: 13px;
}

.margin-lr-14px {
  margin-left: 14px;
  margin-right: 14px;
}

.margin-lr-15px {
  margin-left: 15px;
  margin-right: 15px;
}

.margin-lr-16px {
  margin-left: 16px;
  margin-right: 16px;
}

.margin-lr-17px {
  margin-left: 17px;
  margin-right: 17px;
}

.margin-lr-18px {
  margin-left: 18px;
  margin-right: 18px;
}

.margin-lr-19px {
  margin-left: 19px;
  margin-right: 19px;
}

.margin-lr-20px {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-lr-21px {
  margin-left: 21px;
  margin-right: 21px;
}

.margin-lr-22px {
  margin-left: 22px;
  margin-right: 22px;
}

.margin-lr-23px {
  margin-left: 23px;
  margin-right: 23px;
}

.margin-lr-24px {
  margin-left: 24px;
  margin-right: 24px;
}

.margin-lr-25px {
  margin-left: 25px;
  margin-right: 25px;
}

.margin-lr-26px {
  margin-left: 26px;
  margin-right: 26px;
}

.margin-lr-27px {
  margin-left: 27px;
  margin-right: 27px;
}

.margin-lr-28px {
  margin-left: 28px;
  margin-right: 28px;
}

.margin-lr-29px {
  margin-left: 29px;
  margin-right: 29px;
}

.margin-lr-30px {
  margin-left: 30px;
  margin-right: 30px;
}

.margin-lr-31px {
  margin-left: 31px;
  margin-right: 31px;
}

.margin-lr-32px {
  margin-left: 32px;
  margin-right: 32px;
}

.margin-lr-33px {
  margin-left: 33px;
  margin-right: 33px;
}

.margin-lr-34px {
  margin-left: 34px;
  margin-right: 34px;
}

.margin-lr-35px {
  margin-left: 35px;
  margin-right: 35px;
}

.margin-lr-36px {
  margin-left: 36px;
  margin-right: 36px;
}

.margin-lr-37px {
  margin-left: 37px;
  margin-right: 37px;
}

.margin-lr-38px {
  margin-left: 38px;
  margin-right: 38px;
}

.margin-lr-39px {
  margin-left: 39px;
  margin-right: 39px;
}

.margin-lr-40px {
  margin-left: 40px;
  margin-right: 40px;
}

.margin-lr-41px {
  margin-left: 41px;
  margin-right: 41px;
}

.margin-lr-42px {
  margin-left: 42px;
  margin-right: 42px;
}

.margin-lr-43px {
  margin-left: 43px;
  margin-right: 43px;
}

.margin-lr-44px {
  margin-left: 44px;
  margin-right: 44px;
}

.margin-lr-45px {
  margin-left: 45px;
  margin-right: 45px;
}

.margin-lr-46px {
  margin-left: 46px;
  margin-right: 46px;
}

.margin-lr-47px {
  margin-left: 47px;
  margin-right: 47px;
}

.margin-lr-48px {
  margin-left: 48px;
  margin-right: 48px;
}

.margin-lr-49px {
  margin-left: 49px;
  margin-right: 49px;
}

.margin-lr-50px {
  margin-left: 50px;
  margin-right: 50px;
}

.margin-lr-51px {
  margin-left: 51px;
  margin-right: 51px;
}

.margin-lr-52px {
  margin-left: 52px;
  margin-right: 52px;
}

.margin-lr-53px {
  margin-left: 53px;
  margin-right: 53px;
}

.margin-lr-54px {
  margin-left: 54px;
  margin-right: 54px;
}

.margin-lr-55px {
  margin-left: 55px;
  margin-right: 55px;
}

.margin-lr-56px {
  margin-left: 56px;
  margin-right: 56px;
}

.margin-lr-57px {
  margin-left: 57px;
  margin-right: 57px;
}

.margin-lr-58px {
  margin-left: 58px;
  margin-right: 58px;
}

.margin-lr-59px {
  margin-left: 59px;
  margin-right: 59px;
}

.margin-lr-60px {
  margin-left: 60px;
  margin-right: 60px;
}

.margin-lr-61px {
  margin-left: 61px;
  margin-right: 61px;
}

.margin-lr-62px {
  margin-left: 62px;
  margin-right: 62px;
}

.margin-lr-63px {
  margin-left: 63px;
  margin-right: 63px;
}

.margin-lr-64px {
  margin-left: 64px;
  margin-right: 64px;
}

.margin-lr-65px {
  margin-left: 65px;
  margin-right: 65px;
}

.margin-lr-66px {
  margin-left: 66px;
  margin-right: 66px;
}

.margin-lr-67px {
  margin-left: 67px;
  margin-right: 67px;
}

.margin-lr-68px {
  margin-left: 68px;
  margin-right: 68px;
}

.margin-lr-69px {
  margin-left: 69px;
  margin-right: 69px;
}

.margin-lr-70px {
  margin-left: 70px;
  margin-right: 70px;
}

.margin-lr-71px {
  margin-left: 71px;
  margin-right: 71px;
}

.margin-lr-72px {
  margin-left: 72px;
  margin-right: 72px;
}

.margin-lr-73px {
  margin-left: 73px;
  margin-right: 73px;
}

.margin-lr-74px {
  margin-left: 74px;
  margin-right: 74px;
}

.margin-lr-75px {
  margin-left: 75px;
  margin-right: 75px;
}

.margin-lr-76px {
  margin-left: 76px;
  margin-right: 76px;
}

.margin-lr-77px {
  margin-left: 77px;
  margin-right: 77px;
}

.margin-lr-78px {
  margin-left: 78px;
  margin-right: 78px;
}

.margin-lr-79px {
  margin-left: 79px;
  margin-right: 79px;
}

.margin-lr-80px {
  margin-left: 80px;
  margin-right: 80px;
}

.margin-lr-81px {
  margin-left: 81px;
  margin-right: 81px;
}

.margin-lr-82px {
  margin-left: 82px;
  margin-right: 82px;
}

.margin-lr-83px {
  margin-left: 83px;
  margin-right: 83px;
}

.margin-lr-84px {
  margin-left: 84px;
  margin-right: 84px;
}

.margin-lr-85px {
  margin-left: 85px;
  margin-right: 85px;
}

.margin-lr-86px {
  margin-left: 86px;
  margin-right: 86px;
}

.margin-lr-87px {
  margin-left: 87px;
  margin-right: 87px;
}

.margin-lr-88px {
  margin-left: 88px;
  margin-right: 88px;
}

.margin-lr-89px {
  margin-left: 89px;
  margin-right: 89px;
}

.margin-lr-90px {
  margin-left: 90px;
  margin-right: 90px;
}

.margin-lr-91px {
  margin-left: 91px;
  margin-right: 91px;
}

.margin-lr-92px {
  margin-left: 92px;
  margin-right: 92px;
}

.margin-lr-93px {
  margin-left: 93px;
  margin-right: 93px;
}

.margin-lr-94px {
  margin-left: 94px;
  margin-right: 94px;
}

.margin-lr-95px {
  margin-left: 95px;
  margin-right: 95px;
}

.margin-lr-96px {
  margin-left: 96px;
  margin-right: 96px;
}

.margin-lr-97px {
  margin-left: 97px;
  margin-right: 97px;
}

.margin-lr-98px {
  margin-left: 98px;
  margin-right: 98px;
}

.margin-lr-99px {
  margin-left: 99px;
  margin-right: 99px;
}

.margin-lr-100px {
  margin-left: 100px;
  margin-right: 100px;
}

/*--- Margin Left ---*/

.margin-left-0px {
  margin-left: 0px;
}

.margin-left-1px {
  margin-left: 1px;
}

.margin-left-2px {
  margin-left: 2px;
}

.margin-left-3px {
  margin-left: 3px;
}

.margin-left-4px {
  margin-left: 4px;
}

.margin-left-5px {
  margin-left: 5px;
}

.margin-left-6px {
  margin-left: 6px;
}

.margin-left-7px {
  margin-left: 7px;
}

.margin-left-8px {
  margin-left: 8px;
}

.margin-left-9px {
  margin-left: 9px;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-left-11px {
  margin-left: 11px;
}

.margin-left-12px {
  margin-left: 12px;
}

.margin-left-13px {
  margin-left: 13px;
}

.margin-left-14px {
  margin-left: 14px;
}

.margin-left-15px {
  margin-left: 15px;
}

.margin-left-16px {
  margin-left: 16px;
}

.margin-left-17px {
  margin-left: 17px;
}

.margin-left-18px {
  margin-left: 18px;
}

.margin-left-19px {
  margin-left: 19px;
}

.margin-left-20px {
  margin-left: 20px;
}

.margin-left-21px {
  margin-left: 21px;
}

.margin-left-22px {
  margin-left: 22px;
}

.margin-left-23px {
  margin-left: 23px;
}

.margin-left-24px {
  margin-left: 24px;
}

.margin-left-25px {
  margin-left: 25px;
}

.margin-left-26px {
  margin-left: 26px;
}

.margin-left-27px {
  margin-left: 27px;
}

.margin-left-28px {
  margin-left: 28px;
}

.margin-left-29px {
  margin-left: 29px;
}

.margin-left-30px {
  margin-left: 30px;
}

.margin-left-31px {
  margin-left: 31px;
}

.margin-left-32px {
  margin-left: 32px;
}

.margin-left-33px {
  margin-left: 33px;
}

.margin-left-34px {
  margin-left: 34px;
}

.margin-left-35px {
  margin-left: 35px;
}

.margin-left-36px {
  margin-left: 36px;
}

.margin-left-37px {
  margin-left: 37px;
}

.margin-left-38px {
  margin-left: 38px;
}

.margin-left-39px {
  margin-left: 39px;
}

.margin-left-40px {
  margin-left: 40px;
}

.margin-left-41px {
  margin-left: 41px;
}

.margin-left-42px {
  margin-left: 42px;
}

.margin-left-43px {
  margin-left: 43px;
}

.margin-left-44px {
  margin-left: 44px;
}

.margin-left-45px {
  margin-left: 45px;
}

.margin-left-46px {
  margin-left: 46px;
}

.margin-left-47px {
  margin-left: 47px;
}

.margin-left-48px {
  margin-left: 48px;
}

.margin-left-49px {
  margin-left: 49px;
}

.margin-left-50px {
  margin-left: 50px;
}

.margin-left-51px {
  margin-left: 51px;
}

.margin-left-52px {
  margin-left: 52px;
}

.margin-left-53px {
  margin-left: 53px;
}

.margin-left-54px {
  margin-left: 54px;
}

.margin-left-55px {
  margin-left: 55px;
}

.margin-left-56px {
  margin-left: 56px;
}

.margin-left-57px {
  margin-left: 57px;
}

.margin-left-58px {
  margin-left: 58px;
}

.margin-left-59px {
  margin-left: 59px;
}

.margin-left-60px {
  margin-left: 60px;
}

.margin-left-61px {
  margin-left: 61px;
}

.margin-left-62px {
  margin-left: 62px;
}

.margin-left-63px {
  margin-left: 63px;
}

.margin-left-64px {
  margin-left: 64px;
}

.margin-left-65px {
  margin-left: 65px;
}

.margin-left-66px {
  margin-left: 66px;
}

.margin-left-67px {
  margin-left: 67px;
}

.margin-left-68px {
  margin-left: 68px;
}

.margin-left-69px {
  margin-left: 69px;
}

.margin-left-70px {
  margin-left: 70px;
}

.margin-left-71px {
  margin-left: 71px;
}

.margin-left-72px {
  margin-left: 72px;
}

.margin-left-73px {
  margin-left: 73px;
}

.margin-left-74px {
  margin-left: 74px;
}

.margin-left-75px {
  margin-left: 75px;
}

.margin-left-76px {
  margin-left: 76px;
}

.margin-left-77px {
  margin-left: 77px;
}

.margin-left-78px {
  margin-left: 78px;
}

.margin-left-79px {
  margin-left: 79px;
}

.margin-left-80px {
  margin-left: 80px;
}

.margin-left-81px {
  margin-left: 81px;
}

.margin-left-82px {
  margin-left: 82px;
}

.margin-left-83px {
  margin-left: 83px;
}

.margin-left-84px {
  margin-left: 84px;
}

.margin-left-85px {
  margin-left: 85px;
}

.margin-left-86px {
  margin-left: 86px;
}

.margin-left-87px {
  margin-left: 87px;
}

.margin-left-88px {
  margin-left: 88px;
}

.margin-left-89px {
  margin-left: 89px;
}

.margin-left-90px {
  margin-left: 90px;
}

.margin-left-91px {
  margin-left: 91px;
}

.margin-left-92px {
  margin-left: 92px;
}

.margin-left-93px {
  margin-left: 93px;
}

.margin-left-94px {
  margin-left: 94px;
}

.margin-left-95px {
  margin-left: 95px;
}

.margin-left-96px {
  margin-left: 96px;
}

.margin-left-97px {
  margin-left: 97px;
}

.margin-left-98px {
  margin-left: 98px;
}

.margin-left-99px {
  margin-left: 99px;
}

.margin-left-100px {
  margin-left: 100px;
}

/*--- Margin right ---*/

.margin-right-0px {
  margin-right: 0px;
}

.margin-right-1px {
  margin-right: 1px;
}

.margin-right-2px {
  margin-right: 2px;
}

.margin-right-3px {
  margin-right: 3px;
}

.margin-right-4px {
  margin-right: 4px;
}

.margin-right-5px {
  margin-right: 5px;
}

.margin-right-6px {
  margin-right: 6px;
}

.margin-right-7px {
  margin-right: 7px;
}

.margin-right-8px {
  margin-right: 8px;
}

.margin-right-9px {
  margin-right: 9px;
}

.margin-right-10px {
  margin-right: 10px;
}

.margin-right-11px {
  margin-right: 11px;
}

.margin-right-12px {
  margin-right: 12px;
}

.margin-right-13px {
  margin-right: 13px;
}

.margin-right-14px {
  margin-right: 14px;
}

.margin-right-15px {
  margin-right: 15px;
}

.margin-right-16px {
  margin-right: 16px;
}

.margin-right-17px {
  margin-right: 17px;
}

.margin-right-18px {
  margin-right: 18px;
}

.margin-right-19px {
  margin-right: 19px;
}

.margin-right-20px {
  margin-right: 20px;
}

.margin-right-21px {
  margin-right: 21px;
}

.margin-right-22px {
  margin-right: 22px;
}

.margin-right-23px {
  margin-right: 23px;
}

.margin-right-24px {
  margin-right: 24px;
}

.margin-right-25px {
  margin-right: 25px;
}

.margin-right-26px {
  margin-right: 26px;
}

.margin-right-27px {
  margin-right: 27px;
}

.margin-right-28px {
  margin-right: 28px;
}

.margin-right-29px {
  margin-right: 29px;
}

.margin-right-30px {
  margin-right: 30px;
}

.margin-right-31px {
  margin-right: 31px;
}

.margin-right-32px {
  margin-right: 32px;
}

.margin-right-33px {
  margin-right: 33px;
}

.margin-right-34px {
  margin-right: 34px;
}

.margin-right-35px {
  margin-right: 35px;
}

.margin-right-36px {
  margin-right: 36px;
}

.margin-right-37px {
  margin-right: 37px;
}

.margin-right-38px {
  margin-right: 38px;
}

.margin-right-39px {
  margin-right: 39px;
}

.margin-right-40px {
  margin-right: 40px;
}

.margin-right-41px {
  margin-right: 41px;
}

.margin-right-42px {
  margin-right: 42px;
}

.margin-right-43px {
  margin-right: 43px;
}

.margin-right-44px {
  margin-right: 44px;
}

.margin-right-45px {
  margin-right: 45px;
}

.margin-right-46px {
  margin-right: 46px;
}

.margin-right-47px {
  margin-right: 47px;
}

.margin-right-48px {
  margin-right: 48px;
}

.margin-right-49px {
  margin-right: 49px;
}

.margin-right-50px {
  margin-right: 50px;
}

.margin-right-51px {
  margin-right: 51px;
}

.margin-right-52px {
  margin-right: 52px;
}

.margin-right-53px {
  margin-right: 53px;
}

.margin-right-54px {
  margin-right: 54px;
}

.margin-right-55px {
  margin-right: 55px;
}

.margin-right-56px {
  margin-right: 56px;
}

.margin-right-57px {
  margin-right: 57px;
}

.margin-right-58px {
  margin-right: 58px;
}

.margin-right-59px {
  margin-right: 59px;
}

.margin-right-60px {
  margin-right: 60px;
}

.margin-right-61px {
  margin-right: 61px;
}

.margin-right-62px {
  margin-right: 62px;
}

.margin-right-63px {
  margin-right: 63px;
}

.margin-right-64px {
  margin-right: 64px;
}

.margin-right-65px {
  margin-right: 65px;
}

.margin-right-66px {
  margin-right: 66px;
}

.margin-right-67px {
  margin-right: 67px;
}

.margin-right-68px {
  margin-right: 68px;
}

.margin-right-69px {
  margin-right: 69px;
}

.margin-right-70px {
  margin-right: 70px;
}

.margin-right-71px {
  margin-right: 71px;
}

.margin-right-72px {
  margin-right: 72px;
}

.margin-right-73px {
  margin-right: 73px;
}

.margin-right-74px {
  margin-right: 74px;
}

.margin-right-75px {
  margin-right: 75px;
}

.margin-right-76px {
  margin-right: 76px;
}

.margin-right-77px {
  margin-right: 77px;
}

.margin-right-78px {
  margin-right: 78px;
}

.margin-right-79px {
  margin-right: 79px;
}

.margin-right-80px {
  margin-right: 80px;
}

.margin-right-81px {
  margin-right: 81px;
}

.margin-right-82px {
  margin-right: 82px;
}

.margin-right-83px {
  margin-right: 83px;
}

.margin-right-84px {
  margin-right: 84px;
}

.margin-right-85px {
  margin-right: 85px;
}

.margin-right-86px {
  margin-right: 86px;
}

.margin-right-87px {
  margin-right: 87px;
}

.margin-right-88px {
  margin-right: 88px;
}

.margin-right-89px {
  margin-right: 89px;
}

.margin-right-90px {
  margin-right: 90px;
}

.margin-right-91px {
  margin-right: 91px;
}

.margin-right-92px {
  margin-right: 92px;
}

.margin-right-93px {
  margin-right: 93px;
}

.margin-right-94px {
  margin-right: 94px;
}

.margin-right-95px {
  margin-right: 95px;
}

.margin-right-96px {
  margin-right: 96px;
}

.margin-right-97px {
  margin-right: 97px;
}

.margin-right-98px {
  margin-right: 98px;
}

.margin-right-99px {
  margin-right: 99px;
}

.margin-right-100px {
  margin-right: 100px;
}

/*--- Margin all ---*/

.margin-0px {
  margin: 0px;
}

.margin-1px {
  margin: 1px;
}

.margin-2px {
  margin: 2px;
}

.margin-3px {
  margin: 3px;
}

.margin-4px {
  margin: 4px;
}

.margin-5px {
  margin: 5px;
}

.margin-6px {
  margin: 6px;
}

.margin-7px {
  margin: 7px;
}

.margin-8px {
  margin: 8px;
}

.margin-9px {
  margin: 9px;
}

.margin-10px {
  margin: 10px;
}

.margin-11px {
  margin: 11px;
}

.margin-12px {
  margin: 12px;
}

.margin-13px {
  margin: 13px;
}

.margin-14px {
  margin: 14px;
}

.margin-15px {
  margin: 15px;
}

.margin-16px {
  margin: 16px;
}

.margin-17px {
  margin: 17px;
}

.margin-18px {
  margin: 18px;
}

.margin-19px {
  margin: 19px;
}

.margin-20px {
  margin: 20px;
}

.margin-21px {
  margin: 21px;
}

.margin-22px {
  margin: 22px;
}

.margin-23px {
  margin: 23px;
}

.margin-24px {
  margin: 24px;
}

.margin-25px {
  margin: 25px;
}

.margin-26px {
  margin: 26px;
}

.margin-27px {
  margin: 27px;
}

.margin-28px {
  margin: 28px;
}

.margin-29px {
  margin: 29px;
}

.margin-30px {
  margin: 30px;
}

.margin-31px {
  margin: 31px;
}

.margin-32px {
  margin: 32px;
}

.margin-33px {
  margin: 33px;
}

.margin-34px {
  margin: 34px;
}

.margin-35px {
  margin: 35px;
}

.margin-36px {
  margin: 36px;
}

.margin-37px {
  margin: 37px;
}

.margin-38px {
  margin: 38px;
}

.margin-39px {
  margin: 39px;
}

.margin-40px {
  margin: 40px;
}

.margin-41px {
  margin: 41px;
}

.margin-42px {
  margin: 42px;
}

.margin-43px {
  margin: 43px;
}

.margin-44px {
  margin: 44px;
}

.margin-45px {
  margin: 45px;
}

.margin-46px {
  margin: 46px;
}

.margin-47px {
  margin: 47px;
}

.margin-48px {
  margin: 48px;
}

.margin-49px {
  margin: 49px;
}

.margin-50px {
  margin: 50px;
}

.margin-51px {
  margin: 51px;
}

.margin-52px {
  margin: 52px;
}

.margin-53px {
  margin: 53px;
}

.margin-54px {
  margin: 54px;
}

.margin-55px {
  margin: 55px;
}

.margin-56px {
  margin: 56px;
}

.margin-57px {
  margin: 57px;
}

.margin-58px {
  margin: 58px;
}

.margin-59px {
  margin: 59px;
}

.margin-60px {
  margin: 60px;
}

.margin-61px {
  margin: 61px;
}

.margin-62px {
  margin: 62px;
}

.margin-63px {
  margin: 63px;
}

.margin-64px {
  margin: 64px;
}

.margin-65px {
  margin: 65px;
}

.margin-66px {
  margin: 66px;
}

.margin-67px {
  margin: 67px;
}

.margin-68px {
  margin: 68px;
}

.margin-69px {
  margin: 69px;
}

.margin-70px {
  margin: 70px;
}

.margin-71px {
  margin: 71px;
}

.margin-72px {
  margin: 72px;
}

.margin-73px {
  margin: 73px;
}

.margin-74px {
  margin: 74px;
}

.margin-75px {
  margin: 75px;
}

.margin-76px {
  margin: 76px;
}

.margin-77px {
  margin: 77px;
}

.margin-78px {
  margin: 78px;
}

.margin-79px {
  margin: 79px;
}

.margin-80px {
  margin: 80px;
}

.margin-81px {
  margin: 81px;
}

.margin-82px {
  margin: 82px;
}

.margin-83px {
  margin: 83px;
}

.margin-84px {
  margin: 84px;
}

.margin-85px {
  margin: 85px;
}

.margin-86px {
  margin: 86px;
}

.margin-87px {
  margin: 87px;
}

.margin-88px {
  margin: 88px;
}

.margin-89px {
  margin: 89px;
}

.margin-90px {
  margin: 90px;
}

.margin-91px {
  margin: 91px;
}

.margin-92px {
  margin: 92px;
}

.margin-93px {
  margin: 93px;
}

.margin-94px {
  margin: 94px;
}

.margin-95px {
  margin: 95px;
}

.margin-96px {
  margin: 96px;
}

.margin-97px {
  margin: 97px;
}

.margin-98px {
  margin: 98px;
}

.margin-99px {
  margin: 99px;
}

.margin-100px {
  margin: 100px;
}

/*-- Border Top ---*/

.border-top-0 {
  border-top: solid 0px;
}

.border-top-1 {
  border-top: solid 1px;
}

.border-top-2 {
  border-top: solid 2px;
}

.border-top-3 {
  border-top: solid 3px;
}

.border-top-4 {
  border-top: solid 4px;
}

.border-top-5 {
  border-top: solid 5px;
}

.border-top-6 {
  border-top: solid 6px;
}

.border-top-7 {
  border-top: solid 7px;
}

.border-top-8 {
  border-top: solid 8px;
}

.border-top-9 {
  border-top: solid 9px;
}

.border-top-10 {
  border-top: solid 10px;
}

/*-- Border Bottom ---*/

.border-bottom-0 {
  border-bottom: solid 0px;
}

.border-bottom-1 {
  border-bottom: solid 1px;
}

.border-bottom-2 {
  border-bottom: solid 2px;
}

.border-bottom-3 {
  border-bottom: solid 3px;
}

.border-bottom-4 {
  border-bottom: solid 4px;
}

.border-bottom-5 {
  border-bottom: solid 5px;
}

.border-bottom-6 {
  border-bottom: solid 6px;
}

.border-bottom-7 {
  border-bottom: solid 7px;
}

.border-bottom-8 {
  border-bottom: solid 8px;
}

.border-bottom-9 {
  border-bottom: solid 9px;
}

.border-bottom-10 {
  border-bottom: solid 10px;
}

/*-- Border Right ---*/

.border-right-0 {
  border-right: solid 0px;
}

.border-right-1 {
  border-right: solid 1px;
}

.border-right-2 {
  border-right: solid 2px;
}

.border-right-3 {
  border-right: solid 3px;
}

.border-right-4 {
  border-right: solid 4px;
}

.border-right-5 {
  border-right: solid 5px;
}

.border-right-6 {
  border-right: solid 6px;
}

.border-right-7 {
  border-right: solid 7px;
}

.border-right-8 {
  border-right: solid 8px;
}

.border-right-9 {
  border-right: solid 9px;
}

.border-right-10 {
  border-right: solid 10px;
}

/*-- Border Left ---*/

.border-left-0 {
  border-left: solid 0px;
}

.border-left-1 {
  border-left: solid 1px;
}

.border-left-2 {
  border-left: solid 2px;
}

.border-left-3 {
  border-left: solid 3px;
}

.border-left-4 {
  border-left: solid 4px;
}

.border-left-5 {
  border-left: solid 5px;
}

.border-left-6 {
  border-left: solid 6px;
}

.border-left-7 {
  border-left: solid 7px;
}

.border-left-8 {
  border-left: solid 8px;
}

.border-left-9 {
  border-left: solid 9px;
}

.border-left-10 {
  border-left: solid 10px;
}

/*-- Border All ---*/

.border-0 {
  border: solid 0px;
}

.border-1 {
  border: solid 1px;
}

.border-2 {
  border: solid 2px;
}

.border-3 {
  border: solid 3px;
}

.border-4 {
  border: solid 4px;
}

.border-5 {
  border: solid 5px;
}

.border-6 {
  border: solid 6px;
}

.border-7 {
  border: solid 7px;
}

.border-8 {
  border: solid 8px;
}

.border-9 {
  border: solid 9px;
}

.border-10 {
  border: solid 10px;
}

/*--- Borders Colors ---*/

.border-main-color {
  border-color: #1db8c1 !important;
}

.border-second-color {
  border-color: #fdbe19 !important;
}

.border-third-color {
  border-color: #f44336 !important;
}

.border-red {
  border-color: #e91e63 !important;
}

.border-pink {
  border-color: #f44336 !important;
}

.border-purple {
  border-color: #9c27b0 !important;
}

.border-blue {
  border-color: #2196f3 !important;
}

.border-light-green {
  border-color: #8bc34a !important;
}

.border-lime {
  border-color: #cddc39 !important;
}

.border-yellow {
  border-color: #ffeb3b !important;
}

.border-amber {
  border-color: #ffc107 !important;
}

.border-orange {
  border-color: #ff9800 !important;
}

.border-light-grey {
  border-color: #fafafa !important;
}

.border-grey {
  border-color: #e0e0e0 !important;
}

.border-grey-1 {
  border-color: #eeeded !important;
}

.border-grey-2 {
  border-color: #9e9e9e !important;
}

.border-grey-3 {
  border-color: #616161 !important;
}

.border-grey-4 {
  border-color: #424242 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-black {
  border-color: #000 !important;
}

.border-dark {
  border-color: #212121 !important;
}

/*-- Border Radius ---*/

.border-radius-0 {
  border-radius: 0px;
}

.border-radius-1 {
  border-radius: 1px;
}

.border-radius-2 {
  border-radius: 2px;
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-6 {
  border-radius: 6px;
}

.border-radius-7 {
  border-radius: 7px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-9 {
  border-radius: 9px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-11 {
  border-radius: 11px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-13 {
  border-radius: 13px;
}

.border-radius-14 {
  border-radius: 14px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-radius-17 {
  border-radius: 17px;
}

.border-radius-18 {
  border-radius: 18px;
}

.border-radius-19 {
  border-radius: 19px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-21 {
  border-radius: 21px;
}

.border-radius-22 {
  border-radius: 22px;
}

.border-radius-23 {
  border-radius: 23px;
}

.border-radius-24 {
  border-radius: 24px;
}

.border-radius-25 {
  border-radius: 25px;
}

.border-radius-26 {
  border-radius: 26px;
}

.border-radius-27 {
  border-radius: 27px;
}

.border-radius-28 {
  border-radius: 28px;
}

.border-radius-29 {
  border-radius: 29px;
}

.border-radius-30 {
  border-radius: 30px;
}

.border-radius-31 {
  border-radius: 31px;
}

.border-radius-32 {
  border-radius: 32px;
}

.border-radius-33 {
  border-radius: 33px;
}

.border-radius-34 {
  border-radius: 34px;
}

.border-radius-35 {
  border-radius: 35px;
}

.border-radius-36 {
  border-radius: 36px;
}

.border-radius-37 {
  border-radius: 37px;
}

.border-radius-38 {
  border-radius: 38px;
}

.border-radius-39 {
  border-radius: 39px;
}

.border-radius-40 {
  border-radius: 40px;
}

.border-radius-41 {
  border-radius: 41px;
}

.border-radius-42 {
  border-radius: 42px;
}

.border-radius-43 {
  border-radius: 43px;
}

.border-radius-44 {
  border-radius: 44px;
}

.border-radius-45 {
  border-radius: 45px;
}

.border-radius-46 {
  border-radius: 46px;
}

.border-radius-47 {
  border-radius: 47px;
}

.border-radius-48 {
  border-radius: 48px;
}

.border-radius-49 {
  border-radius: 49px;
}

.border-radius-50 {
  border-radius: 50px;
}

.border-radius-51 {
  border-radius: 51px;
}

.border-radius-52 {
  border-radius: 52px;
}

.border-radius-53 {
  border-radius: 53px;
}

.border-radius-54 {
  border-radius: 54px;
}

.border-radius-55 {
  border-radius: 55px;
}

.border-radius-56 {
  border-radius: 56px;
}

.border-radius-57 {
  border-radius: 57px;
}

.border-radius-58 {
  border-radius: 58px;
}

.border-radius-59 {
  border-radius: 59px;
}

.border-radius-60 {
  border-radius: 60px;
}

.border-radius-61 {
  border-radius: 61px;
}

.border-radius-62 {
  border-radius: 62px;
}

.border-radius-63 {
  border-radius: 63px;
}

.border-radius-64 {
  border-radius: 64px;
}

.border-radius-65 {
  border-radius: 65px;
}

.border-radius-66 {
  border-radius: 66px;
}

.border-radius-67 {
  border-radius: 67px;
}

.border-radius-68 {
  border-radius: 68px;
}

.border-radius-69 {
  border-radius: 69px;
}

.border-radius-70 {
  border-radius: 70px;
}

.border-radius-71 {
  border-radius: 71px;
}

.border-radius-72 {
  border-radius: 72px;
}

.border-radius-73 {
  border-radius: 73px;
}

.border-radius-74 {
  border-radius: 74px;
}

.border-radius-75 {
  border-radius: 75px;
}

.border-radius-76 {
  border-radius: 76px;
}

.border-radius-77 {
  border-radius: 77px;
}

.border-radius-78 {
  border-radius: 78px;
}

.border-radius-79 {
  border-radius: 79px;
}

.border-radius-80 {
  border-radius: 80px;
}

.border-radius-81 {
  border-radius: 81px;
}

.border-radius-82 {
  border-radius: 82px;
}

.border-radius-83 {
  border-radius: 83px;
}

.border-radius-84 {
  border-radius: 84px;
}

.border-radius-85 {
  border-radius: 85px;
}

.border-radius-86 {
  border-radius: 86px;
}

.border-radius-87 {
  border-radius: 87px;
}

.border-radius-88 {
  border-radius: 88px;
}

.border-radius-89 {
  border-radius: 89px;
}

.border-radius-90 {
  border-radius: 90px;
}

.border-radius-91 {
  border-radius: 91px;
}

.border-radius-92 {
  border-radius: 92px;
}

.border-radius-93 {
  border-radius: 93px;
}

.border-radius-94 {
  border-radius: 94px;
}

.border-radius-95 {
  border-radius: 95px;
}

.border-radius-96 {
  border-radius: 96px;
}

.border-radius-97 {
  border-radius: 97px;
}

.border-radius-98 {
  border-radius: 98px;
}

.border-radius-99 {
  border-radius: 99px;
}

.border-radius-100 {
  border-radius: 100px;
}

.border-radius-101 {
  border-radius: 101px;
}

.border-radius-102 {
  border-radius: 102px;
}

.border-radius-103 {
  border-radius: 103px;
}

.border-radius-104 {
  border-radius: 104px;
}

.border-radius-105 {
  border-radius: 105px;
}

.border-radius-106 {
  border-radius: 106px;
}

.border-radius-107 {
  border-radius: 107px;
}

.border-radius-108 {
  border-radius: 108px;
}

.border-radius-109 {
  border-radius: 109px;
}

.border-radius-110 {
  border-radius: 110px;
}

.border-radius-111 {
  border-radius: 111px;
}

.border-radius-112 {
  border-radius: 112px;
}

.border-radius-113 {
  border-radius: 113px;
}

.border-radius-114 {
  border-radius: 114px;
}

.border-radius-115 {
  border-radius: 115px;
}

.border-radius-116 {
  border-radius: 116px;
}

.border-radius-117 {
  border-radius: 117px;
}

.border-radius-118 {
  border-radius: 118px;
}

.border-radius-119 {
  border-radius: 119px;
}

.border-radius-120 {
  border-radius: 120px;
}

.border-radius-121 {
  border-radius: 121px;
}

.border-radius-122 {
  border-radius: 122px;
}

.border-radius-123 {
  border-radius: 123px;
}

.border-radius-124 {
  border-radius: 124px;
}

.border-radius-125 {
  border-radius: 125px;
}

.border-radius-126 {
  border-radius: 126px;
}

.border-radius-127 {
  border-radius: 127px;
}

.border-radius-128 {
  border-radius: 128px;
}

.border-radius-129 {
  border-radius: 129px;
}

.border-radius-130 {
  border-radius: 130px;
}

.border-radius-131 {
  border-radius: 131px;
}

.border-radius-132 {
  border-radius: 132px;
}

.border-radius-133 {
  border-radius: 133px;
}

.border-radius-134 {
  border-radius: 134px;
}

.border-radius-135 {
  border-radius: 135px;
}

.border-radius-136 {
  border-radius: 136px;
}

.border-radius-137 {
  border-radius: 137px;
}

.border-radius-138 {
  border-radius: 138px;
}

.border-radius-139 {
  border-radius: 139px;
}

.border-radius-140 {
  border-radius: 140px;
}

.border-radius-141 {
  border-radius: 141px;
}

.border-radius-142 {
  border-radius: 142px;
}

.border-radius-143 {
  border-radius: 143px;
}

.border-radius-144 {
  border-radius: 144px;
}

.border-radius-145 {
  border-radius: 145px;
}

.border-radius-146 {
  border-radius: 146px;
}

.border-radius-147 {
  border-radius: 147px;
}

.border-radius-148 {
  border-radius: 148px;
}

.border-radius-149 {
  border-radius: 149px;
}

.border-radius-150 {
  border-radius: 150px;
}

.border-radius-151 {
  border-radius: 151px;
}

.border-radius-152 {
  border-radius: 152px;
}

.border-radius-153 {
  border-radius: 153px;
}

.border-radius-154 {
  border-radius: 154px;
}

.border-radius-155 {
  border-radius: 155px;
}

.border-radius-156 {
  border-radius: 156px;
}

.border-radius-157 {
  border-radius: 157px;
}

.border-radius-158 {
  border-radius: 158px;
}

.border-radius-159 {
  border-radius: 159px;
}

.border-radius-160 {
  border-radius: 160px;
}

.border-radius-161 {
  border-radius: 161px;
}

.border-radius-162 {
  border-radius: 162px;
}

.border-radius-163 {
  border-radius: 163px;
}

.border-radius-164 {
  border-radius: 164px;
}

.border-radius-165 {
  border-radius: 165px;
}

.border-radius-166 {
  border-radius: 166px;
}

.border-radius-167 {
  border-radius: 167px;
}

.border-radius-168 {
  border-radius: 168px;
}

.border-radius-169 {
  border-radius: 169px;
}

.border-radius-170 {
  border-radius: 170px;
}

.border-radius-171 {
  border-radius: 171px;
}

.border-radius-172 {
  border-radius: 172px;
}

.border-radius-173 {
  border-radius: 173px;
}

.border-radius-174 {
  border-radius: 174px;
}

.border-radius-175 {
  border-radius: 175px;
}

.border-radius-176 {
  border-radius: 176px;
}

.border-radius-177 {
  border-radius: 177px;
}

.border-radius-178 {
  border-radius: 178px;
}

.border-radius-179 {
  border-radius: 179px;
}

.border-radius-180 {
  border-radius: 180px;
}

.border-radius-181 {
  border-radius: 181px;
}

.border-radius-182 {
  border-radius: 182px;
}

.border-radius-183 {
  border-radius: 183px;
}

.border-radius-184 {
  border-radius: 184px;
}

.border-radius-185 {
  border-radius: 185px;
}

.border-radius-186 {
  border-radius: 186px;
}

.border-radius-187 {
  border-radius: 187px;
}

.border-radius-188 {
  border-radius: 188px;
}

.border-radius-189 {
  border-radius: 189px;
}

.border-radius-190 {
  border-radius: 190px;
}

.border-radius-191 {
  border-radius: 191px;
}

.border-radius-192 {
  border-radius: 192px;
}

.border-radius-193 {
  border-radius: 193px;
}

.border-radius-194 {
  border-radius: 194px;
}

.border-radius-195 {
  border-radius: 195px;
}

.border-radius-196 {
  border-radius: 196px;
}

.border-radius-197 {
  border-radius: 197px;
}

.border-radius-198 {
  border-radius: 198px;
}

.border-radius-199 {
  border-radius: 199px;
}

.border-radius-200 {
  border-radius: 200px;
}

.border-radius-201 {
  border-radius: 201px;
}

.border-radius-202 {
  border-radius: 202px;
}

.border-radius-203 {
  border-radius: 203px;
}

.border-radius-204 {
  border-radius: 204px;
}

.border-radius-205 {
  border-radius: 205px;
}

.border-radius-206 {
  border-radius: 206px;
}

.border-radius-207 {
  border-radius: 207px;
}

.border-radius-208 {
  border-radius: 208px;
}

.border-radius-209 {
  border-radius: 209px;
}

.border-radius-210 {
  border-radius: 210px;
}

.border-radius-211 {
  border-radius: 211px;
}

.border-radius-212 {
  border-radius: 212px;
}

.border-radius-213 {
  border-radius: 213px;
}

.border-radius-214 {
  border-radius: 214px;
}

.border-radius-215 {
  border-radius: 215px;
}

.border-radius-216 {
  border-radius: 216px;
}

.border-radius-217 {
  border-radius: 217px;
}

.border-radius-218 {
  border-radius: 218px;
}

.border-radius-219 {
  border-radius: 219px;
}

.border-radius-220 {
  border-radius: 220px;
}

.border-radius-221 {
  border-radius: 221px;
}

.border-radius-222 {
  border-radius: 222px;
}

.border-radius-223 {
  border-radius: 223px;
}

.border-radius-224 {
  border-radius: 224px;
}

.border-radius-225 {
  border-radius: 225px;
}

.border-radius-226 {
  border-radius: 226px;
}

.border-radius-227 {
  border-radius: 227px;
}

.border-radius-228 {
  border-radius: 228px;
}

.border-radius-229 {
  border-radius: 229px;
}

.border-radius-230 {
  border-radius: 230px;
}

.border-radius-231 {
  border-radius: 231px;
}

.border-radius-232 {
  border-radius: 232px;
}

.border-radius-233 {
  border-radius: 233px;
}

.border-radius-234 {
  border-radius: 234px;
}

.border-radius-235 {
  border-radius: 235px;
}

.border-radius-236 {
  border-radius: 236px;
}

.border-radius-237 {
  border-radius: 237px;
}

.border-radius-238 {
  border-radius: 238px;
}

.border-radius-239 {
  border-radius: 239px;
}

.border-radius-240 {
  border-radius: 240px;
}

.border-radius-241 {
  border-radius: 241px;
}

.border-radius-242 {
  border-radius: 242px;
}

.border-radius-243 {
  border-radius: 243px;
}

.border-radius-244 {
  border-radius: 244px;
}

.border-radius-245 {
  border-radius: 245px;
}

.border-radius-246 {
  border-radius: 246px;
}

.border-radius-247 {
  border-radius: 247px;
}

.border-radius-248 {
  border-radius: 248px;
}

.border-radius-249 {
  border-radius: 249px;
}

.border-radius-250 {
  border-radius: 250px;
}

.border-radius-251 {
  border-radius: 251px;
}

.border-radius-252 {
  border-radius: 252px;
}

.border-radius-253 {
  border-radius: 253px;
}

.border-radius-254 {
  border-radius: 254px;
}

.border-radius-255 {
  border-radius: 255px;
}

.border-radius-256 {
  border-radius: 256px;
}

.border-radius-257 {
  border-radius: 257px;
}

.border-radius-258 {
  border-radius: 258px;
}

.border-radius-259 {
  border-radius: 259px;
}

.border-radius-260 {
  border-radius: 260px;
}

.border-radius-261 {
  border-radius: 261px;
}

.border-radius-262 {
  border-radius: 262px;
}

.border-radius-263 {
  border-radius: 263px;
}

.border-radius-264 {
  border-radius: 264px;
}

.border-radius-265 {
  border-radius: 265px;
}

.border-radius-266 {
  border-radius: 266px;
}

.border-radius-267 {
  border-radius: 267px;
}

.border-radius-268 {
  border-radius: 268px;
}

.border-radius-269 {
  border-radius: 269px;
}

.border-radius-270 {
  border-radius: 270px;
}

.border-radius-271 {
  border-radius: 271px;
}

.border-radius-272 {
  border-radius: 272px;
}

.border-radius-273 {
  border-radius: 273px;
}

.border-radius-274 {
  border-radius: 274px;
}

.border-radius-275 {
  border-radius: 275px;
}

.border-radius-276 {
  border-radius: 276px;
}

.border-radius-277 {
  border-radius: 277px;
}

.border-radius-278 {
  border-radius: 278px;
}

.border-radius-279 {
  border-radius: 279px;
}

.border-radius-280 {
  border-radius: 280px;
}

.border-radius-281 {
  border-radius: 281px;
}

.border-radius-282 {
  border-radius: 282px;
}

.border-radius-283 {
  border-radius: 283px;
}

.border-radius-284 {
  border-radius: 284px;
}

.border-radius-285 {
  border-radius: 285px;
}

.border-radius-286 {
  border-radius: 286px;
}

.border-radius-287 {
  border-radius: 287px;
}

.border-radius-288 {
  border-radius: 288px;
}

.border-radius-289 {
  border-radius: 289px;
}

.border-radius-290 {
  border-radius: 290px;
}

.border-radius-291 {
  border-radius: 291px;
}

.border-radius-292 {
  border-radius: 292px;
}

.border-radius-293 {
  border-radius: 293px;
}

.border-radius-294 {
  border-radius: 294px;
}

.border-radius-295 {
  border-radius: 295px;
}

.border-radius-296 {
  border-radius: 296px;
}

.border-radius-297 {
  border-radius: 297px;
}

.border-radius-298 {
  border-radius: 298px;
}

.border-radius-299 {
  border-radius: 299px;
}

.border-radius-300 {
  border-radius: 300px;
}

.border-radius-301 {
  border-radius: 301px;
}

.border-radius-302 {
  border-radius: 302px;
}

.border-radius-303 {
  border-radius: 303px;
}

.border-radius-304 {
  border-radius: 304px;
}

.border-radius-305 {
  border-radius: 305px;
}

.border-radius-306 {
  border-radius: 306px;
}

.border-radius-307 {
  border-radius: 307px;
}

.border-radius-308 {
  border-radius: 308px;
}

.border-radius-309 {
  border-radius: 309px;
}

.border-radius-310 {
  border-radius: 310px;
}

.border-radius-311 {
  border-radius: 311px;
}

.border-radius-312 {
  border-radius: 312px;
}

.border-radius-313 {
  border-radius: 313px;
}

.border-radius-314 {
  border-radius: 314px;
}

.border-radius-315 {
  border-radius: 315px;
}

.border-radius-316 {
  border-radius: 316px;
}

.border-radius-317 {
  border-radius: 317px;
}

.border-radius-318 {
  border-radius: 318px;
}

.border-radius-319 {
  border-radius: 319px;
}

.border-radius-320 {
  border-radius: 320px;
}

.border-radius-321 {
  border-radius: 321px;
}

.border-radius-322 {
  border-radius: 322px;
}

.border-radius-323 {
  border-radius: 323px;
}

.border-radius-324 {
  border-radius: 324px;
}

.border-radius-325 {
  border-radius: 325px;
}

.border-radius-326 {
  border-radius: 326px;
}

.border-radius-327 {
  border-radius: 327px;
}

.border-radius-328 {
  border-radius: 328px;
}

.border-radius-329 {
  border-radius: 329px;
}

.border-radius-330 {
  border-radius: 330px;
}

.border-radius-331 {
  border-radius: 331px;
}

.border-radius-332 {
  border-radius: 332px;
}

.border-radius-333 {
  border-radius: 333px;
}

.border-radius-334 {
  border-radius: 334px;
}

.border-radius-335 {
  border-radius: 335px;
}

.border-radius-336 {
  border-radius: 336px;
}

.border-radius-337 {
  border-radius: 337px;
}

.border-radius-338 {
  border-radius: 338px;
}

.border-radius-339 {
  border-radius: 339px;
}

.border-radius-340 {
  border-radius: 340px;
}

.border-radius-341 {
  border-radius: 341px;
}

.border-radius-342 {
  border-radius: 342px;
}

.border-radius-343 {
  border-radius: 343px;
}

.border-radius-344 {
  border-radius: 344px;
}

.border-radius-345 {
  border-radius: 345px;
}

.border-radius-346 {
  border-radius: 346px;
}

.border-radius-347 {
  border-radius: 347px;
}

.border-radius-348 {
  border-radius: 348px;
}

.border-radius-349 {
  border-radius: 349px;
}

.border-radius-350 {
  border-radius: 350px;
}

.border-radius-351 {
  border-radius: 351px;
}

.border-radius-352 {
  border-radius: 352px;
}

.border-radius-353 {
  border-radius: 353px;
}

.border-radius-354 {
  border-radius: 354px;
}

.border-radius-355 {
  border-radius: 355px;
}

.border-radius-356 {
  border-radius: 356px;
}

.border-radius-357 {
  border-radius: 357px;
}

.border-radius-358 {
  border-radius: 358px;
}

.border-radius-359 {
  border-radius: 359px;
}

.border-radius-360 {
  border-radius: 360px;
}

.border-radius-361 {
  border-radius: 361px;
}

.border-radius-362 {
  border-radius: 362px;
}

.border-radius-363 {
  border-radius: 363px;
}

.border-radius-364 {
  border-radius: 364px;
}

.border-radius-365 {
  border-radius: 365px;
}

.border-radius-366 {
  border-radius: 366px;
}

.border-radius-367 {
  border-radius: 367px;
}

.border-radius-368 {
  border-radius: 368px;
}

.border-radius-369 {
  border-radius: 369px;
}

.border-radius-370 {
  border-radius: 370px;
}

.border-radius-371 {
  border-radius: 371px;
}

.border-radius-372 {
  border-radius: 372px;
}

.border-radius-373 {
  border-radius: 373px;
}

.border-radius-374 {
  border-radius: 374px;
}

.border-radius-375 {
  border-radius: 375px;
}

.border-radius-376 {
  border-radius: 376px;
}

.border-radius-377 {
  border-radius: 377px;
}

.border-radius-378 {
  border-radius: 378px;
}

.border-radius-379 {
  border-radius: 379px;
}

.border-radius-380 {
  border-radius: 380px;
}

.border-radius-381 {
  border-radius: 381px;
}

.border-radius-382 {
  border-radius: 382px;
}

.border-radius-383 {
  border-radius: 383px;
}

.border-radius-384 {
  border-radius: 384px;
}

.border-radius-385 {
  border-radius: 385px;
}

.border-radius-386 {
  border-radius: 386px;
}

.border-radius-387 {
  border-radius: 387px;
}

.border-radius-388 {
  border-radius: 388px;
}

.border-radius-389 {
  border-radius: 389px;
}

.border-radius-390 {
  border-radius: 390px;
}

.border-radius-391 {
  border-radius: 391px;
}

.border-radius-392 {
  border-radius: 392px;
}

.border-radius-393 {
  border-radius: 393px;
}

.border-radius-394 {
  border-radius: 394px;
}

.border-radius-395 {
  border-radius: 395px;
}

.border-radius-396 {
  border-radius: 396px;
}

.border-radius-397 {
  border-radius: 397px;
}

.border-radius-398 {
  border-radius: 398px;
}

.border-radius-399 {
  border-radius: 399px;
}

.border-radius-400 {
  border-radius: 400px;
}

/*-- Box ---*/

.box-0px {
  width: 0px;
  height: 0px;
}

.box-1px {
  width: 1px;
  height: 1px;
}

.box-2px {
  width: 2px;
  height: 2px;
}

.box-3px {
  width: 3px;
  height: 3px;
}

.box-4px {
  width: 4px;
  height: 4px;
}

.box-5px {
  width: 5px;
  height: 5px;
}

.box-6px {
  width: 6px;
  height: 6px;
}

.box-7px {
  width: 7px;
  height: 7px;
}

.box-8px {
  width: 8px;
  height: 8px;
}

.box-9px {
  width: 9px;
  height: 9px;
}

.box-10px {
  width: 10px;
  height: 10px;
}

.box-11px {
  width: 11px;
  height: 11px;
}

.box-12px {
  width: 12px;
  height: 12px;
}

.box-13px {
  width: 13px;
  height: 13px;
}

.box-14px {
  width: 14px;
  height: 14px;
}

.box-15px {
  width: 15px;
  height: 15px;
}

.box-16px {
  width: 16px;
  height: 16px;
}

.box-17px {
  width: 17px;
  height: 17px;
}

.box-18px {
  width: 18px;
  height: 18px;
}

.box-19px {
  width: 19px;
  height: 19px;
}

.box-20px {
  width: 20px;
  height: 20px;
}

.box-21px {
  width: 21px;
  height: 21px;
}

.box-22px {
  width: 22px;
  height: 22px;
}

.box-23px {
  width: 23px;
  height: 23px;
}

.box-24px {
  width: 24px;
  height: 24px;
}

.box-25px {
  width: 25px;
  height: 25px;
}

.box-26px {
  width: 26px;
  height: 26px;
}

.box-27px {
  width: 27px;
  height: 27px;
}

.box-28px {
  width: 28px;
  height: 28px;
}

.box-29px {
  width: 29px;
  height: 29px;
}

.box-30px {
  width: 30px;
  height: 30px;
}

.box-31px {
  width: 31px;
  height: 31px;
}

.box-32px {
  width: 32px;
  height: 32px;
}

.box-33px {
  width: 33px;
  height: 33px;
}

.box-34px {
  width: 34px;
  height: 34px;
}

.box-35px {
  width: 35px;
  height: 35px;
}

.box-36px {
  width: 36px;
  height: 36px;
}

.box-37px {
  width: 37px;
  height: 37px;
}

.box-38px {
  width: 38px;
  height: 38px;
}

.box-39px {
  width: 39px;
  height: 39px;
}

.box-40px {
  width: 40px;
  height: 40px;
}

.box-41px {
  width: 41px;
  height: 41px;
}

.box-42px {
  width: 42px;
  height: 42px;
}

.box-43px {
  width: 43px;
  height: 43px;
}

.box-44px {
  width: 44px;
  height: 44px;
}

.box-45px {
  width: 45px;
  height: 45px;
}

.box-46px {
  width: 46px;
  height: 46px;
}

.box-47px {
  width: 47px;
  height: 47px;
}

.box-48px {
  width: 48px;
  height: 48px;
}

.box-49px {
  width: 49px;
  height: 49px;
}

.box-50px {
  width: 50px;
  height: 50px;
}

.box-51px {
  width: 51px;
  height: 51px;
}

.box-52px {
  width: 52px;
  height: 52px;
}

.box-53px {
  width: 53px;
  height: 53px;
}

.box-54px {
  width: 54px;
  height: 54px;
}

.box-55px {
  width: 55px;
  height: 55px;
}

.box-56px {
  width: 56px;
  height: 56px;
}

.box-57px {
  width: 57px;
  height: 57px;
}

.box-58px {
  width: 58px;
  height: 58px;
}

.box-59px {
  width: 59px;
  height: 59px;
}

.box-60px {
  width: 60px;
  height: 60px;
}

.box-61px {
  width: 61px;
  height: 61px;
}

.box-62px {
  width: 62px;
  height: 62px;
}

.box-63px {
  width: 63px;
  height: 63px;
}

.box-64px {
  width: 64px;
  height: 64px;
}

.box-65px {
  width: 65px;
  height: 65px;
}

.box-66px {
  width: 66px;
  height: 66px;
}

.box-67px {
  width: 67px;
  height: 67px;
}

.box-68px {
  width: 68px;
  height: 68px;
}

.box-69px {
  width: 69px;
  height: 69px;
}

.box-70px {
  width: 70px;
  height: 70px;
}

.box-71px {
  width: 71px;
  height: 71px;
}

.box-72px {
  width: 72px;
  height: 72px;
}

.box-73px {
  width: 73px;
  height: 73px;
}

.box-74px {
  width: 74px;
  height: 74px;
}

.box-75px {
  width: 75px;
  height: 75px;
}

.box-76px {
  width: 76px;
  height: 76px;
}

.box-77px {
  width: 77px;
  height: 77px;
}

.box-78px {
  width: 78px;
  height: 78px;
}

.box-79px {
  width: 79px;
  height: 79px;
}

.box-80px {
  width: 80px;
  height: 80px;
}

.box-81px {
  width: 81px;
  height: 81px;
}

.box-82px {
  width: 82px;
  height: 82px;
}

.box-83px {
  width: 83px;
  height: 83px;
}

.box-84px {
  width: 84px;
  height: 84px;
}

.box-85px {
  width: 85px;
  height: 85px;
}

.box-86px {
  width: 86px;
  height: 86px;
}

.box-87px {
  width: 87px;
  height: 87px;
}

.box-88px {
  width: 88px;
  height: 88px;
}

.box-89px {
  width: 89px;
  height: 89px;
}

.box-90px {
  width: 90px;
  height: 90px;
}

.box-91px {
  width: 91px;
  height: 91px;
}

.box-92px {
  width: 92px;
  height: 92px;
}

.box-93px {
  width: 93px;
  height: 93px;
}

.box-94px {
  width: 94px;
  height: 94px;
}

.box-95px {
  width: 95px;
  height: 95px;
}

.box-96px {
  width: 96px;
  height: 96px;
}

.box-97px {
  width: 97px;
  height: 97px;
}

.box-98px {
  width: 98px;
  height: 98px;
}

.box-99px {
  width: 99px;
  height: 99px;
}

.box-100px {
  width: 100px;
  height: 100px;
}

.box-101px {
  width: 101px;
  height: 101px;
}

.box-102px {
  width: 102px;
  height: 102px;
}

.box-103px {
  width: 103px;
  height: 103px;
}

.box-104px {
  width: 104px;
  height: 104px;
}

.box-105px {
  width: 105px;
  height: 105px;
}

.box-106px {
  width: 106px;
  height: 106px;
}

.box-107px {
  width: 107px;
  height: 107px;
}

.box-108px {
  width: 108px;
  height: 108px;
}

.box-109px {
  width: 109px;
  height: 109px;
}

.box-110px {
  width: 110px;
  height: 110px;
}

.box-111px {
  width: 111px;
  height: 111px;
}

.box-112px {
  width: 112px;
  height: 112px;
}

.box-113px {
  width: 113px;
  height: 113px;
}

.box-114px {
  width: 114px;
  height: 114px;
}

.box-115px {
  width: 115px;
  height: 115px;
}

.box-116px {
  width: 116px;
  height: 116px;
}

.box-117px {
  width: 117px;
  height: 117px;
}

.box-118px {
  width: 118px;
  height: 118px;
}

.box-119px {
  width: 119px;
  height: 119px;
}

.box-120px {
  width: 120px;
  height: 120px;
}

.box-121px {
  width: 121px;
  height: 121px;
}

.box-122px {
  width: 122px;
  height: 122px;
}

.box-123px {
  width: 123px;
  height: 123px;
}

.box-124px {
  width: 124px;
  height: 124px;
}

.box-125px {
  width: 125px;
  height: 125px;
}

.box-126px {
  width: 126px;
  height: 126px;
}

.box-127px {
  width: 127px;
  height: 127px;
}

.box-128px {
  width: 128px;
  height: 128px;
}

.box-129px {
  width: 129px;
  height: 129px;
}

.box-130px {
  width: 130px;
  height: 130px;
}

.box-131px {
  width: 131px;
  height: 131px;
}

.box-132px {
  width: 132px;
  height: 132px;
}

.box-133px {
  width: 133px;
  height: 133px;
}

.box-134px {
  width: 134px;
  height: 134px;
}

.box-135px {
  width: 135px;
  height: 135px;
}

.box-136px {
  width: 136px;
  height: 136px;
}

.box-137px {
  width: 137px;
  height: 137px;
}

.box-138px {
  width: 138px;
  height: 138px;
}

.box-139px {
  width: 139px;
  height: 139px;
}

.box-140px {
  width: 140px;
  height: 140px;
}

.box-141px {
  width: 141px;
  height: 141px;
}

.box-142px {
  width: 142px;
  height: 142px;
}

.box-143px {
  width: 143px;
  height: 143px;
}

.box-144px {
  width: 144px;
  height: 144px;
}

.box-145px {
  width: 145px;
  height: 145px;
}

.box-146px {
  width: 146px;
  height: 146px;
}

.box-147px {
  width: 147px;
  height: 147px;
}

.box-148px {
  width: 148px;
  height: 148px;
}

.box-149px {
  width: 149px;
  height: 149px;
}

.box-150px {
  width: 150px;
  height: 150px;
}

.box-151px {
  width: 151px;
  height: 151px;
}

.box-152px {
  width: 152px;
  height: 152px;
}

.box-153px {
  width: 153px;
  height: 153px;
}

.box-154px {
  width: 154px;
  height: 154px;
}

.box-155px {
  width: 155px;
  height: 155px;
}

.box-156px {
  width: 156px;
  height: 156px;
}

.box-157px {
  width: 157px;
  height: 157px;
}

.box-158px {
  width: 158px;
  height: 158px;
}

.box-159px {
  width: 159px;
  height: 159px;
}

.box-160px {
  width: 160px;
  height: 160px;
}

.box-161px {
  width: 161px;
  height: 161px;
}

.box-162px {
  width: 162px;
  height: 162px;
}

.box-163px {
  width: 163px;
  height: 163px;
}

.box-164px {
  width: 164px;
  height: 164px;
}

.box-165px {
  width: 165px;
  height: 165px;
}

.box-166px {
  width: 166px;
  height: 166px;
}

.box-167px {
  width: 167px;
  height: 167px;
}

.box-168px {
  width: 168px;
  height: 168px;
}

.box-169px {
  width: 169px;
  height: 169px;
}

.box-170px {
  width: 170px;
  height: 170px;
}

.box-171px {
  width: 171px;
  height: 171px;
}

.box-172px {
  width: 172px;
  height: 172px;
}

.box-173px {
  width: 173px;
  height: 173px;
}

.box-174px {
  width: 174px;
  height: 174px;
}

.box-175px {
  width: 175px;
  height: 175px;
}

.box-176px {
  width: 176px;
  height: 176px;
}

.box-177px {
  width: 177px;
  height: 177px;
}

.box-178px {
  width: 178px;
  height: 178px;
}

.box-179px {
  width: 179px;
  height: 179px;
}

.box-180px {
  width: 180px;
  height: 180px;
}

.box-181px {
  width: 181px;
  height: 181px;
}

.box-182px {
  width: 182px;
  height: 182px;
}

.box-183px {
  width: 183px;
  height: 183px;
}

.box-184px {
  width: 184px;
  height: 184px;
}

.box-185px {
  width: 185px;
  height: 185px;
}

.box-186px {
  width: 186px;
  height: 186px;
}

.box-187px {
  width: 187px;
  height: 187px;
}

.box-188px {
  width: 188px;
  height: 188px;
}

.box-189px {
  width: 189px;
  height: 189px;
}

.box-190px {
  width: 190px;
  height: 190px;
}

.box-191px {
  width: 191px;
  height: 191px;
}

.box-192px {
  width: 192px;
  height: 192px;
}

.box-193px {
  width: 193px;
  height: 193px;
}

.box-194px {
  width: 194px;
  height: 194px;
}

.box-195px {
  width: 195px;
  height: 195px;
}

.box-196px {
  width: 196px;
  height: 196px;
}

.box-197px {
  width: 197px;
  height: 197px;
}

.box-198px {
  width: 198px;
  height: 198px;
}

.box-199px {
  width: 199px;
  height: 199px;
}

.box-200px {
  width: 200px;
  height: 200px;
}

.box-201px {
  width: 201px;
  height: 201px;
}

.box-202px {
  width: 202px;
  height: 202px;
}

.box-203px {
  width: 203px;
  height: 203px;
}

.box-204px {
  width: 204px;
  height: 204px;
}

.box-205px {
  width: 205px;
  height: 205px;
}

.box-206px {
  width: 206px;
  height: 206px;
}

.box-207px {
  width: 207px;
  height: 207px;
}

.box-208px {
  width: 208px;
  height: 208px;
}

.box-209px {
  width: 209px;
  height: 209px;
}

.box-210px {
  width: 210px;
  height: 210px;
}

.box-211px {
  width: 211px;
  height: 211px;
}

.box-212px {
  width: 212px;
  height: 212px;
}

.box-213px {
  width: 213px;
  height: 213px;
}

.box-214px {
  width: 214px;
  height: 214px;
}

.box-215px {
  width: 215px;
  height: 215px;
}

.box-216px {
  width: 216px;
  height: 216px;
}

.box-217px {
  width: 217px;
  height: 217px;
}

.box-218px {
  width: 218px;
  height: 218px;
}

.box-219px {
  width: 219px;
  height: 219px;
}

.box-220px {
  width: 220px;
  height: 220px;
}

.box-221px {
  width: 221px;
  height: 221px;
}

.box-222px {
  width: 222px;
  height: 222px;
}

.box-223px {
  width: 223px;
  height: 223px;
}

.box-224px {
  width: 224px;
  height: 224px;
}

.box-225px {
  width: 225px;
  height: 225px;
}

.box-226px {
  width: 226px;
  height: 226px;
}

.box-227px {
  width: 227px;
  height: 227px;
}

.box-228px {
  width: 228px;
  height: 228px;
}

.box-229px {
  width: 229px;
  height: 229px;
}

.box-230px {
  width: 230px;
  height: 230px;
}

.box-231px {
  width: 231px;
  height: 231px;
}

.box-232px {
  width: 232px;
  height: 232px;
}

.box-233px {
  width: 233px;
  height: 233px;
}

.box-234px {
  width: 234px;
  height: 234px;
}

.box-235px {
  width: 235px;
  height: 235px;
}

.box-236px {
  width: 236px;
  height: 236px;
}

.box-237px {
  width: 237px;
  height: 237px;
}

.box-238px {
  width: 238px;
  height: 238px;
}

.box-239px {
  width: 239px;
  height: 239px;
}

.box-240px {
  width: 240px;
  height: 240px;
}

.box-241px {
  width: 241px;
  height: 241px;
}

.box-242px {
  width: 242px;
  height: 242px;
}

.box-243px {
  width: 243px;
  height: 243px;
}

.box-244px {
  width: 244px;
  height: 244px;
}

.box-245px {
  width: 245px;
  height: 245px;
}

.box-246px {
  width: 246px;
  height: 246px;
}

.box-247px {
  width: 247px;
  height: 247px;
}

.box-248px {
  width: 248px;
  height: 248px;
}

.box-249px {
  width: 249px;
  height: 249px;
}

.box-250px {
  width: 250px;
  height: 250px;
}

.box-251px {
  width: 251px;
  height: 251px;
}

.box-252px {
  width: 252px;
  height: 252px;
}

.box-253px {
  width: 253px;
  height: 253px;
}

.box-254px {
  width: 254px;
  height: 254px;
}

.box-255px {
  width: 255px;
  height: 255px;
}

.box-256px {
  width: 256px;
  height: 256px;
}

.box-257px {
  width: 257px;
  height: 257px;
}

.box-258px {
  width: 258px;
  height: 258px;
}

.box-259px {
  width: 259px;
  height: 259px;
}

.box-260px {
  width: 260px;
  height: 260px;
}

.box-261px {
  width: 261px;
  height: 261px;
}

.box-262px {
  width: 262px;
  height: 262px;
}

.box-263px {
  width: 263px;
  height: 263px;
}

.box-264px {
  width: 264px;
  height: 264px;
}

.box-265px {
  width: 265px;
  height: 265px;
}

.box-266px {
  width: 266px;
  height: 266px;
}

.box-267px {
  width: 267px;
  height: 267px;
}

.box-268px {
  width: 268px;
  height: 268px;
}

.box-269px {
  width: 269px;
  height: 269px;
}

.box-270px {
  width: 270px;
  height: 270px;
}

.box-271px {
  width: 271px;
  height: 271px;
}

.box-272px {
  width: 272px;
  height: 272px;
}

.box-273px {
  width: 273px;
  height: 273px;
}

.box-274px {
  width: 274px;
  height: 274px;
}

.box-275px {
  width: 275px;
  height: 275px;
}

.box-276px {
  width: 276px;
  height: 276px;
}

.box-277px {
  width: 277px;
  height: 277px;
}

.box-278px {
  width: 278px;
  height: 278px;
}

.box-279px {
  width: 279px;
  height: 279px;
}

.box-280px {
  width: 280px;
  height: 280px;
}

.box-281px {
  width: 281px;
  height: 281px;
}

.box-282px {
  width: 282px;
  height: 282px;
}

.box-283px {
  width: 283px;
  height: 283px;
}

.box-284px {
  width: 284px;
  height: 284px;
}

.box-285px {
  width: 285px;
  height: 285px;
}

.box-286px {
  width: 286px;
  height: 286px;
}

.box-287px {
  width: 287px;
  height: 287px;
}

.box-288px {
  width: 288px;
  height: 288px;
}

.box-289px {
  width: 289px;
  height: 289px;
}

.box-290px {
  width: 290px;
  height: 290px;
}

.box-291px {
  width: 291px;
  height: 291px;
}

.box-292px {
  width: 292px;
  height: 292px;
}

.box-293px {
  width: 293px;
  height: 293px;
}

.box-294px {
  width: 294px;
  height: 294px;
}

.box-295px {
  width: 295px;
  height: 295px;
}

.box-296px {
  width: 296px;
  height: 296px;
}

.box-297px {
  width: 297px;
  height: 297px;
}

.box-298px {
  width: 298px;
  height: 298px;
}

.box-299px {
  width: 299px;
  height: 299px;
}

.box-300px {
  width: 300px;
  height: 300px;
}

/*-- Width ---*/

.width-0px {
  width: 0px;
}

.width-1px {
  width: 1px;
}

.width-2px {
  width: 2px;
}

.width-3px {
  width: 3px;
}

.width-4px {
  width: 4px;
}

.width-5px {
  width: 5px;
}

.width-6px {
  width: 6px;
}

.width-7px {
  width: 7px;
}

.width-8px {
  width: 8px;
}

.width-9px {
  width: 9px;
}

.width-10px {
  width: 10px;
}

.width-11px {
  width: 11px;
}

.width-12px {
  width: 12px;
}

.width-13px {
  width: 13px;
}

.width-14px {
  width: 14px;
}

.width-15px {
  width: 15px;
}

.width-16px {
  width: 16px;
}

.width-17px {
  width: 17px;
}

.width-18px {
  width: 18px;
}

.width-19px {
  width: 19px;
}

.width-20px {
  width: 20px;
}

.width-21px {
  width: 21px;
}

.width-22px {
  width: 22px;
}

.width-23px {
  width: 23px;
}

.width-24px {
  width: 24px;
}

.width-25px {
  width: 25px;
}

.width-26px {
  width: 26px;
}

.width-27px {
  width: 27px;
}

.width-28px {
  width: 28px;
}

.width-29px {
  width: 29px;
}

.width-30px {
  width: 30px;
}

.width-31px {
  width: 31px;
}

.width-32px {
  width: 32px;
}

.width-33px {
  width: 33px;
}

.width-34px {
  width: 34px;
}

.width-35px {
  width: 35px;
}

.width-36px {
  width: 36px;
}

.width-37px {
  width: 37px;
}

.width-38px {
  width: 38px;
}

.width-39px {
  width: 39px;
}

.width-40px {
  width: 40px;
}

.width-41px {
  width: 41px;
}

.width-42px {
  width: 42px;
}

.width-43px {
  width: 43px;
}

.width-44px {
  width: 44px;
}

.width-45px {
  width: 45px;
}

.width-46px {
  width: 46px;
}

.width-47px {
  width: 47px;
}

.width-48px {
  width: 48px;
}

.width-49px {
  width: 49px;
}

.width-50px {
  width: 50px;
}

.width-51px {
  width: 51px;
}

.width-52px {
  width: 52px;
}

.width-53px {
  width: 53px;
}

.width-54px {
  width: 54px;
}

.width-55px {
  width: 55px;
}

.width-56px {
  width: 56px;
}

.width-57px {
  width: 57px;
}

.width-58px {
  width: 58px;
}

.width-59px {
  width: 59px;
}

.width-60px {
  width: 60px;
}

.width-61px {
  width: 61px;
}

.width-62px {
  width: 62px;
}

.width-63px {
  width: 63px;
}

.width-64px {
  width: 64px;
}

.width-65px {
  width: 65px;
}

.width-66px {
  width: 66px;
}

.width-67px {
  width: 67px;
}

.width-68px {
  width: 68px;
}

.width-69px {
  width: 69px;
}

.width-70px {
  width: 70px;
}

.width-71px {
  width: 71px;
}

.width-72px {
  width: 72px;
}

.width-73px {
  width: 73px;
}

.width-74px {
  width: 74px;
}

.width-75px {
  width: 75px;
}

.width-76px {
  width: 76px;
}

.width-77px {
  width: 77px;
}

.width-78px {
  width: 78px;
}

.width-79px {
  width: 79px;
}

.width-80px {
  width: 80px;
}

.width-81px {
  width: 81px;
}

.width-82px {
  width: 82px;
}

.width-83px {
  width: 83px;
}

.width-84px {
  width: 84px;
}

.width-85px {
  width: 85px;
}

.width-86px {
  width: 86px;
}

.width-87px {
  width: 87px;
}

.width-88px {
  width: 88px;
}

.width-89px {
  width: 89px;
}

.width-90px {
  width: 90px;
}

.width-91px {
  width: 91px;
}

.width-92px {
  width: 92px;
}

.width-93px {
  width: 93px;
}

.width-94px {
  width: 94px;
}

.width-95px {
  width: 95px;
}

.width-96px {
  width: 96px;
}

.width-97px {
  width: 97px;
}

.width-98px {
  width: 98px;
}

.width-99px {
  width: 99px;
}

.width-100px {
  width: 100px;
}

.width-101px {
  width: 101px;
}

.width-102px {
  width: 102px;
}

.width-103px {
  width: 103px;
}

.width-104px {
  width: 104px;
}

.width-105px {
  width: 105px;
}

.width-106px {
  width: 106px;
}

.width-107px {
  width: 107px;
}

.width-108px {
  width: 108px;
}

.width-109px {
  width: 109px;
}

.width-110px {
  width: 110px;
}

.width-111px {
  width: 111px;
}

.width-112px {
  width: 112px;
}

.width-113px {
  width: 113px;
}

.width-114px {
  width: 114px;
}

.width-115px {
  width: 115px;
}

.width-116px {
  width: 116px;
}

.width-117px {
  width: 117px;
}

.width-118px {
  width: 118px;
}

.width-119px {
  width: 119px;
}

.width-120px {
  width: 120px;
}

.width-121px {
  width: 121px;
}

.width-122px {
  width: 122px;
}

.width-123px {
  width: 123px;
}

.width-124px {
  width: 124px;
}

.width-125px {
  width: 125px;
}

.width-126px {
  width: 126px;
}

.width-127px {
  width: 127px;
}

.width-128px {
  width: 128px;
}

.width-129px {
  width: 129px;
}

.width-130px {
  width: 130px;
}

.width-131px {
  width: 131px;
}

.width-132px {
  width: 132px;
}

.width-133px {
  width: 133px;
}

.width-134px {
  width: 134px;
}

.width-135px {
  width: 135px;
}

.width-136px {
  width: 136px;
}

.width-137px {
  width: 137px;
}

.width-138px {
  width: 138px;
}

.width-139px {
  width: 139px;
}

.width-140px {
  width: 140px;
}

.width-141px {
  width: 141px;
}

.width-142px {
  width: 142px;
}

.width-143px {
  width: 143px;
}

.width-144px {
  width: 144px;
}

.width-145px {
  width: 145px;
}

.width-146px {
  width: 146px;
}

.width-147px {
  width: 147px;
}

.width-148px {
  width: 148px;
}

.width-149px {
  width: 149px;
}

.width-150px {
  width: 150px;
}

.width-151px {
  width: 151px;
}

.width-152px {
  width: 152px;
}

.width-153px {
  width: 153px;
}

.width-154px {
  width: 154px;
}

.width-155px {
  width: 155px;
}

.width-156px {
  width: 156px;
}

.width-157px {
  width: 157px;
}

.width-158px {
  width: 158px;
}

.width-159px {
  width: 159px;
}

.width-160px {
  width: 160px;
}

.width-161px {
  width: 161px;
}

.width-162px {
  width: 162px;
}

.width-163px {
  width: 163px;
}

.width-164px {
  width: 164px;
}

.width-165px {
  width: 165px;
}

.width-166px {
  width: 166px;
}

.width-167px {
  width: 167px;
}

.width-168px {
  width: 168px;
}

.width-169px {
  width: 169px;
}

.width-170px {
  width: 170px;
}

.width-171px {
  width: 171px;
}

.width-172px {
  width: 172px;
}

.width-173px {
  width: 173px;
}

.width-174px {
  width: 174px;
}

.width-175px {
  width: 175px;
}

.width-176px {
  width: 176px;
}

.width-177px {
  width: 177px;
}

.width-178px {
  width: 178px;
}

.width-179px {
  width: 179px;
}

.width-180px {
  width: 180px;
}

.width-181px {
  width: 181px;
}

.width-182px {
  width: 182px;
}

.width-183px {
  width: 183px;
}

.width-184px {
  width: 184px;
}

.width-185px {
  width: 185px;
}

.width-186px {
  width: 186px;
}

.width-187px {
  width: 187px;
}

.width-188px {
  width: 188px;
}

.width-189px {
  width: 189px;
}

.width-190px {
  width: 190px;
}

.width-191px {
  width: 191px;
}

.width-192px {
  width: 192px;
}

.width-193px {
  width: 193px;
}

.width-194px {
  width: 194px;
}

.width-195px {
  width: 195px;
}

.width-196px {
  width: 196px;
}

.width-197px {
  width: 197px;
}

.width-198px {
  width: 198px;
}

.width-199px {
  width: 199px;
}

.width-200px {
  width: 200px;
}

.width-201px {
  width: 201px;
}

.width-202px {
  width: 202px;
}

.width-203px {
  width: 203px;
}

.width-204px {
  width: 204px;
}

.width-205px {
  width: 205px;
}

.width-206px {
  width: 206px;
}

.width-207px {
  width: 207px;
}

.width-208px {
  width: 208px;
}

.width-209px {
  width: 209px;
}

.width-210px {
  width: 210px;
}

.width-211px {
  width: 211px;
}

.width-212px {
  width: 212px;
}

.width-213px {
  width: 213px;
}

.width-214px {
  width: 214px;
}

.width-215px {
  width: 215px;
}

.width-216px {
  width: 216px;
}

.width-217px {
  width: 217px;
}

.width-218px {
  width: 218px;
}

.width-219px {
  width: 219px;
}

.width-220px {
  width: 220px;
}

.width-221px {
  width: 221px;
}

.width-222px {
  width: 222px;
}

.width-223px {
  width: 223px;
}

.width-224px {
  width: 224px;
}

.width-225px {
  width: 225px;
}

.width-226px {
  width: 226px;
}

.width-227px {
  width: 227px;
}

.width-228px {
  width: 228px;
}

.width-229px {
  width: 229px;
}

.width-230px {
  width: 230px;
}

.width-231px {
  width: 231px;
}

.width-232px {
  width: 232px;
}

.width-233px {
  width: 233px;
}

.width-234px {
  width: 234px;
}

.width-235px {
  width: 235px;
}

.width-236px {
  width: 236px;
}

.width-237px {
  width: 237px;
}

.width-238px {
  width: 238px;
}

.width-239px {
  width: 239px;
}

.width-240px {
  width: 240px;
}

.width-241px {
  width: 241px;
}

.width-242px {
  width: 242px;
}

.width-243px {
  width: 243px;
}

.width-244px {
  width: 244px;
}

.width-245px {
  width: 245px;
}

.width-246px {
  width: 246px;
}

.width-247px {
  width: 247px;
}

.width-248px {
  width: 248px;
}

.width-249px {
  width: 249px;
}

.width-250px {
  width: 250px;
}

.width-251px {
  width: 251px;
}

.width-252px {
  width: 252px;
}

.width-253px {
  width: 253px;
}

.width-254px {
  width: 254px;
}

.width-255px {
  width: 255px;
}

.width-256px {
  width: 256px;
}

.width-257px {
  width: 257px;
}

.width-258px {
  width: 258px;
}

.width-259px {
  width: 259px;
}

.width-260px {
  width: 260px;
}

.width-261px {
  width: 261px;
}

.width-262px {
  width: 262px;
}

.width-263px {
  width: 263px;
}

.width-264px {
  width: 264px;
}

.width-265px {
  width: 265px;
}

.width-266px {
  width: 266px;
}

.width-267px {
  width: 267px;
}

.width-268px {
  width: 268px;
}

.width-269px {
  width: 269px;
}

.width-270px {
  width: 270px;
}

.width-271px {
  width: 271px;
}

.width-272px {
  width: 272px;
}

.width-273px {
  width: 273px;
}

.width-274px {
  width: 274px;
}

.width-275px {
  width: 275px;
}

.width-276px {
  width: 276px;
}

.width-277px {
  width: 277px;
}

.width-278px {
  width: 278px;
}

.width-279px {
  width: 279px;
}

.width-280px {
  width: 280px;
}

.width-281px {
  width: 281px;
}

.width-282px {
  width: 282px;
}

.width-283px {
  width: 283px;
}

.width-284px {
  width: 284px;
}

.width-285px {
  width: 285px;
}

.width-286px {
  width: 286px;
}

.width-287px {
  width: 287px;
}

.width-288px {
  width: 288px;
}

.width-289px {
  width: 289px;
}

.width-290px {
  width: 290px;
}

.width-291px {
  width: 291px;
}

.width-292px {
  width: 292px;
}

.width-293px {
  width: 293px;
}

.width-294px {
  width: 294px;
}

.width-295px {
  width: 295px;
}

.width-296px {
  width: 296px;
}

.width-297px {
  width: 297px;
}

.width-298px {
  width: 298px;
}

.width-299px {
  width: 299px;
}

.width-300px {
  width: 300px;
}

/*-- Width ---*/

.height-0px {
  height: 0px;
}

.height-1px {
  height: 1px;
}

.height-2px {
  height: 2px;
}

.height-3px {
  height: 3px;
}

.height-4px {
  height: 4px;
}

.height-5px {
  height: 5px;
}

.height-6px {
  height: 6px;
}

.height-7px {
  height: 7px;
}

.height-8px {
  height: 8px;
}

.height-9px {
  height: 9px;
}

.height-10px {
  height: 10px;
}

.height-11px {
  height: 11px;
}

.height-12px {
  height: 12px;
}

.height-13px {
  height: 13px;
}

.height-14px {
  height: 14px;
}

.height-15px {
  height: 15px;
}

.height-16px {
  height: 16px;
}

.height-17px {
  height: 17px;
}

.height-18px {
  height: 18px;
}

.height-19px {
  height: 19px;
}

.height-20px {
  height: 20px;
}

.height-21px {
  height: 21px;
}

.height-22px {
  height: 22px;
}

.height-23px {
  height: 23px;
}

.height-24px {
  height: 24px;
}

.height-25px {
  height: 25px;
}

.height-26px {
  height: 26px;
}

.height-27px {
  height: 27px;
}

.height-28px {
  height: 28px;
}

.height-29px {
  height: 29px;
}

.height-30px {
  height: 30px;
}

.height-31px {
  height: 31px;
}

.height-32px {
  height: 32px;
}

.height-33px {
  height: 33px;
}

.height-34px {
  height: 34px;
}

.height-35px {
  height: 35px;
}

.height-36px {
  height: 36px;
}

.height-37px {
  height: 37px;
}

.height-38px {
  height: 38px;
}

.height-39px {
  height: 39px;
}

.height-40px {
  height: 40px;
}

.height-41px {
  height: 41px;
}

.height-42px {
  height: 42px;
}

.height-43px {
  height: 43px;
}

.height-44px {
  height: 44px;
}

.height-45px {
  height: 45px;
}

.height-46px {
  height: 46px;
}

.height-47px {
  height: 47px;
}

.height-48px {
  height: 48px;
}

.height-49px {
  height: 49px;
}

.height-50px {
  height: 50px;
}

.height-51px {
  height: 51px;
}

.height-52px {
  height: 52px;
}

.height-53px {
  height: 53px;
}

.height-54px {
  height: 54px;
}

.height-55px {
  height: 55px;
}

.height-56px {
  height: 56px;
}

.height-57px {
  height: 57px;
}

.height-58px {
  height: 58px;
}

.height-59px {
  height: 59px;
}

.height-60px {
  height: 60px;
}

.height-61px {
  height: 61px;
}

.height-62px {
  height: 62px;
}

.height-63px {
  height: 63px;
}

.height-64px {
  height: 64px;
}

.height-65px {
  height: 65px;
}

.height-66px {
  height: 66px;
}

.height-67px {
  height: 67px;
}

.height-68px {
  height: 68px;
}

.height-69px {
  height: 69px;
}

.height-70px {
  height: 70px;
}

.height-71px {
  height: 71px;
}

.height-72px {
  height: 72px;
}

.height-73px {
  height: 73px;
}

.height-74px {
  height: 74px;
}

.height-75px {
  height: 75px;
}

.height-76px {
  height: 76px;
}

.height-77px {
  height: 77px;
}

.height-78px {
  height: 78px;
}

.height-79px {
  height: 79px;
}

.height-80px {
  height: 80px;
}

.height-81px {
  height: 81px;
}

.height-82px {
  height: 82px;
}

.height-83px {
  height: 83px;
}

.height-84px {
  height: 84px;
}

.height-85px {
  height: 85px;
}

.height-86px {
  height: 86px;
}

.height-87px {
  height: 87px;
}

.height-88px {
  height: 88px;
}

.height-89px {
  height: 89px;
}

.height-90px {
  height: 90px;
}

.height-91px {
  height: 91px;
}

.height-92px {
  height: 92px;
}

.height-93px {
  height: 93px;
}

.height-94px {
  height: 94px;
}

.height-95px {
  height: 95px;
}

.height-96px {
  height: 96px;
}

.height-97px {
  height: 97px;
}

.height-98px {
  height: 98px;
}

.height-99px {
  height: 99px;
}

.height-100px {
  height: 100px;
}

.height-101px {
  height: 101px;
}

.height-102px {
  height: 102px;
}

.height-103px {
  height: 103px;
}

.height-104px {
  height: 104px;
}

.height-105px {
  height: 105px;
}

.height-106px {
  height: 106px;
}

.height-107px {
  height: 107px;
}

.height-108px {
  height: 108px;
}

.height-109px {
  height: 109px;
}

.height-110px {
  height: 110px;
}

.height-111px {
  height: 111px;
}

.height-112px {
  height: 112px;
}

.height-113px {
  height: 113px;
}

.height-114px {
  height: 114px;
}

.height-115px {
  height: 115px;
}

.height-116px {
  height: 116px;
}

.height-117px {
  height: 117px;
}

.height-118px {
  height: 118px;
}

.height-119px {
  height: 119px;
}

.height-120px {
  height: 120px;
}

.height-121px {
  height: 121px;
}

.height-122px {
  height: 122px;
}

.height-123px {
  height: 123px;
}

.height-124px {
  height: 124px;
}

.height-125px {
  height: 125px;
}

.height-126px {
  height: 126px;
}

.height-127px {
  height: 127px;
}

.height-128px {
  height: 128px;
}

.height-129px {
  height: 129px;
}

.height-130px {
  height: 130px;
}

.height-131px {
  height: 131px;
}

.height-132px {
  height: 132px;
}

.height-133px {
  height: 133px;
}

.height-134px {
  height: 134px;
}

.height-135px {
  height: 135px;
}

.height-136px {
  height: 136px;
}

.height-137px {
  height: 137px;
}

.height-138px {
  height: 138px;
}

.height-139px {
  height: 139px;
}

.height-140px {
  height: 140px;
}

.height-141px {
  height: 141px;
}

.height-142px {
  height: 142px;
}

.height-143px {
  height: 143px;
}

.height-144px {
  height: 144px;
}

.height-145px {
  height: 145px;
}

.height-146px {
  height: 146px;
}

.height-147px {
  height: 147px;
}

.height-148px {
  height: 148px;
}

.height-149px {
  height: 149px;
}

.height-150px {
  height: 150px;
}

.height-151px {
  height: 151px;
}

.height-152px {
  height: 152px;
}

.height-153px {
  height: 153px;
}

.height-154px {
  height: 154px;
}

.height-155px {
  height: 155px;
}

.height-156px {
  height: 156px;
}

.height-157px {
  height: 157px;
}

.height-158px {
  height: 158px;
}

.height-159px {
  height: 159px;
}

.height-160px {
  height: 160px;
}

.height-161px {
  height: 161px;
}

.height-162px {
  height: 162px;
}

.height-163px {
  height: 163px;
}

.height-164px {
  height: 164px;
}

.height-165px {
  height: 165px;
}

.height-166px {
  height: 166px;
}

.height-167px {
  height: 167px;
}

.height-168px {
  height: 168px;
}

.height-169px {
  height: 169px;
}

.height-170px {
  height: 170px;
}

.height-171px {
  height: 171px;
}

.height-172px {
  height: 172px;
}

.height-173px {
  height: 173px;
}

.height-174px {
  height: 174px;
}

.height-175px {
  height: 175px;
}

.height-176px {
  height: 176px;
}

.height-177px {
  height: 177px;
}

.height-178px {
  height: 178px;
}

.height-179px {
  height: 179px;
}

.height-180px {
  height: 180px;
}

.height-181px {
  height: 181px;
}

.height-182px {
  height: 182px;
}

.height-183px {
  height: 183px;
}

.height-184px {
  height: 184px;
}

.height-185px {
  height: 185px;
}

.height-186px {
  height: 186px;
}

.height-187px {
  height: 187px;
}

.height-188px {
  height: 188px;
}

.height-189px {
  height: 189px;
}

.height-190px {
  height: 190px;
}

.height-191px {
  height: 191px;
}

.height-192px {
  height: 192px;
}

.height-193px {
  height: 193px;
}

.height-194px {
  height: 194px;
}

.height-195px {
  height: 195px;
}

.height-196px {
  height: 196px;
}

.height-197px {
  height: 197px;
}

.height-198px {
  height: 198px;
}

.height-199px {
  height: 199px;
}

.height-200px {
  height: 200px;
}

.height-201px {
  height: 201px;
}

.height-202px {
  height: 202px;
}

.height-203px {
  height: 203px;
}

.height-204px {
  height: 204px;
}

.height-205px {
  height: 205px;
}

.height-206px {
  height: 206px;
}

.height-207px {
  height: 207px;
}

.height-208px {
  height: 208px;
}

.height-209px {
  height: 209px;
}

.height-210px {
  height: 210px;
}

.height-211px {
  height: 211px;
}

.height-212px {
  height: 212px;
}

.height-213px {
  height: 213px;
}

.height-214px {
  height: 214px;
}

.height-215px {
  height: 215px;
}

.height-216px {
  height: 216px;
}

.height-217px {
  height: 217px;
}

.height-218px {
  height: 218px;
}

.height-219px {
  height: 219px;
}

.height-220px {
  height: 220px;
}

.height-221px {
  height: 221px;
}

.height-222px {
  height: 222px;
}

.height-223px {
  height: 223px;
}

.height-224px {
  height: 224px;
}

.height-225px {
  height: 225px;
}

.height-226px {
  height: 226px;
}

.height-227px {
  height: 227px;
}

.height-228px {
  height: 228px;
}

.height-229px {
  height: 229px;
}

.height-230px {
  height: 230px;
}

.height-231px {
  height: 231px;
}

.height-232px {
  height: 232px;
}

.height-233px {
  height: 233px;
}

.height-234px {
  height: 234px;
}

.height-235px {
  height: 235px;
}

.height-236px {
  height: 236px;
}

.height-237px {
  height: 237px;
}

.height-238px {
  height: 238px;
}

.height-239px {
  height: 239px;
}

.height-240px {
  height: 240px;
}

.height-241px {
  height: 241px;
}

.height-242px {
  height: 242px;
}

.height-243px {
  height: 243px;
}

.height-244px {
  height: 244px;
}

.height-245px {
  height: 245px;
}

.height-246px {
  height: 246px;
}

.height-247px {
  height: 247px;
}

.height-248px {
  height: 248px;
}

.height-249px {
  height: 249px;
}

.height-250px {
  height: 250px;
}

.height-251px {
  height: 251px;
}

.height-252px {
  height: 252px;
}

.height-253px {
  height: 253px;
}

.height-254px {
  height: 254px;
}

.height-255px {
  height: 255px;
}

.height-256px {
  height: 256px;
}

.height-257px {
  height: 257px;
}

.height-258px {
  height: 258px;
}

.height-259px {
  height: 259px;
}

.height-260px {
  height: 260px;
}

.height-261px {
  height: 261px;
}

.height-262px {
  height: 262px;
}

.height-263px {
  height: 263px;
}

.height-264px {
  height: 264px;
}

.height-265px {
  height: 265px;
}

.height-266px {
  height: 266px;
}

.height-267px {
  height: 267px;
}

.height-268px {
  height: 268px;
}

.height-269px {
  height: 269px;
}

.height-270px {
  height: 270px;
}

.height-271px {
  height: 271px;
}

.height-272px {
  height: 272px;
}

.height-273px {
  height: 273px;
}

.height-274px {
  height: 274px;
}

.height-275px {
  height: 275px;
}

.height-276px {
  height: 276px;
}

.height-277px {
  height: 277px;
}

.height-278px {
  height: 278px;
}

.height-279px {
  height: 279px;
}

.height-280px {
  height: 280px;
}

.height-281px {
  height: 281px;
}

.height-282px {
  height: 282px;
}

.height-283px {
  height: 283px;
}

.height-284px {
  height: 284px;
}

.height-285px {
  height: 285px;
}

.height-286px {
  height: 286px;
}

.height-287px {
  height: 287px;
}

.height-288px {
  height: 288px;
}

.height-289px {
  height: 289px;
}

.height-290px {
  height: 290px;
}

.height-291px {
  height: 291px;
}

.height-292px {
  height: 292px;
}

.height-293px {
  height: 293px;
}

.height-294px {
  height: 294px;
}

.height-295px {
  height: 295px;
}

.height-296px {
  height: 296px;
}

.height-297px {
  height: 297px;
}

.height-298px {
  height: 298px;
}

.height-299px {
  height: 299px;
}

.height-300px {
  height: 300px;
}

/*--- Font Weight ---*/

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

/*-- Pull Top ---*/

.pull-top-0px {
  margin-top: 0px;
}

.pull-top-1px {
  margin-top: -1px;
}

.pull-top-2px {
  margin-top: -2px;
}

.pull-top-3px {
  margin-top: -3px;
}

.pull-top-4px {
  margin-top: -4px;
}

.pull-top-5px {
  margin-top: -5px;
}

.pull-top-6px {
  margin-top: -6px;
}

.pull-top-7px {
  margin-top: -7px;
}

.pull-top-8px {
  margin-top: -8px;
}

.pull-top-9px {
  margin-top: -9px;
}

.pull-top-10px {
  margin-top: -10px;
}

.pull-top-11px {
  margin-top: -11px;
}

.pull-top-12px {
  margin-top: -12px;
}

.pull-top-13px {
  margin-top: -13px;
}

.pull-top-14px {
  margin-top: -14px;
}

.pull-top-15px {
  margin-top: -15px;
}

.pull-top-16px {
  margin-top: -16px;
}

.pull-top-17px {
  margin-top: -17px;
}

.pull-top-18px {
  margin-top: -18px;
}

.pull-top-19px {
  margin-top: -19px;
}

.pull-top-20px {
  margin-top: -20px;
}

.pull-top-21px {
  margin-top: -21px;
}

.pull-top-22px {
  margin-top: -22px;
}

.pull-top-23px {
  margin-top: -23px;
}

.pull-top-24px {
  margin-top: -24px;
}

.pull-top-25px {
  margin-top: -25px;
}

.pull-top-26px {
  margin-top: -26px;
}

.pull-top-27px {
  margin-top: -27px;
}

.pull-top-28px {
  margin-top: -28px;
}

.pull-top-29px {
  margin-top: -29px;
}

.pull-top-30px {
  margin-top: -30px;
}

.pull-top-31px {
  margin-top: -31px;
}

.pull-top-32px {
  margin-top: -32px;
}

.pull-top-33px {
  margin-top: -33px;
}

.pull-top-34px {
  margin-top: -34px;
}

.pull-top-35px {
  margin-top: -35px;
}

.pull-top-36px {
  margin-top: -36px;
}

.pull-top-37px {
  margin-top: -37px;
}

.pull-top-38px {
  margin-top: -38px;
}

.pull-top-39px {
  margin-top: -39px;
}

.pull-top-40px {
  margin-top: -40px;
}

.pull-top-41px {
  margin-top: -41px;
}

.pull-top-42px {
  margin-top: -42px;
}

.pull-top-43px {
  margin-top: -43px;
}

.pull-top-44px {
  margin-top: -44px;
}

.pull-top-45px {
  margin-top: -45px;
}

.pull-top-46px {
  margin-top: -46px;
}

.pull-top-47px {
  margin-top: -47px;
}

.pull-top-48px {
  margin-top: -48px;
}

.pull-top-49px {
  margin-top: -49px;
}

.pull-top-50px {
  margin-top: -50px;
}

.pull-top-51px {
  margin-top: -51px;
}

.pull-top-52px {
  margin-top: -52px;
}

.pull-top-53px {
  margin-top: -53px;
}

.pull-top-54px {
  margin-top: -54px;
}

.pull-top-55px {
  margin-top: -55px;
}

.pull-top-56px {
  margin-top: -56px;
}

.pull-top-57px {
  margin-top: -57px;
}

.pull-top-58px {
  margin-top: -58px;
}

.pull-top-59px {
  margin-top: -59px;
}

.pull-top-60px {
  margin-top: -60px;
}

.pull-top-61px {
  margin-top: -61px;
}

.pull-top-62px {
  margin-top: -62px;
}

.pull-top-63px {
  margin-top: -63px;
}

.pull-top-64px {
  margin-top: -64px;
}

.pull-top-65px {
  margin-top: -65px;
}

.pull-top-66px {
  margin-top: -66px;
}

.pull-top-67px {
  margin-top: -67px;
}

.pull-top-68px {
  margin-top: -68px;
}

.pull-top-69px {
  margin-top: -69px;
}

.pull-top-70px {
  margin-top: -70px;
}

.pull-top-71px {
  margin-top: -71px;
}

.pull-top-72px {
  margin-top: -72px;
}

.pull-top-73px {
  margin-top: -73px;
}

.pull-top-74px {
  margin-top: -74px;
}

.pull-top-75px {
  margin-top: -75px;
}

.pull-top-76px {
  margin-top: -76px;
}

.pull-top-77px {
  margin-top: -77px;
}

.pull-top-78px {
  margin-top: -78px;
}

.pull-top-79px {
  margin-top: -79px;
}

.pull-top-80px {
  margin-top: -80px;
}

.pull-top-81px {
  margin-top: -81px;
}

.pull-top-82px {
  margin-top: -82px;
}

.pull-top-83px {
  margin-top: -83px;
}

.pull-top-84px {
  margin-top: -84px;
}

.pull-top-85px {
  margin-top: -85px;
}

.pull-top-86px {
  margin-top: -86px;
}

.pull-top-87px {
  margin-top: -87px;
}

.pull-top-88px {
  margin-top: -88px;
}

.pull-top-89px {
  margin-top: -89px;
}

.pull-top-90px {
  margin-top: -90px;
}

.pull-top-91px {
  margin-top: -91px;
}

.pull-top-92px {
  margin-top: -92px;
}

.pull-top-93px {
  margin-top: -93px;
}

.pull-top-94px {
  margin-top: -94px;
}

.pull-top-95px {
  margin-top: -95px;
}

.pull-top-96px {
  margin-top: -96px;
}

.pull-top-97px {
  margin-top: -97px;
}

.pull-top-98px {
  margin-top: -98px;
}

.pull-top-99px {
  margin-top: -99px;
}

.pull-top-100px {
  margin-top: -100px;
}

/*-- Pull Bottom ---*/

.pull-bottom-0px {
  margin-bottom: 0px;
}

.pull-bottom-1px {
  margin-bottom: -1px;
}

.pull-bottom-2px {
  margin-bottom: -2px;
}

.pull-bottom-3px {
  margin-bottom: -3px;
}

.pull-bottom-4px {
  margin-bottom: -4px;
}

.pull-bottom-5px {
  margin-bottom: -5px;
}

.pull-bottom-6px {
  margin-bottom: -6px;
}

.pull-bottom-7px {
  margin-bottom: -7px;
}

.pull-bottom-8px {
  margin-bottom: -8px;
}

.pull-bottom-9px {
  margin-bottom: -9px;
}

.pull-bottom-10px {
  margin-bottom: -10px;
}

.pull-bottom-11px {
  margin-bottom: -11px;
}

.pull-bottom-12px {
  margin-bottom: -12px;
}

.pull-bottom-13px {
  margin-bottom: -13px;
}

.pull-bottom-14px {
  margin-bottom: -14px;
}

.pull-bottom-15px {
  margin-bottom: -15px;
}

.pull-bottom-16px {
  margin-bottom: -16px;
}

.pull-bottom-17px {
  margin-bottom: -17px;
}

.pull-bottom-18px {
  margin-bottom: -18px;
}

.pull-bottom-19px {
  margin-bottom: -19px;
}

.pull-bottom-20px {
  margin-bottom: -20px;
}

.pull-bottom-21px {
  margin-bottom: -21px;
}

.pull-bottom-22px {
  margin-bottom: -22px;
}

.pull-bottom-23px {
  margin-bottom: -23px;
}

.pull-bottom-24px {
  margin-bottom: -24px;
}

.pull-bottom-25px {
  margin-bottom: -25px;
}

.pull-bottom-26px {
  margin-bottom: -26px;
}

.pull-bottom-27px {
  margin-bottom: -27px;
}

.pull-bottom-28px {
  margin-bottom: -28px;
}

.pull-bottom-29px {
  margin-bottom: -29px;
}

.pull-bottom-30px {
  margin-bottom: -30px;
}

.pull-bottom-31px {
  margin-bottom: -31px;
}

.pull-bottom-32px {
  margin-bottom: -32px;
}

.pull-bottom-33px {
  margin-bottom: -33px;
}

.pull-bottom-34px {
  margin-bottom: -34px;
}

.pull-bottom-35px {
  margin-bottom: -35px;
}

.pull-bottom-36px {
  margin-bottom: -36px;
}

.pull-bottom-37px {
  margin-bottom: -37px;
}

.pull-bottom-38px {
  margin-bottom: -38px;
}

.pull-bottom-39px {
  margin-bottom: -39px;
}

.pull-bottom-40px {
  margin-bottom: -40px;
}

.pull-bottom-41px {
  margin-bottom: -41px;
}

.pull-bottom-42px {
  margin-bottom: -42px;
}

.pull-bottom-43px {
  margin-bottom: -43px;
}

.pull-bottom-44px {
  margin-bottom: -44px;
}

.pull-bottom-45px {
  margin-bottom: -45px;
}

.pull-bottom-46px {
  margin-bottom: -46px;
}

.pull-bottom-47px {
  margin-bottom: -47px;
}

.pull-bottom-48px {
  margin-bottom: -48px;
}

.pull-bottom-49px {
  margin-bottom: -49px;
}

.pull-bottom-50px {
  margin-bottom: -50px;
}

.pull-bottom-51px {
  margin-bottom: -51px;
}

.pull-bottom-52px {
  margin-bottom: -52px;
}

.pull-bottom-53px {
  margin-bottom: -53px;
}

.pull-bottom-54px {
  margin-bottom: -54px;
}

.pull-bottom-55px {
  margin-bottom: -55px;
}

.pull-bottom-56px {
  margin-bottom: -56px;
}

.pull-bottom-57px {
  margin-bottom: -57px;
}

.pull-bottom-58px {
  margin-bottom: -58px;
}

.pull-bottom-59px {
  margin-bottom: -59px;
}

.pull-bottom-60px {
  margin-bottom: -60px;
}

.pull-bottom-61px {
  margin-bottom: -61px;
}

.pull-bottom-62px {
  margin-bottom: -62px;
}

.pull-bottom-63px {
  margin-bottom: -63px;
}

.pull-bottom-64px {
  margin-bottom: -64px;
}

.pull-bottom-65px {
  margin-bottom: -65px;
}

.pull-bottom-66px {
  margin-bottom: -66px;
}

.pull-bottom-67px {
  margin-bottom: -67px;
}

.pull-bottom-68px {
  margin-bottom: -68px;
}

.pull-bottom-69px {
  margin-bottom: -69px;
}

.pull-bottom-70px {
  margin-bottom: -70px;
}

.pull-bottom-71px {
  margin-bottom: -71px;
}

.pull-bottom-72px {
  margin-bottom: -72px;
}

.pull-bottom-73px {
  margin-bottom: -73px;
}

.pull-bottom-74px {
  margin-bottom: -74px;
}

.pull-bottom-75px {
  margin-bottom: -75px;
}

.pull-bottom-76px {
  margin-bottom: -76px;
}

.pull-bottom-77px {
  margin-bottom: -77px;
}

.pull-bottom-78px {
  margin-bottom: -78px;
}

.pull-bottom-79px {
  margin-bottom: -79px;
}

.pull-bottom-80px {
  margin-bottom: -80px;
}

.pull-bottom-81px {
  margin-bottom: -81px;
}

.pull-bottom-82px {
  margin-bottom: -82px;
}

.pull-bottom-83px {
  margin-bottom: -83px;
}

.pull-bottom-84px {
  margin-bottom: -84px;
}

.pull-bottom-85px {
  margin-bottom: -85px;
}

.pull-bottom-86px {
  margin-bottom: -86px;
}

.pull-bottom-87px {
  margin-bottom: -87px;
}

.pull-bottom-88px {
  margin-bottom: -88px;
}

.pull-bottom-89px {
  margin-bottom: -89px;
}

.pull-bottom-90px {
  margin-bottom: -90px;
}

.pull-bottom-91px {
  margin-bottom: -91px;
}

.pull-bottom-92px {
  margin-bottom: -92px;
}

.pull-bottom-93px {
  margin-bottom: -93px;
}

.pull-bottom-94px {
  margin-bottom: -94px;
}

.pull-bottom-95px {
  margin-bottom: -95px;
}

.pull-bottom-96px {
  margin-bottom: -96px;
}

.pull-bottom-97px {
  margin-bottom: -97px;
}

.pull-bottom-98px {
  margin-bottom: -98px;
}

.pull-bottom-99px {
  margin-bottom: -99px;
}

.pull-bottom-100px {
  margin-bottom: -100px;
}

/*-- Pull right ---*/

.pull-bottom-0px {
  margin-right: 0px;
}

.pull-bottom-1px {
  margin-right: -1px;
}

.pull-bottom-2px {
  margin-right: -2px;
}

.pull-bottom-3px {
  margin-right: -3px;
}

.pull-bottom-4px {
  margin-right: -4px;
}

.pull-bottom-5px {
  margin-right: -5px;
}

.pull-bottom-6px {
  margin-right: -6px;
}

.pull-bottom-7px {
  margin-right: -7px;
}

.pull-bottom-8px {
  margin-right: -8px;
}

.pull-bottom-9px {
  margin-right: -9px;
}

.pull-bottom-10px {
  margin-right: -10px;
}

.pull-bottom-11px {
  margin-right: -11px;
}

.pull-bottom-12px {
  margin-right: -12px;
}

.pull-bottom-13px {
  margin-right: -13px;
}

.pull-bottom-14px {
  margin-right: -14px;
}

.pull-bottom-15px {
  margin-right: -15px;
}

.pull-bottom-16px {
  margin-right: -16px;
}

.pull-bottom-17px {
  margin-right: -17px;
}

.pull-bottom-18px {
  margin-right: -18px;
}

.pull-bottom-19px {
  margin-right: -19px;
}

.pull-bottom-20px {
  margin-right: -20px;
}

.pull-bottom-21px {
  margin-right: -21px;
}

.pull-bottom-22px {
  margin-right: -22px;
}

.pull-bottom-23px {
  margin-right: -23px;
}

.pull-bottom-24px {
  margin-right: -24px;
}

.pull-bottom-25px {
  margin-right: -25px;
}

.pull-bottom-26px {
  margin-right: -26px;
}

.pull-bottom-27px {
  margin-right: -27px;
}

.pull-bottom-28px {
  margin-right: -28px;
}

.pull-bottom-29px {
  margin-right: -29px;
}

.pull-bottom-30px {
  margin-right: -30px;
}

.pull-bottom-31px {
  margin-right: -31px;
}

.pull-bottom-32px {
  margin-right: -32px;
}

.pull-bottom-33px {
  margin-right: -33px;
}

.pull-bottom-34px {
  margin-right: -34px;
}

.pull-bottom-35px {
  margin-right: -35px;
}

.pull-bottom-36px {
  margin-right: -36px;
}

.pull-bottom-37px {
  margin-right: -37px;
}

.pull-bottom-38px {
  margin-right: -38px;
}

.pull-bottom-39px {
  margin-right: -39px;
}

.pull-bottom-40px {
  margin-right: -40px;
}

.pull-bottom-41px {
  margin-right: -41px;
}

.pull-bottom-42px {
  margin-right: -42px;
}

.pull-bottom-43px {
  margin-right: -43px;
}

.pull-bottom-44px {
  margin-right: -44px;
}

.pull-bottom-45px {
  margin-right: -45px;
}

.pull-bottom-46px {
  margin-right: -46px;
}

.pull-bottom-47px {
  margin-right: -47px;
}

.pull-bottom-48px {
  margin-right: -48px;
}

.pull-bottom-49px {
  margin-right: -49px;
}

.pull-bottom-50px {
  margin-right: -50px;
}

.pull-bottom-51px {
  margin-right: -51px;
}

.pull-bottom-52px {
  margin-right: -52px;
}

.pull-bottom-53px {
  margin-right: -53px;
}

.pull-bottom-54px {
  margin-right: -54px;
}

.pull-bottom-55px {
  margin-right: -55px;
}

.pull-bottom-56px {
  margin-right: -56px;
}

.pull-bottom-57px {
  margin-right: -57px;
}

.pull-bottom-58px {
  margin-right: -58px;
}

.pull-bottom-59px {
  margin-right: -59px;
}

.pull-bottom-60px {
  margin-right: -60px;
}

.pull-bottom-61px {
  margin-right: -61px;
}

.pull-bottom-62px {
  margin-right: -62px;
}

.pull-bottom-63px {
  margin-right: -63px;
}

.pull-bottom-64px {
  margin-right: -64px;
}

.pull-bottom-65px {
  margin-right: -65px;
}

.pull-bottom-66px {
  margin-right: -66px;
}

.pull-bottom-67px {
  margin-right: -67px;
}

.pull-bottom-68px {
  margin-right: -68px;
}

.pull-bottom-69px {
  margin-right: -69px;
}

.pull-bottom-70px {
  margin-right: -70px;
}

.pull-bottom-71px {
  margin-right: -71px;
}

.pull-bottom-72px {
  margin-right: -72px;
}

.pull-bottom-73px {
  margin-right: -73px;
}

.pull-bottom-74px {
  margin-right: -74px;
}

.pull-bottom-75px {
  margin-right: -75px;
}

.pull-bottom-76px {
  margin-right: -76px;
}

.pull-bottom-77px {
  margin-right: -77px;
}

.pull-bottom-78px {
  margin-right: -78px;
}

.pull-bottom-79px {
  margin-right: -79px;
}

.pull-bottom-80px {
  margin-right: -80px;
}

.pull-bottom-81px {
  margin-right: -81px;
}

.pull-bottom-82px {
  margin-right: -82px;
}

.pull-bottom-83px {
  margin-right: -83px;
}

.pull-bottom-84px {
  margin-right: -84px;
}

.pull-bottom-85px {
  margin-right: -85px;
}

.pull-bottom-86px {
  margin-right: -86px;
}

.pull-bottom-87px {
  margin-right: -87px;
}

.pull-bottom-88px {
  margin-right: -88px;
}

.pull-bottom-89px {
  margin-right: -89px;
}

.pull-bottom-90px {
  margin-right: -90px;
}

.pull-bottom-91px {
  margin-right: -91px;
}

.pull-bottom-92px {
  margin-right: -92px;
}

.pull-bottom-93px {
  margin-right: -93px;
}

.pull-bottom-94px {
  margin-right: -94px;
}

.pull-bottom-95px {
  margin-right: -95px;
}

.pull-bottom-96px {
  margin-right: -96px;
}

.pull-bottom-97px {
  margin-right: -97px;
}

.pull-bottom-98px {
  margin-right: -98px;
}

.pull-bottom-99px {
  margin-right: -99px;
}

.pull-bottom-100px {
  margin-right: -100px;
}

/*-- Pull Left ---*/

.pull-left-0px {
  margin-left: 0px;
}

.pull-left-1px {
  margin-left: -1px;
}

.pull-left-2px {
  margin-left: -2px;
}

.pull-left-3px {
  margin-left: -3px;
}

.pull-left-4px {
  margin-left: -4px;
}

.pull-left-5px {
  margin-left: -5px;
}

.pull-left-6px {
  margin-left: -6px;
}

.pull-left-7px {
  margin-left: -7px;
}

.pull-left-8px {
  margin-left: -8px;
}

.pull-left-9px {
  margin-left: -9px;
}

.pull-left-10px {
  margin-left: -10px;
}

.pull-left-11px {
  margin-left: -11px;
}

.pull-left-12px {
  margin-left: -12px;
}

.pull-left-13px {
  margin-left: -13px;
}

.pull-left-14px {
  margin-left: -14px;
}

.pull-left-15px {
  margin-left: -15px;
}

.pull-left-16px {
  margin-left: -16px;
}

.pull-left-17px {
  margin-left: -17px;
}

.pull-left-18px {
  margin-left: -18px;
}

.pull-left-19px {
  margin-left: -19px;
}

.pull-left-20px {
  margin-left: -20px;
}

.pull-left-21px {
  margin-left: -21px;
}

.pull-left-22px {
  margin-left: -22px;
}

.pull-left-23px {
  margin-left: -23px;
}

.pull-left-24px {
  margin-left: -24px;
}

.pull-left-25px {
  margin-left: -25px;
}

.pull-left-26px {
  margin-left: -26px;
}

.pull-left-27px {
  margin-left: -27px;
}

.pull-left-28px {
  margin-left: -28px;
}

.pull-left-29px {
  margin-left: -29px;
}

.pull-left-30px {
  margin-left: -30px;
}

.pull-left-31px {
  margin-left: -31px;
}

.pull-left-32px {
  margin-left: -32px;
}

.pull-left-33px {
  margin-left: -33px;
}

.pull-left-34px {
  margin-left: -34px;
}

.pull-left-35px {
  margin-left: -35px;
}

.pull-left-36px {
  margin-left: -36px;
}

.pull-left-37px {
  margin-left: -37px;
}

.pull-left-38px {
  margin-left: -38px;
}

.pull-left-39px {
  margin-left: -39px;
}

.pull-left-40px {
  margin-left: -40px;
}

.pull-left-41px {
  margin-left: -41px;
}

.pull-left-42px {
  margin-left: -42px;
}

.pull-left-43px {
  margin-left: -43px;
}

.pull-left-44px {
  margin-left: -44px;
}

.pull-left-45px {
  margin-left: -45px;
}

.pull-left-46px {
  margin-left: -46px;
}

.pull-left-47px {
  margin-left: -47px;
}

.pull-left-48px {
  margin-left: -48px;
}

.pull-left-49px {
  margin-left: -49px;
}

.pull-left-50px {
  margin-left: -50px;
}

.pull-left-51px {
  margin-left: -51px;
}

.pull-left-52px {
  margin-left: -52px;
}

.pull-left-53px {
  margin-left: -53px;
}

.pull-left-54px {
  margin-left: -54px;
}

.pull-left-55px {
  margin-left: -55px;
}

.pull-left-56px {
  margin-left: -56px;
}

.pull-left-57px {
  margin-left: -57px;
}

.pull-left-58px {
  margin-left: -58px;
}

.pull-left-59px {
  margin-left: -59px;
}

.pull-left-60px {
  margin-left: -60px;
}

.pull-left-61px {
  margin-left: -61px;
}

.pull-left-62px {
  margin-left: -62px;
}

.pull-left-63px {
  margin-left: -63px;
}

.pull-left-64px {
  margin-left: -64px;
}

.pull-left-65px {
  margin-left: -65px;
}

.pull-left-66px {
  margin-left: -66px;
}

.pull-left-67px {
  margin-left: -67px;
}

.pull-left-68px {
  margin-left: -68px;
}

.pull-left-69px {
  margin-left: -69px;
}

.pull-left-70px {
  margin-left: -70px;
}

.pull-left-71px {
  margin-left: -71px;
}

.pull-left-72px {
  margin-left: -72px;
}

.pull-left-73px {
  margin-left: -73px;
}

.pull-left-74px {
  margin-left: -74px;
}

.pull-left-75px {
  margin-left: -75px;
}

.pull-left-76px {
  margin-left: -76px;
}

.pull-left-77px {
  margin-left: -77px;
}

.pull-left-78px {
  margin-left: -78px;
}

.pull-left-79px {
  margin-left: -79px;
}

.pull-left-80px {
  margin-left: -80px;
}

.pull-left-81px {
  margin-left: -81px;
}

.pull-left-82px {
  margin-left: -82px;
}

.pull-left-83px {
  margin-left: -83px;
}

.pull-left-84px {
  margin-left: -84px;
}

.pull-left-85px {
  margin-left: -85px;
}

.pull-left-86px {
  margin-left: -86px;
}

.pull-left-87px {
  margin-left: -87px;
}

.pull-left-88px {
  margin-left: -88px;
}

.pull-left-89px {
  margin-left: -89px;
}

.pull-left-90px {
  margin-left: -90px;
}

.pull-left-91px {
  margin-left: -91px;
}

.pull-left-92px {
  margin-left: -92px;
}

.pull-left-93px {
  margin-left: -93px;
}

.pull-left-94px {
  margin-left: -94px;
}

.pull-left-95px {
  margin-left: -95px;
}

.pull-left-96px {
  margin-left: -96px;
}

.pull-left-97px {
  margin-left: -97px;
}

.pull-left-98px {
  margin-left: -98px;
}

.pull-left-99px {
  margin-left: -99px;
}

.pull-left-100px {
  margin-left: -100px;
}

/*--- Z Index ---*/

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-11 {
  z-index: 11;
}

.z-index-12 {
  z-index: 12;
}

.z-index-13 {
  z-index: 13;
}

.z-index-14 {
  z-index: 14;
}

.z-index-15 {
  z-index: 15;
}

.z-index-16 {
  z-index: 16;
}

.z-index-17 {
  z-index: 17;
}

.z-index-18 {
  z-index: 18;
}

.z-index-19 {
  z-index: 19;
}

.z-index-20 {
  z-index: 20;
}

.z-index-21 {
  z-index: 21;
}

.z-index-22 {
  z-index: 22;
}

.z-index-23 {
  z-index: 23;
}

.z-index-24 {
  z-index: 24;
}

.z-index-25 {
  z-index: 25;
}

.z-index-26 {
  z-index: 26;
}

.z-index-27 {
  z-index: 27;
}

.z-index-28 {
  z-index: 28;
}

.z-index-29 {
  z-index: 29;
}

.z-index-30 {
  z-index: 30;
}

.z-index-31 {
  z-index: 31;
}

.z-index-32 {
  z-index: 32;
}

.z-index-33 {
  z-index: 33;
}

.z-index-34 {
  z-index: 34;
}

.z-index-35 {
  z-index: 35;
}

.z-index-36 {
  z-index: 36;
}

.z-index-37 {
  z-index: 37;
}

.z-index-38 {
  z-index: 38;
}

.z-index-39 {
  z-index: 39;
}

.z-index-40 {
  z-index: 40;
}

.z-index-41 {
  z-index: 41;
}

.z-index-42 {
  z-index: 42;
}

.z-index-43 {
  z-index: 43;
}

.z-index-44 {
  z-index: 44;
}

.z-index-45 {
  z-index: 45;
}

.z-index-46 {
  z-index: 46;
}

.z-index-47 {
  z-index: 47;
}

.z-index-48 {
  z-index: 48;
}

.z-index-49 {
  z-index: 49;
}

.z-index-50 {
  z-index: 50;
}

.z-index-51 {
  z-index: 51;
}

.z-index-52 {
  z-index: 52;
}

.z-index-53 {
  z-index: 53;
}

.z-index-54 {
  z-index: 54;
}

.z-index-55 {
  z-index: 55;
}

.z-index-56 {
  z-index: 56;
}

.z-index-57 {
  z-index: 57;
}

.z-index-58 {
  z-index: 58;
}

.z-index-59 {
  z-index: 59;
}

.z-index-60 {
  z-index: 60;
}

.z-index-61 {
  z-index: 61;
}

.z-index-62 {
  z-index: 62;
}

.z-index-63 {
  z-index: 63;
}

.z-index-64 {
  z-index: 64;
}

.z-index-65 {
  z-index: 65;
}

.z-index-66 {
  z-index: 66;
}

.z-index-67 {
  z-index: 67;
}

.z-index-68 {
  z-index: 68;
}

.z-index-69 {
  z-index: 69;
}

.z-index-70 {
  z-index: 70;
}

.z-index-71 {
  z-index: 71;
}

.z-index-72 {
  z-index: 72;
}

.z-index-73 {
  z-index: 73;
}

.z-index-74 {
  z-index: 74;
}

.z-index-75 {
  z-index: 75;
}

.z-index-76 {
  z-index: 76;
}

.z-index-77 {
  z-index: 77;
}

.z-index-78 {
  z-index: 78;
}

.z-index-79 {
  z-index: 79;
}

.z-index-80 {
  z-index: 80;
}

.z-index-81 {
  z-index: 81;
}

.z-index-82 {
  z-index: 82;
}

.z-index-83 {
  z-index: 83;
}

.z-index-84 {
  z-index: 84;
}

.z-index-85 {
  z-index: 85;
}

.z-index-86 {
  z-index: 86;
}

.z-index-87 {
  z-index: 87;
}

.z-index-88 {
  z-index: 88;
}

.z-index-89 {
  z-index: 89;
}

.z-index-90 {
  z-index: 90;
}

.z-index-91 {
  z-index: 91;
}

.z-index-92 {
  z-index: 92;
}

.z-index-93 {
  z-index: 93;
}

.z-index-94 {
  z-index: 94;
}

.z-index-95 {
  z-index: 95;
}

.z-index-96 {
  z-index: 96;
}

.z-index-97 {
  z-index: 97;
}

.z-index-98 {
  z-index: 98;
}

.z-index-99 {
  z-index: 99;
}

.z-index-100 {
  z-index: 100;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

ul.pagination li {
  margin-right: 5px;
}

ul.pagination.dark li a {
  background-color: #424242;
  color: #fafafa;
  border: none;
}

ul.pagination.color li a {
  background-color: #1db8c1;
  color: #fafafa;
  border: none;
}

ul.pagination.color-1 li a {
  background-color: #fdbe19;
  color: #fafafa;
  border: none;
}

ul.pagination.color-2 li a {
  background-color: #f44336;
  color: #fafafa;
  border: none;
}

ul.pagination-style-2 li a {
  border-radius: 50%;
}

ul.pagination-style-2 li a .page-item:last-child .page-link {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

/*--- Round icons ---*/

.icon-round {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #fdbe19;
  color: #fff;
  text-align: center;
  padding-top: 15px;
  font-size: 20px;
}

.icon-round-ba {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  padding-top: 15px;
  font-size: 20px;
}

/*--- Hover Option ---*/

.with-hover {
  position: relative;
  overflow: hidden;
}

.with-hover:hover .hover-option {
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
  visibility: visible;
  display: block;
}

.hidden {
  overflow: hidden;
}

.hover-option {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  width: 93%;
  height: 90%;
  left: 15px;
  top: 14px;
  visibility: hidden;
  opacity: 0;
}

/*------------------------------------------------------------------------*
/* Cart           --------------------------------------------*
------------------------------------------------------------------------- */

.cart-model {
  display: none;
  position: absolute;
  right: 0;
  top: 48px;
  width: 320px;
  background-color: #fff;
  padding: 30px;
  border: 1px solid #eee;
  -webkit-box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.12);
}

.cart-model .media {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.cart-model .media .media img {
  border-radius: 5px;
}

.cart-model .media h5 {
  margin-bottom: 5px;
}

.cart-model .media h5 a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #2b2b2a;
}

.cart-model .media h6 {
  color: #9b9b9b;
  font-size: 13px;
  text-transform: lowercase;
  font-weight: 400;
  font-family: 'Open Sans';
}

.cart-model a.close {
  font-size: 16px;
  margin-top: 10px;
  color: #ccc;
}

.cart-model a.close:hover {
  color: #f44336;
}

.cart-model a.btn-primary {
  background-color: #fdbe19;
  border: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 15px;
}

.cart-model a.btn-outline-primary {
  border-color: #1db8c1;
  color: #1db8c1;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
}

.cart-model a.btn-outline-primary:hover {
  background-color: #1db8c1;
  color: #fff;
}

.cart-model .cart-total {
  margin: 20px 0 20px 0;
}

.cart-model .cart-total .desc {
  float: left;
  text-transform: uppercase;
}

.cart-model .cart-total .price {
  float: right;
}

/*------------------------------------------------------------------*
/* Search Bar           --------------------------------------------*
------------------------------------------------------------------- */

#search_bar {
  position: relative;
  display: none;
}

#search_bar input.input-search {
  margin-bottom: 5px;
  padding: 10px 20px 10px 20px;
  border-radius: 3px;
  position: relative;
}

#search_bar a.close-search {
  position: absolute;
  right: 20px;
  top: 8px;
  font-size: 15px;
  z-index: 9;
}

/*------------------------------------------------------------------------*
/* Map Distributors           --------------------------------------------*
------------------------------------------------------------------------- */

.map-distributors-in {
  position: relative;
  height: 450px;
  width: 100%;
}

#map-distributors {
  height: 100% !important;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 5;
}

#map-distributors .item-in {
  width: 200px;
}

#map-distributors .item-in img {
  width: 100%;
  height: auto;
}

#map-distributors .btn-book {
  background-color: #1db8c1;
  border-radius: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  padding: 8px;
}

#map-distributors .btn-book {
  background-color: #1db8c1;
  border-radius: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  padding: 8px;
}

#map-distributors .btn-book:hover {
  background-color: #fdbe19;
  text-decoration: none;
}

#map-distributors .gm-style .gm-style-iw {
  left: 25px !important;
}

#map-distributors .gm-style .gm-style-iw div {
  max-height: auto !important;
  overflow: auto !important;
  max-height: 438px !important;
}

#map-distributors .gomapMarker {
  height: auto !important;
}

#map-distributors .gomapMarker :after {
  content: ' ';
  clear: both;
  display: block;
  width: 100%;
}

#map-distributors .item-in .title a {
  margin-top: 5px;
  font-size: 14px;
  color: #000;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

#map-distributors .item-in .card-text small a {
  font-size: 11px;
  color: #515151;
  text-decoration: none;
}

#map-distributors .item-in .card-text small a span {
  color: #f44336;
}

#map-distributors .item-in .card-text-in .price {
  font-size: 17px;
  color: #f44336;
}

/*------------------------------------------------------------------------*
/* Search Filter           -----------------------------------------------*
------------------------------------------------------------------------- */

.search-filter .nav-tabs .nav-link {
  color: #212121;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.search-filter .nav-item.show .nav-link,
.search-filter .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #1db8c1;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.search-filter input {
  position: relative;
  padding-left: 35px;
}

.search-filter .date-input {
  position: relative;
}

.search-filter .date-input:before {
  content: '';
  font-family: FontAwesome;
  position: absolute;
  top: 5px;
  left: 15px;
  z-index: 5;
  color: #fdbe19;
  z-index: 1;
}

.search-filter .destination {
  position: relative;
}

.search-filter .destination:before {
  content: '';
  font-family: FontAwesome;
  position: absolute;
  top: 5px;
  left: 15px;
  z-index: 5;
  color: #fdbe19;
  z-index: 1;
}

.search-filter .rooms {
  position: relative;
}

.search-filter .rooms:before {
  content: '';
  font-family: FontAwesome;
  position: absolute;
  top: 5px;
  left: 15px;
  z-index: 5;
  color: #fdbe19;
  z-index: 1;
}

.search-filter .children {
  position: relative;
}

.search-filter .children:before {
  content: '';
  font-family: FontAwesome;
  position: absolute;
  top: 5px;
  left: 15px;
  z-index: 5;
  color: #fdbe19;
  z-index: 1;
}

/*------------------------------------------------------------------------*
/* Section Style           -----------------------------------------------*
------------------------------------------------------------------------- */

.map-ba {
  /*background-image: url(../images/map_ba.png);*/
  background-position: left top;
  background-repeat: no-repeat;
}

section .section-title {
  text-align: center;
  margin-bottom: 60px;
}

section .section-title h1 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 25px;
  color: #f44336;
}

section .section-title h1 span {
  color: #1db8c1;
}

section .section-title span.section-title-des {
  position: relative;
  display: block;
}

section .section-title-left {
  text-align: left;
}

section .section-title-left span.section-title-des:after {
  text-align: left;
  content: '  ';
  position: relative;
  display: block;
  width: 65px;
  height: 10px;
  border-bottom: solid 1px #fdbe19;
}

section .section-title-center span.section-title-des:after {
  content: '  ';
  position: relative;
  display: block;
  width: 65px;
  height: 10px;
  border-bottom: solid 1px #fdbe19;
  margin: auto;
}

/*----------------------------------------------------------------*
/* Hotel  Cart     -----------------------------------------------*
----------------------------------------------------------------- */

.hotel-grid .hotel-img {
  position: relative;
  overflow: hidden;
}

.hotel-grid .rating {
  margin-top: -15px;
  position: relative;
  z-index: 2;
}

.rating ul {
  max-width: 160px;
  background-color: #fff;
  padding: 5px;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  -webkit-box-shadow: 0px 0px 8px -1px #595959;
  -moz-box-shadow: 0px 0px 8px -1px #595959;
  box-shadow: 0px 0px 8px -1px #595959;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.rating ul li {
  display: inline-block;
  /*background-image: url(../images/star_0.png);*/
  background-repeat: no-repeat;
  background-position: center center;
  height: 14px;
  width: 14px;
  margin-right: 5px;
  margin-left: 5px;
}

.rating ul li.active {
  /*background-image: url(../images/star_1.png);*/
}

/*------------------------------------------------------------------------*
/* Hotel List                 --------------------------------------------*
------------------------------------------------------------------------- */

.hotel-list .hotel-thum {
  float: left;
  width: 270px;
  margin-right: 30px;
}

.hotel-list ul.service {
  width: 40px;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}

.hotel-list ul.service li {
  display: block;
  text-align: center;
}

.hotel-list ul.service li:nth-child(odd) a {
  background-color: #fdbe19;
}

.hotel-list ul.service li:nth-child(even) a {
  background-color: #fccf5f;
}

.hotel-list ul.service li a {
  padding: 14px 0 13px 0;
  display: block;
  color: #fff;
}

/*-----------------------------------
/ Owl Slider Style        -----------
------------------------------------*/

.owl-theme .owl-controls .owl-page span {
  border-radius: 0;
  margin: 10px 10px 10px 0px;
  border-radius: 15px;
  background-color: #fff;
  border: 1px solid #1db8c1;
}

/*------------------------------------
/ Social link  List      -------------
------------------------------------*/

ul.social-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.social-list li {
  display: inline-block;
}

ul.social-list li a {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  border: solid 1px #1db8c1;
  color: #1db8c1;
  padding-top: 4px;
}

/*------------------------------------
/ Instagram Feed        --------------
------------------------------------*/

ul.instagram-feed {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.instagram-feed li {
  width: 10%;
  float: left;
  overflow: hidden;
}

ul.instagram-feed li a {
  display: block;
  position: relative;
}

h1.error-404 {
  font-size: 140px;
}

.page-404-full,
.full-screen {
  min-height: 100vh;
}

ul.soon-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.soon-list li {
  display: inline-block;
  margin: 10px 30px 10px 30px;
}

ul.soon-list li span {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
}

/*--- Pricing Page ---*/

ul.pricing-content {
  padding: 0;
  margin: 0;
}

ul.pricing-content li {
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid #eeeded;
}

/*--- Loading Page ---*/

.looding-in {
  width: 100%;
  height: 100%;
  position: absolute;
}

/*--- Widget ---*/

.widget {
  margin-bottom: 40px;
  border: 1px solid #eeeded;
  padding: 30px;
  background-color: #fff;
}

.widget h4.widget-title {
  text-transform: uppercase;
  font-size: 13px;
  color: #1db8c1;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
}

.widget h4.widget-title span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
  background-color: #fff;
}

.widget h4.widget-title:before {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  height: 1px;
  width: 100%;
  background-color: #eeeded;
}

.widget ul.last-posts {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget ul.last-posts li {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px #eeeded;
}

h4.table-title {
  text-transform: uppercase;
  font-size: 16px;
  color: #1db8c1;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
}

h4.table-title span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
  background-color: #fff;
}

h4.table-title:before {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  height: 1px;
  width: 100%;
  background-color: #eeeded;
}

ul.last-posts-footer {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.last-posts-footer li {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}

.widget_categories ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget_categories ul li {
  padding: 5px 0 5px 0;
  border-bottom: 1px solid #eeeded;
}

.widget_categories ul li a {
  color: #424242;
}

/* =WordPress Core
-------------------------------------------------------------- */

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Tabs
---------------------------------------------------------------- */

.nav-tabs-2 .nav-link {
  background-color: #1db8c1;
  border-radius: 0px;
  border: none;
  color: #eee;
}

.nav-tabs-2 .nav-link.active {
  background-color: #52d0d6;
}

.nav-tabs-3 .nav-link {
  background-color: #212121;
  border-radius: 0px;
  border: none;
  color: #fff;
}

.nav-tabs-3 .nav-link.active {
  background-color: #000;
  color: #fff;
}

/* List
---------------------------------------------------------------- */

.border-top-1 {
  border-top: solid 1px;
}

ul.list-style-1 {
  list-style: none;
}

ul.list-style-1 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-1 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-2 {
  border-top: solid 2px;
}

ul.list-style-2 {
  list-style: none;
}

ul.list-style-2 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-2 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-3 {
  border-top: solid 3px;
}

ul.list-style-3 {
  list-style: none;
}

ul.list-style-3 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-3 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-4 {
  border-top: solid 4px;
}

ul.list-style-4 {
  list-style: none;
}

ul.list-style-4 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-4 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-5 {
  border-top: solid 5px;
}

ul.list-style-5 {
  list-style: none;
}

ul.list-style-5 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-5 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-6 {
  border-top: solid 6px;
}

ul.list-style-6 {
  list-style: none;
}

ul.list-style-6 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-6 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-7 {
  border-top: solid 7px;
}

ul.list-style-7 {
  list-style: none;
}

ul.list-style-7 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-7 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-8 {
  border-top: solid 8px;
}

ul.list-style-8 {
  list-style: none;
}

ul.list-style-8 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-8 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-9 {
  border-top: solid 9px;
}

ul.list-style-9 {
  list-style: none;
}

ul.list-style-9 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-9 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

.border-top-10 {
  border-top: solid 10px;
}

ul.list-style-10 {
  list-style: none;
}

ul.list-style-10 li {
  padding: 10px 0 10px;
  border-bottom: 1px solid #eeeded;
}

ul.list-style-10 li:before {
  content: '\f192';
  font-family: 'FontAwesome';
  color: #9e9e9e;
  margin-right: 5px;
  display: inline-block;
}

ul.list-style-2 li:before {
  content: '\f101';
}

ul.list-style-3 li:before {
  content: '\f02e';
}

ul.list-style-4 li:before {
  content: '\f061';
}

ul.list-style-5 li:before {
  content: '\f054';
}

ul.list-style-6 li:before {
  content: '\f0a4';
}

ul.list-style-7 li:before {
  content: '\f178';
}

ul.list-style-8 li:before {
  content: '\f069';
}

ul.list-style-9 li:before {
  content: '\f0e9';
}

ul.list-style-10 li:before {
  content: '\f006';
}

/* timeline-item
----------------------------------------------------------- */

.timeline-item .date {
  position: relative;
  margin-top: 5px;
}

.timeline-item .date:before {
  font-size: 20px;
  content: '\f111';
  position: absolute;
  top: -3px;
  right: -10px;
  font-family: 'FontAwesome';
  color: #1db8c1;
}

/* Social Icons
--------------------------------------------------------------- */

ul.social-icon {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

ul.social-icon li {
  float: left;
}

ul.social-icon li a {
  width: 30px;
  height: 30px;
  display: block;
  margin: 5px;
  padding-top: 4px;
  text-align: center;
}

ul.social-icon.style-1 li a {
  color: #212121;
}

ul.social-icon.style-2 li a {
  color: #fff;
}

ul.social-icon.style-2 li.icon-rss a {
  background-color: #faa33d;
}

ul.social-icon.style-2 li.icon-facebook a {
  background-color: #5d82d1;
}

ul.social-icon.style-2 li.icon-twitter a {
  background-color: #40bff5;
}

ul.social-icon.style-2 li.icon-vimeo a {
  background-color: #35c6ea;
}

ul.social-icon.style-2 li.icon-myspace a {
  background-color: #008dde;
}

ul.social-icon.style-2 li.icon-youtube a {
  background-color: #ef4e41;
}

ul.social-icon.style-2 li.icon-instagram a {
  background-color: #e1326b;
}

ul.social-icon.style-2 li.icon-gplus a {
  background-color: #d68400;
}

ul.social-icon.style-2 li.icon-stumbleupon a {
  background-color: #ff5c30;
}

ul.social-icon.style-2 li.icon-lastfm a {
  background-color: #f34320;
}

ul.social-icon.style-2 li.icon-pinterest a {
  background-color: #e13138;
}

ul.social-icon.style-2 li.icon-google a {
  background-color: #eb5e4c;
}

ul.social-icon.style-2 li.icon-evernote a {
  background-color: #9acf4f;
}

ul.social-icon.style-2 li.icon-dribbble a {
  background-color: #f7659c;
}

ul.social-icon.style-2 li.icon-skype a {
  background-color: #13c1f3;
}

ul.social-icon.style-2 li.icon-forrst a {
  background-color: #45ad76;
}

ul.social-icon.style-2 li.icon-linkedin a {
  background-color: #238cc8;
}

ul.social-icon.style-2 li.icon-wordpress a {
  background-color: #2592c3;
}

ul.social-icon.style-2 li.icon-grooveshark a {
  background-color: #ffb21d;
}

ul.social-icon.style-2 li.icon-delicious a {
  background-color: #377bda;
}

ul.social-icon.style-2 li.icon-behance a {
  background-color: #1879fd;
}

ul.social-icon.style-2 li.icon-dropbox a {
  background-color: #17a3eb;
}

ul.social-icon.style-2 li.icon-soundcloud a {
  background-color: #ff7e30;
}

ul.social-icon.style-2 li.icon-deviantart a {
  background-color: #6a8a7b;
}

ul.social-icon.style-2 li.icon-yahoo a {
  background-color: #ab47ac;
}

ul.social-icon.style-2 li.icon-flickr a {
  background-color: #ff48a3;
}

ul.social-icon.style-2 li.icon-digg a {
  background-color: #75788d;
}

ul.social-icon.style-2 li.icon-blogger a {
  background-color: #ff9233;
}

ul.social-icon.style-2 li.icon-tumblr a {
  background-color: #426d9b;
}

ul.social-icon.style-2 li.icon-quora a {
  background-color: #ea3d23;
}

ul.social-icon.style-2 li.icon-github a {
  background-color: #3f91cb;
}

ul.social-icon.style-2 li.icon-amazon a {
  background-color: #ff8e2e;
}

ul.social-icon.style-2 li.icon-xing a {
  background-color: #1a8e8c;
}

ul.social-icon.style-2 li.icon-wikipedia a {
  background-color: #b3b5b8;
}

ul.social-icon.style-2 li.icon-android a {
  background-color: #a4c639;
}

ul.social-icon.style-2 li.icon-apple a {
  background-color: #999999;
}

ul.social-icon.style-3 li a {
  color: #fafafa;
  background-color: #212121;
}

ul.social-icon.style-4 li a {
  color: #212121;
  border: 1px solid #9e9e9e;
}

ul.social-icon.style-2.rounded li a {
  border-radius: 50%;
}

ul.social-icon.style-3.rounded li a {
  border-radius: 50%;
}

ul.social-icon.style-4.rounded li a {
  border-radius: 50%;
}

rounded .country {
  background-color: #eee;
  border: 1px solid #eee;
  margin-bottom: 30px;
}

rounded .country .name {
  padding: 10px;
}

rounded .country .capital {
  display: none;
}

/*
---------------------------------------------------------------- */

.demo-item a {
  color: #333;
}

.demo-item:hover a {
  color: #f44336;
}

.demo-item:hover {
  transform: translateY(-5%);
  -webkit-transform: translateY(-5%);
  -moz-transform: translateY(-5%);
  -o-transform: translateY(-5%);
  -ms-transform: translateY(-5%);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.demo-item:hover img {
  -webkit-box-shadow: 0px 0px 32px -8px #858585;
  -moz-box-shadow: 0px 0px 32px -8px #858585;
  box-shadow: 0px 0px 32px -8px #858585;
}

#buy-button a {
  font-family: 'Raleway';
  font-weight: 500;
  background-color: #81b441;
  padding: 8px 20px 8px 20px;
  display: inline-block;
  color: #fff;
  position: fixed;
  bottom: 30px;
  z-index: 999;
  right: 45px;
  border-radius: 10px;
  -webkit-animation: color-fade 10s infinite;
  -moz-animation: color-fade 10s infinite;
  animation: color-fade 10s infinite;
}

@-webkit-keyframes color-fade {
  0% {
    background: #9a5342;
  }
  25% {
    background: #81b441;
  }
  50% {
    background: #e46d00;
  }
  75% {
    background: #ff3506;
  }
  100% {
    background: #9a5342;
  }
}

@-moz-keyframes color-fade {
  0% {
    background: #9a5342;
  }
  25% {
    background: #81b441;
  }
  50% {
    background: #e46d00;
  }
  75% {
    background: #ff3506;
  }
  100% {
    background: #9a5342;
  }
}

@keyframes color-fade {
  0% {
    background: #9a5342;
  }
  25% {
    background: #81b441;
  }
  50% {
    background: #e46d00;
  }
  75% {
    background: #ff3506;
  }
  100% {
    background: #9a5342;
  }
}

/*# sourceMappingURL=style.css.map */


.editable-buttons .btn-with-icon i {
  margin-right: 0;
}

.table-responsive {
  margin-top: 10px;
}

.ng2-smart-pagination {
  display: flex !important;
}

.ng2-smart-pagination-nav {
  margin-top: 16px;
}

.table-moor {
  margin-top: 10px;
  margin-bottom: 10px !important;
}

.table-moor thead tr th span {
  margin-right: 5px;
}

.table-moor > tbody > tr.active {
  background-color: #e0bba3 !important;
  color: white;
}

.table.table-moor thead tr th {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 10px;
  text-align: -webkit-center;
  background-color: #d2d2d2 !important;
  color: #000000;
  vertical-align: inherit !important;
  //border: 1px solid #dee2e6;
  border: 1px  #dee2e6;
}

.table.table-moor tbody tr:hover {
  //background-color: #cff9e9;
}

.table.table-moor tbody {
  border: 2px #cdd1d4;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
}

.table-moor thead,
.table-moor tfoot {
  background-color: #d3f3c3;
  color: #253644;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-weight: 400;
  //border: 2px solid #cdd1d4;
}

.table-moor > tbody > tr > td {
  padding: 0;
  //line-height: 35px;
  text-align: center;
  vertical-align: inherit !important;
}

//responsiv table

/*

RESPONSTABLE 2.0 by jordyvanraaij
  Designed mobile first!

If you like this solution, you might also want to check out the 1.0 version:
  https://gist.github.com/jordyvanraaij/9069194

*/

// Default options for table style
$table-breakpoint: 480px;
$table-background-color: #fff;
$table-text-color: #024457;
$table-outer-border: 1px solid #167f92;
$table-cell-border: 1px solid #d9e4e6;

// Extra options for table style (parse these arguments when including your mixin)
$table-border-radius: 10px;
$table-highlight-color: #eaf3f3;
$table-header-background-color: #d3f3c3;
$table-header-text-color: #253644;
$table-header-border: 1px solid #fff;

// The Responstable mixin

@mixin responstable(
  $breakpoint: $table-breakpoint,
  $background-color: $table-background-color,
  $text-color: $table-text-color,
  $outer-border: $table-outer-border,
  $cell-border: $table-cell-border,
  $border-radius: none,
  $highlight-color: none,
  $header-background-color: $table-background-color,
  $header-text-color: $table-text-color,
  $header-border: $table-cell-border
) {
  .responstable {
    margin: 1em 0;
    width: 100%;
    overflow: hidden;
    background: $background-color;
    color: $text-color;

    tr {
      border: $cell-border;
      &:nth-child(odd) {
        // highlight the odd rows with a color
        background-color: $highlight-color;
      }
    }

    th {
      display: none; // hide all the table header for mobile
      background-color: $header-background-color;
      color: $header-text-color;
      text-align: center;
      padding: 1em;
      &:first-child {
        // show the first table header for mobile
        display: table-cell;
        text-align: center;
      }
      &:nth-child(2) {
        // show the second table header but replace the content with the data-th from the markup for mobile
        display: table-cell;
        span {
          display: none;
        }
        &:after {
          content: attr(data-th);
        }
      }
      @media (min-width: $breakpoint) {
        &:nth-child(2) {
          // hide the data-th and show the normal header for tablet and desktop
          span {
            display: block;
          }
          &:after {
            display: none;
          }
        }
      }
    }

    td {
      display: block; // display the table data as one block for mobile
      word-wrap: break-word;
      max-width: 7em;
      text-align: center;
      &:first-child {
        display: table-cell; // display the first one as a table cell (radio button) for mobile
        border-right: $cell-border;
        text-align: center;
      }
      @media (min-width: $breakpoint) {
        border: $cell-border;
      }
    }

    th,
    td {
      text-align: left;
      margin: 0.5em 1em;
      @media (min-width: $breakpoint) {
        display: table-cell; // show the table as a normal table for tablet and desktop
        padding: 1em;
      }
    }
  }
}

// Include the mixin (with extra options as overrides)

@include responstable(
  $border-radius: $table-border-radius,
  $highlight-color: $table-highlight-color,
  $header-background-color: $table-header-background-color,
  $header-text-color: $table-header-text-color,
  $header-border: $table-header-border
);

// General styles
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
